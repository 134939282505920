import React, { FC, useState } from "react";
import { Card, CardContent } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import FailedSectionView from "../../../components/lazy/FailedSectionView";
import LoadingSectionView from "../../../components/lazy/LoadingSectionView";
import LazyView from "../../../components/lazy/LazyView";
import Slide from "../../../components/carousel/Slide";
import Carousel from "../../../components/carousel/Carousel";
import { valueFromProps } from "../../../util/ValueFromProps";
import { useWindowSize } from "../../../hooks/WindowSize";
import LinkButton from "../../../components/button/LinkButton";
import { useCatalogItems } from "../../../hooks/UseCatalogItems";
import CatalogItemSlide from "./CatalogItemSlide";
import { isNonNullOrUndefined } from "@echope/echope-store-core/dist/util/assertions";
import { CatalogItem, Product } from "@echope/echope-store-core/dist/models";
import CatalogItemDetailView from "../../catalog/views/CatalogItemDetailView";
import { StoreWithDistance } from "../../../store/preferences";

interface ICatalogCardViewProps { 
    store?: StoreWithDistance 
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            width: "60%",
            marginBottom: 5,
            marginLeft: "0.5%",
            flexDirection: "column",
            [theme.breakpoints.up("lg")]: {
                width: "68%",
            },
            [theme.breakpoints.between("sm", "lg")]: {
                width: "55%",
            },
            [theme.breakpoints.down("sm")]: {
                width: "100%",
                alignSelf: "center",
            },
        },
        container: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            flex: 1,
        },
        content: {
            display: "flex",
            flex: 1,
            padding: "10px !important",
        },
        sloganImage: {
            height: (props) =>
                valueFromProps(props, "vw", 1440) * 0.33 * 0.5625,
            width: "auto",
            [theme.breakpoints.down("sm")]: {
                display: "none",
            },
        },
    })
);

const CatalogCardView: FC<ICatalogCardViewProps> = ({ store }) => {
    const windowSize = useWindowSize();
    const styles = useStyles({ vw: windowSize.width });
    const [items, operation] = useCatalogItems(store);
    const [item, setItem] = useState<CatalogItem>({} as CatalogItem);
    const [open, setOpen] = useState(false);

    const slidesBySize =
        window.innerWidth < 1400 && window.innerWidth > 660
            ? 2
            : window.innerWidth > 600
                ? 3
                : 1;

    const onItemClick = (item: CatalogItem) => {
        setOpen(isNonNullOrUndefined(item));
        setItem(item);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Card className={styles.root}>
            <CardContent className={styles.content}>
                <LazyView
                    operation={operation}
                    failureSection={() => (
                        <FailedSectionView operation={operation} />
                    )}
                    loadingSection={() => (
                        <LoadingSectionView operation={operation} />
                    )}
                >
                    <div className={styles.container}>
                        <Carousel slidesByTime={slidesBySize} delay={5000}>
                            {items.slice(0, 6).map((item) => (
                                <Slide
                                    key={item.product.id}
                                    view={
                                        <CatalogItemSlide
                                            item={item}
                                            onClick={() => onItemClick(item)}
                                        />
                                    }
                                />
                            ))}
                        </Carousel>

                        <CatalogItemDetailView
                            product={item.product || ({} as Product)}
                            open={open}
                            onClose={handleClose}
                        />

                        <LinkButton
                            path="/catalog"
                            style={{ marginTop: 4 }}
                            variant="contained"
                            color="primary"
                            text="Ir para o Catálogo"
                        />
                    </div>
                </LazyView>
            </CardContent>
        </Card>
    );
};

export default CatalogCardView;
