import { Store } from "@echope/echope-store-core/dist/models";
import {
    getCartDeliveryPrice,
    getCartItems,
    getCartSubtotal,
} from "@echope/echope-store-core/dist/store/cart";
import { orderCalculateRate } from "@echope/echope-store-core/dist/use-cases/cart/service";
import {
    CartVoucherData,
    VoucherData,
    VoucherPreparedStatus,
} from "@echope/echope-store-core/dist/use-cases/voucher";
import { isNullOrUndefined } from "@echope/echope-store-core/dist/util/assertions";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getPreferenceSelectedStore } from "../store/preferences";
import { currencyFormatter } from "../util/formatter";

export const useVoucher = (
    voucherData?: VoucherData,
    mayApply?: (data: CartVoucherData) => VoucherPreparedStatus
) => {
    const [voucherValidate, setVoucherValidate] = useState(
        {} as VoucherPreparedStatus
    );
    const [voucherDescription, setVoucherDescription] = useState("");

    const cartItems = useSelector(getCartItems);
    const cartTotal = useSelector(getCartSubtotal);
    const deliveryPrice = useSelector(getCartDeliveryPrice);
    const store = useSelector(getPreferenceSelectedStore);

    useEffect(() => {
        if (!mayApply) return;

        const rate = orderCalculateRate(deliveryPrice)(
            store?.store || ({} as Store)
        )(cartItems);

        const voucherParams = {
            products: cartItems,
            total: cartTotal,
            rateValue: rate.value,
            isRateDiscounted: rate.discounted,
            baseRate: deliveryPrice,
        };

        setVoucherValidate(mayApply(voucherParams));
    }, [cartItems, cartTotal, deliveryPrice, mayApply, store?.store]);

    useEffect(() => {
        const buildDescription = (type: string): string => {
            if (isNullOrUndefined(voucherData) || !voucherData) return "";

            const hasBrands =
                Array.isArray(voucherData.brands) &&
                voucherData.brands.length > 0;
            const hasMinOrder = !!voucherData.minOrderAmount;
            let brandsName = "";
            if (hasBrands) {
                brandsName = voucherData.brands
                    .map((brand) => brand.name.trim())
                    .join(", ");
            }
            const valueFormated = currencyFormatter.format(voucherData.value) || "";

            if (hasMinOrder || (hasMinOrder && hasBrands)) {
                let minOrder =
                    currencyFormatter.format(voucherData.minOrderAmount) || "";

                switch (type) {
                    case "P":
                        return `Compre ${minOrder} em produtos ${brandsName} para ganhar ${voucherData.value}% de desconto nessa compra`;
                    case "F":
                        return `Compre ${minOrder} em produtos ${brandsName} para ganhar taxa zero nessa compra`;
                    case "V":
                        return `Compre ${minOrder} em produtos ${brandsName} para ganhar ${valueFormated} de desconto nessa compra`;
                    default:
                        return "";
                }
            } else if (hasBrands) {
                switch (type) {
                    case "P":
                        return `Compre produtos ${brandsName} para ganhar ${voucherData.value}% de desconto nessa compra`;
                    case "F":
                        return `Compre produtos ${brandsName} para ganhar taxa zero nessa compra`;
                    case "V":
                        return `Compre produtos ${brandsName} para ganhar ${valueFormated} de desconto nessa compra`;
                    default:
                        return "";
                }
            } else {
                switch (type) {
                    case "P":
                        return `Ganhe ${voucherData.value}% de desconto nessa compra.`;
                    case "F":
                        return `Ganhe taxa zero nessa compra.`;
                    case "V":
                        return `Ganhe ${valueFormated} de desconto nessa compra.`;
                    default:
                        return "";
                }
            }
        };

        if (isNullOrUndefined(voucherData) || !voucherData) return;

        let description = "";

        switch (voucherData.type) {
            case "P":
                description = buildDescription(voucherData.type);
                break;
            case "F":
                description = buildDescription(voucherData.type);
                break;
            case "V":
                description = buildDescription(voucherData.type);
                break;
            default:
                description = "";
        }

        setVoucherDescription(description);
    }, [voucherData]);

    return { voucherValidate: voucherValidate, voucherDescription };
};
