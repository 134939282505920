import {Converter, newRemoteService} from "@echope/echope-store-core/dist/infrastructure/api";
import {LatLng} from "leaflet";
import {getAddressByPostalCode} from "./AddressLoader";
import {isEmpty, isNullOrUndefined} from "../../util/Asserts";

class LocationResponse {
    readonly location: LatLng;
    readonly address: string;

    constructor(location: LatLng, address: string) {
        this.location = location;
        this.address = address;
    }
}

const locationConverter: Converter<LocationResponse | undefined> = (data) => {
    if (data.results.length === 0) {
        return undefined;
    }

    const location = data.results[0].geometry.location;
    const textAddress = data.results[0].formatted_address;

    return new LocationResponse(
        new LatLng(location.lat, location.lng),
        textAddress
    );
}

const addressToQuery = (text: string): string =>
    isEmpty(text) ?
        text :
        text.replace(/([a-z])([A-Z])/g, '$1 $2').split(" ").join("+");

// const onlyPostalCodePrefix = (text: string): string =>
//     text.length >= 5 ? text.substr(0, 5) : text;


const cleanPostalCode = (text: string): string => text.replace(/[\\.-]/g, '');

const key = 'AIzaSyB7091IqunDQ8bjXSvhhsbl5P7SkBePUtI';

const getLocationByPostalCode = (postalCode: string): Promise<LocationResponse> =>
    new Promise<LocationResponse>((resolve, reject) => {
        getAddressByPostalCode(postalCode).then(
            address => {
                if (!address.city && !address.street) {
                    reject(new Error("Não foi possível encontrar a sua localização"))
                }

                const params = new URLSearchParams();
                params.append('address', addressToQuery(address.street));
                params.append('components', `locality:${address.city?.toLowerCase()}|postal_code:${cleanPostalCode(postalCode)}|country:BR`)
                params.append('key', key)
                
                newRemoteService().get<LocationResponse | undefined>({
                    authenticated: false,
                    endpoint: `https://maps.googleapis.com/maps/api/geocode/json?${params.toString()}`,
                    converter: locationConverter
                }).then(
                    response => {
                        isNullOrUndefined(response) ?
                            reject(new Error("Não foi possível encontrar a sua localização")) :
                            resolve(response!);
                    },
                    error => reject(error)
                );
            },
            error => reject(error)
        )
    });

export {
    LocationResponse,
    getLocationByPostalCode
}