import configureStore from "@echope/echope-store-core/dist/store";
import {WebApplicationState} from "./types";
import {Store} from "redux";
import {storeConfiguration} from "./config/configuration";
import {loadState} from "./config/stateLoad";
import {saveState} from "./config/statePersist";

export const configureWebStore = (): Store<WebApplicationState> => {
    const lastState = loadState();
    const store = configureStore(storeConfiguration, lastState);

    store.subscribe(() => {
        saveState(store.getState());
    })

    return store;
}

