import {Store} from "@echope/echope-store-core/dist/models";
import {LatLng} from "leaflet";
import {StoreWithDistance} from "./types";
import {isNullOrUndefined} from "../../util/Asserts";

const deg2rad = (deg: number): number => deg * (Math.PI / 180);

export const getDistanceInKm = (lat1: number, lon1: number, lat2: number, lon2: number): number => {
    const R = 6371; // radius of the earth in km

    const dLat = deg2rad(lat2 - lat1); // deg2rad below
    const dLon = deg2rad(lon2 - lon1);

    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2)
    ;

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // distance in km
}

export const calculateDistance = (location: LatLng) => (store: Store): number => {
    const { coords } = store;

    return getDistanceInKm(
        location.lat,
        location.lng,
        coords.latitude,
        coords.longitude
    )
}

export const findNearestStore = (stores: StoreWithDistance[]) => {
    return (userLocation: LatLng): StoreWithDistance | undefined => {
        const nearFunc = (nearest: StoreWithDistance, item: StoreWithDistance) => {
            const {store, distance} = nearest;

            if (isNullOrUndefined(store) || distance === -1) {
                return item.store?.acceptingOrders ? item : nearest;
            }

            if (!item.store?.acceptingOrders) {
                return nearest;
            }

            return item.distance < nearest.distance ? item : nearest;
        }

        if (stores != null && stores.length > 0 && userLocation != null) {
            return stores.reduce(nearFunc, StoreWithDistance.createDefault());
        }

        return undefined;
    }
}