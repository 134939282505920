import { Theme } from "@material-ui/core"
import { ArrowForward, Close } from "@material-ui/icons"
import { createStyles, makeStyles } from "@material-ui/styles"
import React, { FC, useState } from "react"
import logoE from '../../assets/images/logoE.png'

interface DownloadAppProps {

}

const DownloadApp: FC<DownloadAppProps> = (props) => {
    const styles = useStyles();

    const [showApp, setShowApp] = useState(true)

    const handleCloseApp = () => {
        setShowApp(false)
    }

    const linkApp = () => {
        const agent = window.navigator.userAgent.toLowerCase();
        switch (true) {
            case agent.indexOf('android') > -1:
                return 'https://play.google.com/store/apps/details?id=br.com.echope';
            case agent.indexOf('iphone') > -1:
                return 'https://apps.apple.com/br/app/echope/id1471110013';
            default:
                setShowApp(false);
                return '';
        }
    }

    return (
        <>
            {showApp ? (
                <div className={styles.linkApp}>
                    <div className={styles.LinkAppBox}>
                        <span className={styles.linkAppClose} onClick={handleCloseApp}><Close /></span>

                        <a className={styles.linkAppLinkAppBox} href={linkApp()}>
                            <img className={styles.linkAppIcon} src={logoE} alt="Icon App" />
                            <span className={styles.linkAppLinkApp}>Vouchers disponíveis no APP</span>
                            <span className={styles.linkAppLinkVer}>
                                BAIXAR
                                <ArrowForward fontSize="small" />
                            </span>
                        </a>
                    </div>
                </div>
            ) : (
                <></>
            )}
        </>
    )
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        linkApp: {
            backgroundColor: 'rgba(65, 61, 61, 0.918)',
            left: 0,
            position: 'fixed',
            right: 0,
            top: 0,
            width: '100%',
            zIndex: 1800,
        },
        LinkAppBox: {
            display: 'flex',
            alignItems: 'center',
            color: '#fff',
            padding: '0.4444444444444444em 1.1111111111111112em',
            position: 'relative',
        },
        linkAppClose: {
            color: '#fff',
            fontSize: '1.3666666666666666em',
            marginRight: '1.1111111111111112em',
        },
        linkAppLinkAppBox: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            textDecoration: 'none',
            fontSize: '1.125rem',
            color: 'inherit',
            fontWeight: 500,
            width: '100%',
            left: 10,
        },
        linkAppIcon: {
            marginRight: '0.5555555555555556em',
            width: '2.7777777777777777em',
        },
        linkAppLinkApp: {
            fontSize: '0.7777777777777778em',
            maxWidth: '45%',
            height: '100%',
            display: 'flex',
            justifyContent: 'space-between',
        },
        linkAppLinkVer: {
            fontSize: '0.7777777777777778em',
            display: 'flex',
            alignItems: 'center',
        },
        [theme.breakpoints.down('md')]: {
            root: {
                flexFlow: 'column nowrap',
            },
            acceptButton: {
                marginTop: 20,
            }
        }
    })
)

export default DownloadApp
