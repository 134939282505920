import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isNonNullOrUndefined} from "@echope/echope-store-core/dist/util/assertions";
import {isEqual} from "lodash";
import {sendLoadStoreData} from "@echope/echope-store-core/dist/store/store";
import {getPreferenceSelectedStore, StoreWithDistance} from "../store/preferences";
import { sendCleanCart } from "@echope/echope-store-core/dist/store/cart";
import { sendSelectStore } from "@echope/echope-store-core/dist/store/auth";
import { Store } from "@echope/echope-store-core/dist/models";
import { sendCleanSelectedVouchers } from "@echope/echope-store-core/dist/store/voucher";


export const useSelectedStore = () => {

    const storeWithDistance = useSelector(getPreferenceSelectedStore);
    const dispatch = useDispatch();

    const [selectedStore, setSelectedStore] = useState<StoreWithDistance>();

    useEffect(() => {
        if (isNonNullOrUndefined(storeWithDistance) && isEqual(storeWithDistance, selectedStore)){
            console.log("dont need update anything...");
            return;
        }

        console.log("updating selected store: ", storeWithDistance);
        setSelectedStore(storeWithDistance);
        dispatch(sendLoadStoreData(storeWithDistance?.store));
        dispatch(sendSelectStore(storeWithDistance?.store || {} as Store))
        dispatch(sendCleanCart());
        dispatch(sendCleanSelectedVouchers())
    }, [dispatch, selectedStore, storeWithDistance]);


    return selectedStore;
}