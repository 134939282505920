import styled from "styled-components";
import {Grid} from "@material-ui/core";

const StyledContainer = styled(Grid)`
  padding: ${(props) => props.theme.spacing(2)}px 0;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;

export default StyledContainer;