import {
    Box,
    Button,
    Paper,
    TextField,
    Theme,
    Typography,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import { Credential } from "@echope/echope-store-core/dist/models";
import { getAuthOperation, sendSignIn, getLoggedInUserSelector } from "@echope/echope-store-core/dist/store/auth";
import { useFormik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import RecoverPassword from "../../components/modal/RecoverPassword";

const LoginScreen = () => {
    const styles = useStyles();
    const [modalRecoverPass, setModalRecoverPass] = React.useState(false);
    const userLoggedOperation = useSelector(getAuthOperation)

    const myUser = useSelector(getLoggedInUserSelector)
    const dispatch = useDispatch()

    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        onSubmit(value) {
            const credential = new Credential(value.email, value.password) 

            dispatch(sendSignIn(credential))
        }
    })

    React.useEffect(() => {
        console.log(userLoggedOperation)
        console.log('\n\n\n\n user;', myUser, '\n\n\n')
    }, [userLoggedOperation, myUser])

    return (
        <section className={styles.root}>
            <Paper elevation={2} className={styles.card}>
                <Typography variant="h5" component="h1" color="primary">
                    Login
                </Typography>

                <form className={styles.form} onSubmit={formik.handleSubmit}>
                    <TextField
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        type="email"
                        className={styles.input}
                        label="E-mail"
                        variant="outlined"
                        placeholder="Digite seu e-mail"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        name="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        type="password"
                        className={styles.input}
                        label="Senha"
                        variant="outlined"
                        placeholder="Digite sua senha"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />

                    <Box display="flex" justifyContent="space-between">
                        <Link to="/registration" className={styles.link}>
                            Ainda não é cadastrado?
                        </Link>
                        <Typography
                            role="button"
                            className={styles.link}
                            onClick={() => setModalRecoverPass(true)}
                        >
                            Esqueceu a senha?
                        </Typography>
                    </Box>


                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                    // className={styles.button}
                    >
                        Entrar
                    </Button>
                </form>
            </Paper>

            <RecoverPassword
                modalRecoverPass={modalRecoverPass}
                setModalRecoverPass={setModalRecoverPass}
            />
        </section>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            maxWidth: 400,
            margin: "20px auto",
        },
        card: {
            padding: 20,
        },
        form: {
            display: "flex",
            flexFlow: "column nowrap",
            gap: 20,
            margin: "20px auto",
        },
        input: {
            "& .MuiOutlinedInput-input": {
                padding: 11,
            },
        },
        link: {
            width: "fit-content",
            textDecoration: "none",
            fontSize: 13,
            color: theme.palette.primary.dark,
            cursor: "pointer",
        },
    })
);

export default LoginScreen;
