import {ApplicationState, StoreCoreConfiguration} from "@echope/echope-store-core/dist/store";
import {WebApplicationState} from "../types";
import {locationReducer} from "../location";
import {preferenceReducer} from "../preferences";

const storeConfiguration: StoreCoreConfiguration<WebApplicationState, WebApplicationState, ApplicationState> = {
    reducers: (commonReducers) => ({
        ...commonReducers,
        location: locationReducer,
        preference: preferenceReducer
    }),
};

export {
    storeConfiguration
}