import { nonNullOrUndefined } from "./Asserts";

const valueFromProps = (props: any, attribute: string, defaultValue?: any) => {
    if (attribute in props){
        const value = props[attribute];

        if (nonNullOrUndefined(value)){
            return value;
        }
    }

    return defaultValue;
}

export {
    valueFromProps
}