import {CatalogItem} from "@echope/echope-store-core/dist/models";
import {FC} from "react";
import {createStyles, Typography} from "@material-ui/core";
import {makeStyles, Theme} from "@material-ui/core/styles";
import {currencyFormatter} from "../../../util/formatter";
import PromotionImage from "../../../assets/images/promotion.png";

export interface CatalogItemViewProps {
    item: CatalogItem,
    onClick: (item: CatalogItem) => void;
}

const useStyle = makeStyles((theme: Theme) => createStyles({
    container: {
        display: "flex",
        flexDirection: "column",
        backgroundColor: theme.palette.background.paper,
        border: "solid 1px #d0d0d0",
        padding: 5,
        borderRadius: 5,
        cursor: "pointer",
        justifyContent: "center",
        width: "12%",
        margin: "0.25%",
        [theme.breakpoints.down("md")]: {
            width: "19.5%",
            margin: "0.25%",
        },
        [theme.breakpoints.down("sm")]: {
            width: "32%",
            margin: "0.5%",
        },
        [theme.breakpoints.down("xs")]: {
            width: "50%",
            margin: 0
        },
        "&:hover": {
            backgroundColor: theme.palette.primary.main
        }
    },
    content: {
        backgroundColor: theme.palette.background.paper,
        flex: 1,
        display: "flex",
        flexDirection: "column",
        padding: 0,
        paddingLeft: 3,
        paddingRight: 3,
        borderRadius: 5,
    },
    data: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
        justifyContent: "space-between"
    },
    imageContainer: {
        position: 'relative',
    },
    image: {
        width: "100%",
        height: 90,
        borderBottom: "solid 1px #e0e0e0",
        marginBottom: 8,
        objectFit: "contain",
    },
    imagePromotion: {
        position: "absolute",
        top: 0,
        left: 0,
        width: 60,
    },
    price: {
        color: theme.palette.primary.main,
        fontWeight: "bold",
        textAlign: "right",
        marginTop: 8
    }
}));


const CatalogItemView: FC<CatalogItemViewProps> = ({item, onClick}) => {
    const {product} = item;
    const styles = useStyle();

    const action = () => {
        onClick(item);
    }

    return (
        <div className={styles.container} onClick={action}>
            <div className={styles.content}>
                <div className={styles.imageContainer}>
                    <img className={styles.image} src={product.imageSquareURL} alt={product.name} />
                    {
                        product.price != product.fullPrice &&
                        <img className={styles.imagePromotion} alt="Promoção" src={PromotionImage} />
                    }
                </div>
                {/* <img className={styles.image} alt={product.name} src={product.imageWideURL}/> */}

                <div className={styles.data}>
                    <Typography variant="body2">{product.name}</Typography>

                    <Typography variant="h6"
                                className={styles.price}>{currencyFormatter.format(product.price)}</Typography>
                </div>
            </div>
        </div>
    )
}

export default CatalogItemView;