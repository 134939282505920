import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    TextField,
    Theme,
    Typography,
} from "@material-ui/core";
import { Add, Backspace, Close, Remove } from "@material-ui/icons";
import { createStyles, makeStyles } from "@material-ui/styles";
import { CartItem, Product } from "@echope/echope-store-core/dist/models";
import { sendUpdateCartItems } from "@echope/echope-store-core/dist/store/cart";
import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LinkButton from "../../../components/button/LinkButton";
import { WebApplicationState } from "../../../store/types";
import { currencyFormatter, dateFormatter } from "../../../util/formatter";
import ReactPixel from 'react-facebook-pixel';

export interface CatalogItemDetailViewProps {
    product: Product;
    open: boolean;
    onClose: () => void;
}

interface ItemFormated {
    totalPrice: number;
    totalPriceFormated: string;
    totalAmount: number;
    isPromotion: boolean;
}

let debounce: NodeJS.Timeout;

const CatalogItemDetailView: FC<CatalogItemDetailViewProps> = ({
    product,
    open,
    onClose,
}) => {
    const styles = useStyles();
    const [amount, setAmount] = useState(0);
    const [addToCart, setAddToCart] = useState(false);
    const [itemFormated, setItemFormated] = useState<ItemFormated>(
        {} as ItemFormated
    );
    const dispatch = useDispatch();
    const cartItems: CartItem[] = useSelector<WebApplicationState, CartItem[]>(
        (state) => state.cart.items
    );


    const handlePixelAddCart = () => {
        if (addToCart) {
            ReactPixel.track('AddToCart', {
                content_ids: [product.id],
                content_name: product.name,
                value: product.price,
                content_type: 'product',
                currency: 'BRL'
            });
        }
    }

    const handleAddAmount = () => {
        let value = amount;

        if (amount >= itemFormated.totalAmount) return;

        const index = cartItems.findIndex(
            (item) => item.product.id === product.id
        );

        if (index > -1) {
            cartItems.splice(index, 1);
            const itemToCart = CartItem.create(product, ++value);
            dispatch(sendUpdateCartItems([...cartItems, itemToCart]));
        } else {
            const itemToCart = CartItem.create(product, ++value);
            dispatch(sendUpdateCartItems([...cartItems, itemToCart]));
        }

        setAddToCart(true)
    };

    const handleRemoveAmount = () => {
        let value = amount;
        if (amount > 0) {
            const index = cartItems.findIndex(
                (item) => item.product.id === product.id
            );

            if (index > -1) {
                const quantity = cartItems[index].quantity;
                if (quantity === 1) {
                    cartItems.splice(index, 1);
                    dispatch(sendUpdateCartItems([...cartItems]));
                } else {
                    cartItems.splice(index, 1);
                    const itemToCart = CartItem.create(product, --value);
                    dispatch(sendUpdateCartItems([...cartItems, itemToCart]));
                }
            }
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        clearTimeout(debounce);

        const value = e.target.value;
        const isValidNumber =
            value.match(/\d/g)?.length &&
            Number(value) <= itemFormated.totalAmount;

        if (isValidNumber) {
            debounce = setTimeout(() => {
                console.log("adicionou pelo texto");
                const index = cartItems.findIndex(
                    (item) => item.product.id === product.id
                );

                if (index > -1) {
                    cartItems.splice(index, 1);
                    const itemToCart = CartItem.create(product, Number(value));
                    dispatch(sendUpdateCartItems([...cartItems, itemToCart]));
                } else {
                    const itemToCart = CartItem.create(product, Number(value));
                    dispatch(sendUpdateCartItems([...cartItems, itemToCart]));
                }

                setAddToCart(true)
            }, 500);
            setAmount(Number(value));
        }
    };

    const handleResetAmount = () => {
        const index = cartItems.findIndex(
            (item) => item.product.id === product.id
        );
        cartItems.splice(index, 1);
        dispatch(sendUpdateCartItems([...cartItems]));
        setAmount(0);
        setAddToCart(false)
    };

    const handleCloseModal = () => {
        handlePixelAddCart();
        setAddToCart(false);
        onClose();
    }

    useEffect(() => {
        if (cartItems?.length) {
            const index = cartItems.findIndex(
                (item) => item.product.id === product.id
            );
            if (index > -1) {
                const prodAmount = cartItems[index].quantity;
                setAmount(prodAmount);
            } else {
                setAmount(0);
            }
        }
    }, [cartItems, product.id]);

    useEffect(() => {
        setItemFormated({
            totalPrice: product?.price,
            totalPriceFormated: currencyFormatter.format(
                product?.price * amount
            ),
            totalAmount: product?.quantity || 0,
            isPromotion: product?.fullPrice !== product?.price,
        });
    }, [amount, product]);

    return (
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={styles.root}
        >
            <DialogTitle
                id="alert-dialog-title"
                style={{ height: 30, padding: "" }}
            >
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Typography color="primary" variant="h6">
                        Detalhes do produto
                    </Typography>
                    <Button style={{ minWidth: 24, padding: "8px 0" }}>
                        <Close onClick={handleCloseModal} />
                    </Button>
                </Box>
            </DialogTitle>

            <DialogContent className={styles.contentContainer}>
                <Box className={styles.image}>
                    <img src={product?.imageWideURL} alt={product?.name} />
                </Box>

                <DialogContentText component="div">
                    <Typography component="h6" color="primary" variant="h6">
                        {product?.name}
                    </Typography>
                </DialogContentText>

                {itemFormated.isPromotion && product?.datePromotion && (
                    <Box className={styles.promotionBox}>
                        <Typography variant="h5">
                            Promoção válida até{" "}
                            {dateFormatter.format(
                                new Date(product?.datePromotion)
                            )}
                        </Typography>
                    </Box>
                )}

                <Box className={styles.lineStyle}>
                    <Typography>Marca</Typography>
                    <Typography> {product?.brand} </Typography>
                </Box>

                <Box className={styles.lineStyle}>
                    <Typography>Grupo</Typography>
                    <Typography> {product?.group} </Typography>
                </Box>

                <Box className={styles.lineStyle}>
                    <Typography>Kit Chopeira</Typography>
                    <Typography>
                        {" "}
                        {product?.isLager ? "Sim" : "Não"}{" "}
                    </Typography>
                </Box>

                <Box className={styles.lineStyle}>
                    <Typography>Unidades (Pronto entrega)</Typography>
                    <Typography>
                        {" "}
                        {product?.quantity ? "Disponível" : "Indisponível"}{" "}
                    </Typography>
                </Box>

                <Box className={styles.amountContainer}>
                    <Box
                        display="flex"
                        alignItems="flex-start"
                        justifyContent="space-between"
                        paddingBottom="10"
                    >
                        <Typography variant="h6">Itens no Carrinho</Typography>
                        <IconButton
                            color="inherit"
                            onClick={handleResetAmount}
                            style={{ height: 28 }}
                        >
                            <Backspace />
                        </IconButton>
                    </Box>

                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography
                            style={{
                                textDecoration: "line-through",
                                color: "#909090",
                            }}
                        >
                            {itemFormated.isPromotion &&
                                currencyFormatter.format(
                                    product?.fullPrice || 0
                                )}
                        </Typography>

                        <Typography>
                            {currencyFormatter.format(product?.price || 0)}
                        </Typography>
                    </Box>

                    <div className={styles.inputContainer}>
                        <Button
                            variant="contained"
                            color="primary"
                            style={{ borderRadius: "5px 0 0 5px" }}
                            onClick={handleRemoveAmount}
                        >
                            <Remove />
                        </Button>

                        <TextField
                            variant="outlined"
                            value={amount}
                            onChange={handleChange}
                        />

                        <Button
                            variant="contained"
                            color="primary"
                            style={{ borderRadius: "0 5px 5px 0" }}
                            onClick={handleAddAmount}
                        >
                            <Add />
                        </Button>
                    </div>

                    <Box display="flex" justifyContent="space-between">
                        <Typography>Total</Typography>
                        <Typography>
                            {itemFormated.totalPriceFormated}
                        </Typography>
                    </Box>
                </Box>

                <Box display="flex" justifyContent="space-around" gridGap={20}>
                    <Button
                        size="large"
                        variant="contained"
                        // className={styles.actionButton}
                        fullWidth
                        onClick={handleCloseModal}
                    >
                        Continuar comprando
                    </Button>
                    <LinkButton
                        size="large"
                        variant="contained"
                        color="primary"
                        // className={styles.actionButton}
                        fullWidth
                        onClick={handlePixelAddCart}
                        path="/store/cart"
                        text="Carrinho"
                    />
                </Box>
            </DialogContent>
        </Dialog>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            "& p": {
                fontSize: "0.8rem",
            },
            "& .MuiDialog-paper": {
                width: "50%",
                minWidth: 300,
                maxHeight: "95%",
            },
            "& .MuiOutlinedInput-root": {
                borderRadius: 0,
                backgroundColor: theme.palette.background.paper,
                height: "100%",
                "& input": {
                    textAlign: "center",
                },
            },
            "& .MuiDialogTitle-root": {
                height: 25,
                padding: "0 5px 0 16px",
            },
            [theme.breakpoints.down("sm")]: {
                "& .MuiDialog-paper": {
                    width: "auto",
                    margin: "auto 10px",
                },
            },
        },
        contentContainer: {
            padding: "10px 16px",
            scrollbarWidth: "none",
            "&::-webkit-scrollbar": {
                width: "0",
            },
        },
        amountContainer: {
            padding: "5px 16px",
            margin: "20px 0",
            borderRadius: 5,
            backgroundColor: "#E7E7E7",
        },
        inputContainer: {
            display: "flex",
            justifyContent: "center",
            margin: "10px auto",
            height: 30,
            width: 200,
        },
        promotionBox: {
            margin: "20px 0",
            padding: 10,
            backgroundColor: theme.palette.error.main,
            color: theme.palette.text.secondary,
            fontWeight: "bold",
            borderRadius: 5,
        },
        lineStyle: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            borderBottom: `1px solid ${theme.palette.grey[400]}`,
            "& + &": {
                paddingTop: 10,
            },
        },
        image: {
            textAlign: "center",
            marginBottom: 5,
            padding: 5,
            border: "2px solid #E7E7E7",
            "& img": {
                width: "100%",
                objectFit: "contain",
            },
        },
        actionButton: {
            padding: "10px 16px",
        },
        [theme.breakpoints.down("md")]: {
            actionButton: {
                padding: 6,
            },
        },
        [theme.breakpoints.down("sm")]: {
            lineStyle: {
                "& p": {
                    fontSize: "0.775rem",
                },
            },
            actionButton: {
                padding: 10,
                fontSize: "0.750rem",
            },
        },
    })
);

export default CatalogItemDetailView;
