import { Theme, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import React, { FC, useEffect } from "react";
import faq from "../../assets/faq/data.json";

const HelpScreen: FC = () => {
    const styles = useStyles();

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);

    return (
        <section className={styles.container}>
            <Typography color="primary" variant="h4" component="h1">
                Dúvidas
            </Typography>
            {faq.map((faq) => (
                <dl className={styles.faqContainer}>
                    <Typography component="dt">{faq.question}</Typography>
                    <Typography component="dd">{faq.answer}</Typography>
                </dl>
            ))}
        </section>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            boxSizing: "border-box",
            maxWidth: 900,
            margin: "25 auto",
            padding: "0 20px",
        },
        faqContainer: {
            boxSizing: "border-box",
            margin: "20px 0",
            "& dt, dd": {
                fontSize: "1rem",
            },
            "& dt": {
                color: theme.palette.primary.main,
                fontWeight: "bold",
            },
        },
    })
);

export default HelpScreen;
