import { Store } from "@echope/echope-store-core/dist/models";
import { FC } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import {
    Card,
    IconButton,
    Theme,
    Tooltip,
    Typography,
} from "@material-ui/core";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import RemoveShoppingCartIcon from "@material-ui/icons/RemoveShoppingCart";
// import HeaderBackground from "../../../assets/images/store-home-bg.jpeg";
// import {formatPhone} from "../../../util/formatter";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { WebApplicationState } from "../../../store/types";
import { currencyFormatter } from "../../../util/formatter";
import { sendCleanCart } from "@echope/echope-store-core/dist/store/cart";
import { getCustomerUserSelector } from "@echope/echope-store-core/dist/store/customerUser";
import { sendSignOut } from "@echope/echope-store-core/dist/store/auth";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flexDirection: "column",
            margin: theme.spacing(1),
            backgroundColor: theme.palette.primary.main,
        },
        main: {
            width: "100%",
            display: "flex",
            alignItems: "center",
            padding: theme.spacing(1),
            flexDirection: "row",
            justifyContent: "space-between",
            [theme.breakpoints.down("sm")]: {
                flexDirection: "column",
            },
        },
        actions: {},
        icon: {
            color: theme.palette.text.secondary,
        },
        amount: {
            marginLeft: theme.spacing(1),
            color: theme.palette.text.secondary,
        },
        headerText: {
            fontFamily: '"Baloo 2"',
            color: theme.palette.text.secondary,
            marginLeft: theme.spacing(1),
            fontWeight: "bold",
            textAlign: "left",
        },
        link: {
            textDecoration: 'none',
        }
    })
);

export interface StoreHomeHeaderViewProps {
    store: Store;
}

const StoreHomeHeaderView: FC<StoreHomeHeaderViewProps> = ({ store }) => {
    const styles = useStyles();
    const history = useHistory();
    const user = useSelector(getCustomerUserSelector)
    const total = useSelector<WebApplicationState, string>((state) =>
        currencyFormatter.format(state.cart.total)
    );
    const dispatch = useDispatch();

    const handleClickLogout = () => {
        dispatch(sendSignOut())

        history.push('/')
    }

    const username = user?.name ? `, ${user?.name}` : '';

    return (
        <Card className={styles.root}>
            <div className={styles.main}>
                <Typography className={styles.headerText} variant="h5">
                    Bem vindo{username} ! Você está na loja: {store?.name}
                </Typography>

                <div className={styles.actions}>
                    <Tooltip title="Limpar o carrinho">
                        <IconButton onClick={() => dispatch(sendCleanCart())}>
                            <RemoveShoppingCartIcon className={styles.icon} />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Entrar o carrinho">
                        <Link to="/store/cart" className={styles.link}>
                            <IconButton>
                                <ShoppingCartIcon className={styles.icon} />
                                <Typography
                                    className={styles.amount}
                                    variant="body1"
                                >
                                    {total}
                                </Typography>
                            </IconButton>
                        </Link>
                    </Tooltip>

                    <Tooltip title={`Sair da loja ${store?.name}`}>
                        <IconButton onClick={handleClickLogout}>
                            <ExitToAppIcon className={styles.icon} />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
        </Card>
    );
};

export default StoreHomeHeaderView;
