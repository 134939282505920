import { sendCleanCart } from "@echope/echope-store-core/dist/store/cart";
import { FC, useEffect, useRef } from "react";
import { isMobile, isSafari } from "react-device-detect";
import { MapContainer, TileLayer, ZoomControl } from "react-leaflet";
import { useDispatch, useSelector } from "react-redux";
import { getUserLocation } from "../../../store/location";
import { getPreferenceStores, sendUpdatePreferencesSelectedStore, StoreWithDistance } from "../../../store/preferences";
import CompaniesLocation from "./CompaniesLocation";
import UserLocation from "./UserLocation";

export interface LocationProps {
    onUserDeniedLocation?: () => void;
}

const Location: FC<LocationProps> = ({onUserDeniedLocation}) => {

    const distanceCalculated = useRef(false);

    const dispatch = useDispatch();
    const userLocation = useSelector(getUserLocation);
    const stores = useSelector(getPreferenceStores);

    useEffect(() => {
        if (stores == null || stores.length === 0 || userLocation == null) {
            return;
        }

        if (distanceCalculated.current) {
            return;
        }

        distanceCalculated.current = true;
    });

    const selectStore = (store: StoreWithDistance) => {
        dispatch(sendCleanCart());
        dispatch(sendUpdatePreferencesSelectedStore(store));
    }

    return (
        <>
            <MapContainer zoom={10}
                          maxZoom={13}
                          style={{width: '100%', height: '50vh'}}
                          scrollWheelZoom={false}
                          zoomControl={false}
                          whenCreated={map => {
                              if (isSafari && !isMobile){
                                  map.tap?.disable();
                              }
                          }}>
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />

                <ZoomControl
                    zoomInText="+"
                    zoomOutText="-"
                    position="bottomright"
                />

                <UserLocation onUserDeniedLocation={onUserDeniedLocation} />

                <CompaniesLocation onSelectStore={store => selectStore(store)}/>
            </MapContainer>
        </>
    );
};

export default Location;