import {ActionCreator} from "redux";
import {
    CLEAN_PREFERENCES_MESSAGE,
    CleanPreferencesAction,
    StoreWithDistance,
    UPDATE_PREFERENCES_OPERATION_MESSAGE,
    UPDATE_PREFERENCES_SELECTED_STORE_MESSAGE,
    UPDATE_PREFERENCES_STORES_MESSAGE,
    UPDATE_PREFERENCES_USER_LOCATION_MESSAGE,
    UpdatePreferenceOperationAction,
    UpdatePreferenceSelectedStoreAction,
    UpdatePreferenceStoresAction,
    UpdatePreferenceUserLocationAction
} from "./types";
import {Store} from "@echope/echope-store-core/dist/models";
import {LatLng} from "leaflet";
import {Operation} from "@echope/echope-store-core/dist/util";

export const sendUpdatePreferencesStore: ActionCreator<UpdatePreferenceStoresAction> = (stores: Store[]) => ({
    type: UPDATE_PREFERENCES_STORES_MESSAGE,
    stores
});

export const sendUpdatePreferencesSelectedStore: ActionCreator<UpdatePreferenceSelectedStoreAction> =
    (store: StoreWithDistance) => ({
        type: UPDATE_PREFERENCES_SELECTED_STORE_MESSAGE,
        store
    });

export const sendUpdatePreferencesUserLocation: ActionCreator<UpdatePreferenceUserLocationAction> =
    (location: LatLng) => ({
        type: UPDATE_PREFERENCES_USER_LOCATION_MESSAGE,
        location
    });

export const sendUpdatePreferencesOperation: ActionCreator<UpdatePreferenceOperationAction> =
    (operation: Operation) => ({
        type: UPDATE_PREFERENCES_OPERATION_MESSAGE,
        operation
    });

export const sendCleanPreferences: ActionCreator<CleanPreferencesAction> = () => ({
    type: CLEAN_PREFERENCES_MESSAGE
})