import { Box, IconButton, Theme, Typography } from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import { createStyles, makeStyles } from "@material-ui/styles";
import { Product } from "@echope/echope-store-core/dist/models";
import React, { FC } from "react";
import { currencyFormatter } from "../../../util/formatter";

interface ProductItemCardProps {
    product: Product;
    quantityCart: number;
    price: number;
    removeProduct: (product: Product) => void;
}

interface FormatedValues {
    price: string;
}

const ProductItemCard: FC<ProductItemCardProps> = (props) => {
    const { product, quantityCart, removeProduct, price } =
        props;
    const styles = useStyles();
    const [formatedValues, setFormatedValues] = React.useState<FormatedValues>(
        {} as FormatedValues
    );

    React.useEffect(() => {
        setFormatedValues({
            price: currencyFormatter.format(Number(price * quantityCart)),
        });
    }, [price, quantityCart]);

    return (
        <div className={styles.container}>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                className={styles.itemProduct}
            >
                <div className={styles.image}>
                    <img src={product.imageSquareURL} alt={product.name}/>
                </div>
                <div className={styles.content}>
                    <Typography color="primary" variant="h6">
                        {product.name}
                    </Typography>
                    <Typography>Produto da marca {product.brand}</Typography>
                    <Typography>Pertencente à categoria {product.group}</Typography>
                    <Typography>{product.quantity} unidade(s) em estoque</Typography>
                </div>

                <IconButton onClick={() => removeProduct(product)}>
                    <Clear />
                </IconButton>
            </Box>

            <div className={styles.itemPrice}>
                <Typography>
                    {quantityCart} unidade(s) no total de {formatedValues.price}
                </Typography>
            </div>
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            width: "50%",
            display: "flex",
            flexFlow: "column nowrap",
            padding: 10,
            marginBottom: 10,
        },
        content: {
            maxWidth: 250,
        },
        itemProduct: {
            "& .MuiIconButton-root": {
                background: theme.palette.grey[100],
            },
        },
        image: {
            padding: 2,
            border: `1px solid ${theme.palette.grey[400]}`,
            maxWidth: 100,
            "& img": {
                minWidth: 60,
                width: '100%'
            }
        },
        itemPrice: {
            marginTop: 10,
            textAlign: "end",
            "& p": {
                fontWeight: "bold",
            },
        },
        [theme.breakpoints.down("md")]: {
            container: {
                width: "100%",
            },
            itemProduct: {
                gap: 10,
                "& img": {
                    maxWidth: 50,
                },
                "& p": {
                    fontSize: "0.775rem",
                },
            },
        },
    })
);

export default ProductItemCard;
