import { CatalogItem } from "@echope/echope-store-core/dist/models";
import {
    getCatalogItemsSelector,
    getCatalogOperationSelector,
    sendLoadProducts,
    sendUpdateCatalogOperation,
} from "@echope/echope-store-core/dist/store/catalog";
import { Operation } from "@echope/echope-store-core/dist/util";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StoreWithDistance } from "../store/preferences";

export const useCatalogItems = (store?: StoreWithDistance) => {
    const items = useSelector(getCatalogItemsSelector) as CatalogItem[];
    const operation = useSelector(getCatalogOperationSelector);
    const dispatch = useDispatch();

    const [lastStore, setLastStore] = useState(store);

    useEffect(() => {
        const sameStore = lastStore?.store?.id === store?.store?.id;

        if (sameStore && items.length > 0) {
            dispatch(sendUpdateCatalogOperation(Operation.started()));
            return;
        }

        const id = store?.store?.id || null;

        console.log("loading products for store: ", id);

        dispatch(
            sendUpdateCatalogOperation(
                Operation.processing(
                    "Aguarde enquanto estamos carregando o catálogo de produtos"
                )
            )
        );
        dispatch(sendLoadProducts(store?.store));
        setLastStore(store);
    }, [dispatch, items.length, lastStore?.store?.id, store]);

    return [items, operation] as const;
};
