import { newRemoteService } from "@echope/echope-store-core/dist/infrastructure/api";
import { BaseRepository } from "@echope/echope-store-core/dist/infrastructure/repository/base-repository";

export interface AddressRequest {
    id?: number;
    postalCode: string;
    street: string;
    streetNumber: string;
    complement: string;
    neighborhood: string;
    locality: string;
    state: string;
}

export interface UserRequest {
    id?: number;
    name: string;
    cpfCnpj: string;
    rgInscricao: string;
    birthDate: string;
    celphone: string;
    phone: string;
    accessKey: string;
    accessPass: string;
    addresses: AddressRequest[];
}

export interface AddressResponse {
    id: number;
    postalCode: string;
    street: string;
    streetNumber: string;
    complement: string;
    neighborhood: string;
    locality: string;
    state: string;
}

export interface UserResponse {
    timestamp: number;
    data: {
        id: number;
        accessKey: string;
        accessPass: string;
        type: string;
        name: string;
        displayName: string;
        birthDate: string;
        creationDate: string;
        cpfCnpj: string;
        rgInscricao: string;
        celphone: string;
        phone: string;
        addresses: AddressResponse[];
    };
}

class UserRepositoryImpl extends BaseRepository {
    saveUser(payload: UserRequest) {
        return newRemoteService().post<UserRequest, { data: string }>(
            {
                authenticated: false,
                endpoint: this.composeURL(this.path.user?.create() || ""),
            },
            payload
        );
    }

    getUserData(email: string) {
        return newRemoteService().get<UserResponse>({
            authenticated: true,
            endpoint: this.composeURL(this.path.user?.currentUser(email) || ""),
        });
    }
}

const userImpl = () => new UserRepositoryImpl();

export default userImpl;
