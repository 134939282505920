import { Address } from "@echope/echope-store-core/dist/models";
import { AddressRequest } from "../services/User";

export const getInCityAddressText = (addr: Address) => {
    return (
        "" +
        (addr.street + ", ") +
        (addr.complement ? addr.complement + ", " : "") +
        (addr.streetNumber + " - ") +
        addr.neighborhood +
        ", " +
        addr.postalCode
    );
};

export const getOutCityAddressText = (addr: Address) => {
    return "" + addr.locality + " - " + addr.state;
};

export const getAddressText = (addr: Address) => {
    return getInCityAddressText(addr) + ", " + getOutCityAddressText(addr);
};

export const getInCityAddressTextReq = (addr: AddressRequest) => {
    return (
        "" +
        (addr.street + ", ") +
        (addr.complement ? addr.complement + ", " : "") +
        (addr.streetNumber + " - ") +
        addr.neighborhood +
        ", " +
        addr.postalCode
    );
};

export const getOutCityAddressTextReq = (addr: AddressRequest) => {
    return "" + addr.locality + " - " + addr.state;
};

export const getAddressTextReq = (addr: AddressRequest) => {
    return getInCityAddressTextReq(addr) + ", " + getOutCityAddressTextReq(addr);
};
