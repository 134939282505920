import React, { Dispatch, FC, SetStateAction, useEffect } from "react"
import { Box, Button, Theme, Typography } from "@material-ui/core"
import { makeStyles, createStyles } from "@material-ui/styles"
import { Link } from "react-router-dom"
// import ReactPixel from 'react-facebook-pixel'

interface CookiesAccept {
    isAccepted: boolean
    handlerAccept: Dispatch<SetStateAction<boolean>>
}

const AcceptCookies: FC<CookiesAccept> = (props) => {
    const { isAccepted, handlerAccept } = props;
    const styles = useStyles();

    return (
        <>
            {!isAccepted ? (
                <div className={styles.root}>
                    <Box>
                        <Typography>
                            Este site usa cookies e dados pessoais de acordo com
                            os nossos{"  "}
                            <Link to="/usage">Termos de Uso</Link> e{"  "}
                            <Link to="/privacy">Política de Privacidade</Link>{"  "}
                            e, ao continuar navegando neste site, você declara
                            estar ciente dessas condições.
                        </Typography>
                    </Box>
                    <Button
                        className={styles.acceptButton}
                        variant="contained"
                        onClick={() => {
                            handlerAccept(true);
                            // ReactPixel.grantConsent();
                            localStorage.setItem('acceptedCookies', 'true');
                        }}
                    >
                        Ok
                    </Button>
                </div>
            ) : (
                <></>
            )}
        </>
    )
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: "fixed",
            bottom: 10,
            left: 10,
            right: 10,
            height: 'auto',
            zIndex: theme.zIndex.modal,
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.text.secondary,
            display: "flex",
            flexFlow: "row nowrap",
            alignItems: "center",
            justifyContent: "space-between",
            padding: 20,
            borderRadius: 5,
            "& p": {
                fontSize: "0.9rem",
                lineHeight: '1.5rem'
            },
            "& a": {
                color: theme.palette.text.secondary,
                fontWeight: 'bold',
            },
        },
        [theme.breakpoints.down('md')]: {
            root: {
                flexFlow: 'column nowrap',
            },
            acceptButton: {
                marginTop: 20,
            }
        }
    })
)

export default AcceptCookies
