import { createStyles, makeStyles } from "@material-ui/core";
import React, { FC } from "react";
import { useSelector } from "react-redux";
import { useWindowSize } from "../../../hooks/WindowSize";
import { isUserGrantedLocation } from "../../../store/location";
import { getPreferenceSelectedStore } from "../../../store/preferences";
import { valueFromProps } from "../../../util/ValueFromProps";
import InstitutionBanner from "../../institution/InstitutionBanners";
import PostalCodeLocation from "./PostalCodeLocation";
import SelectedCompany from "./SelectedCompany";

const useStyles = makeStyles(theme => createStyles({
    container: {
        display: "flex",
        [theme.breakpoints.down("sm")]: {
            flexWrap: "wrap-reverse"
        }
    },
    box: {
        display: "flex",
        flex: 2,
        [theme.breakpoints.down("sm")]: {
            flexBasis: "100%"
        }
    },
    sloganBox: {
        width: "auto",
        height: props => valueFromProps(props, "sloganHeight", 270),
        [theme.breakpoints.down("sm")]: {
            display: "none"
        }
    }
}));

const HomeTopBox: FC = () => {

    const windowSize = useWindowSize();
    const height = (windowSize.width || 1440) * 0.33 * 0.5625;
    const styles = useStyles({ sloganHeight: height });
    const locationGranted = useSelector(isUserGrantedLocation);

    const store = useSelector(getPreferenceSelectedStore);

    return (
        <div className={styles.container}>
            <div className={styles.box}>
                {
                    locationGranted || store != null ?
                        <SelectedCompany store={store?.store} distance={store?.formattedDistance} /> :
                        <PostalCodeLocation />
                }
            </div>
            <InstitutionBanner />
        </div>
    );
}

export default HomeTopBox;