import {CatalogState} from "@echope/echope-store-core/dist/store/catalog";
import {operationFrom} from "./OperationStateLoad";
import {Brand, CatalogItem, Group} from "@echope/echope-store-core/dist/models";

const catalogFrom = (state: any): CatalogState => {
    try {
        const { operation, items, filteredItems, brands, groups } = state;

        return new CatalogState(
            operationFrom(operation),
            catalogItemsFrom(items),
            catalogItemsFrom(filteredItems),
            brands.map((b: any) => b as Brand),
            groups.map((g: any) => g as Group)
        );
    } catch (e) {
        console.log("error loading catalog state from storage", e);
        return CatalogState.empty();
    }
}

const catalogItemsFrom = (element: any): CatalogItem[] => {
    return element.map((e: any) => {
        const { product, brand, group, availableAmount, selectedAmount } = e;

        return new CatalogItem(product, brand, group, availableAmount, selectedAmount);
    })
}

export {
    catalogFrom
}