import {FC} from "react";
import StyledContainer from "../../components/core/StyledContainer";
import {createStyles, Grid, makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => createStyles({
    container: {
        justifyContent: "center",
        alignItems: "center"
    },
    box: {
        textAlign: "justify"
    }
}))

const UsageTermsScreen: FC = () => {
    const styles = useStyles();

    return (
        <StyledContainer className={styles.container}>
            <Grid item md={8} sm={10} xs={10} className={styles.box}>
                <h2>Preâmbulo:</h2>


                <p>Estes
                    termos de serviço regulam o uso deste site. Por favor, consulte regularmente os
                    nossos termos de serviço.</p>


                <p>O
                    acesso ao site representa a aceitação expressa e irrestrita dos termos de uso
                    abaixo descritos e também a nossa Política de Privacidade. Se você não concorda
                    com os termos, por favor, não acesse nem utilize este web site.</p>


                <p>O
                    visitante poderá usar este site apenas para finalidades lícitas. Este espaço
                    não poderá ser utilizado para publicar, enviar, distribuir ou divulgar
                    conteúdos ou informação de caráter difamatório, obsceno ou ilícito, inclusive
                    informações de propriedade exclusiva pertencentes a outras pessoas ou empresas,
                    bem como marcas registradas ou informações protegidas por direitos autorais,
                    sem a expressa autorização do detentor desses direitos. Ainda, o visitante não
                    poderá usar o site para obter ou divulgar informações pessoais, inclusive
                    endereços na Internet, sobre os usuários do site.</p>


                <p>A
                    ECHOPE empenha-se em manter a qualidade, atualidade e autenticidade das
                    informações do site, mas não nos responsabilizamos por eventuais falhas nos
                    serviços ou inexatidão das informações oferecidas. O usuário não deve ter como
                    pressuposto que tais serviços e informações são isentos de erros ou serão
                    adequados aos seus objetivos particulares. </p>


                <p>A
                    ECHOPE se reserva ao direito de alterar as condições de uso ou preços dos
                    serviços e produtos oferecidos no site a qualquer momento.</p>


                <p>O
                    acesso ao site é gratuito. </p>


                <p>A
                    ECHOPE poderá, a seu exclusivo critério e em qualquer tempo, modificar ou
                    desativar o site, bem como limitar, cancelar ou suspender seu uso ou o acesso.</p>


                <p>Também
                    estes Termos de Uso poderão ser alterados a qualquer tempo. Visite regularmente
                    esta página e consulte os Termos então vigentes. Algumas disposições destes
                    Termos podem ser substituídas por termos ou avisos legais expressos localizados
                    em determinadas páginas deste site.</p>


                <p>Os
                    presentes Termos aplicam-se às Páginas relacionadas a quaisquer das marcas da
                    ECHOPE e seu aplicativo móvel.</p>


                <p>Ainda,
                    aplicam-se aos presentes Termos e na utilização das Páginas o quanto disposto
                    na Política de Privacidade da ECHOPE, disponível em
                    https://www.echope.com.br/politica-de-privacidade.</p>


                <h2>1. OBJETO</h2>


                <p>Através
                    do Site, a ECHOPE proporciona aos usuários informações gerais sobre a empresa
                    e sobre seus produtos, bem como o acesso a diversos serviços e conteúdos e,
                    ainda, a venda de produtos.</p>


                <h2>2. DECLARAÇÃO DE MAIORIDADE</h2>


                <p>O
                    Site não é direcionado a e não deve ser utilizado por usuários menores de
                    idade. De modo a evitar o acesso indevido, a ECHOPE cientifica claramente aos
                    usuários que seu conteúdo é voltado a maiores de 18 (dezoito) anos, através do
                    formulário na página de confirmação de idade ao entrar no Site.</p>


                <p>A
                    ECHOPE, no entanto, não se responsabiliza pelo acesso indevido ao Site,
                    decorrente de informações incorretas ou inverídicas fornecidas pelo usuário ou
                    do desrespeito à classificação etária indicada no Site.</p>


                <h2>3. EXCLUSÃO DE GARANTIA E RESPONSABILIDADE</h2>


                <p>A
                    ECHOPE não se responsabiliza por nenhum dano decorrente do uso (ou da
                    incapacidade de fazer uso) do Site, inclusive danos causados por vírus ou
                    qualquer incorreção ou falha. A ECHOPE não será responsável por danos
                    resultantes de falta de compatibilidade, atualização ou precisão do Site ou das
                    informações prestadas.</p>


                <p>Ainda,
                    a ECHOPE não será responsável por danos resultantes do uso inadequado de meios
                    eletrônicos, incluindo, mas não limitados a danos resultantes de falhas ou
                    atrasos na entrega de comunicações eletrônicas, interceptação ou manipulação de
                    comunicações eletrônicas por terceiros ou por programas de computador e
                    transmissão de vírus.</p>


                <p>As
                    informações provindas de terceiros constituem expressão das opiniões pessoais
                    desses terceiros. A ECHOPE não é responsável por essas informações nem
                    responderá por elas.</p>


                <p>Em
                    decorrência de questões técnicas e operacionais, a ECHOPE não pode garantir a
                    disponibilidade, infalibilidade e continuidade do funcionamento das Páginas,
                    nem garante a utilidade das Páginas para a realização de qualquer atividade em
                    concreto. Desse modo, a ECHOPE não será, em hipótese alguma, responsável por
                    quaisquer danos decorrentes da interrupção do acesso às Páginas, ou por falhas
                    de funcionamento das mesmas, nem se responsabiliza pela defraudação da
                    utilidade que os Usuários possam ter atribuídos Páginas, pela falibilidade das
                    mesmas, nem por qualquer dificuldade de acesso.</p>


                <p>A
                    ECHOPE utiliza as melhores práticas recomendadas de mercado para manter seguros
                    todos os dados inseridos pelo Usuário nas Páginas, no entanto não garante que
                    terceiros não autorizados não farão uso de meios ilícitos para obter
                    indevidamente tais informações. Assim, a ECHOPE se exime de responsabilidade
                    pelos danos e prejuízos de toda natureza que possam decorrer do conhecimento
                    que terceiros não autorizados possam ter de quaisquer das informações
                    repassadas, em decorrência de falha exclusivamente atribuível aos Usuários ou a
                    terceiros que fujam a qualquer controle razoável da ECHOPE.</p>


                <p>A
                    ECHOPE não garante a ausência de vírus nas Páginas, bem como de outros
                    elementos nocivos que possam produzir alterações nos sistemas informáticos dos
                    Usuários (software e hardware) ou nos documentos eletrônicos armazenados no
                    sistema informático, eximindo-se de qualquer responsabilidade pelos danos e
                    prejuízos que possam decorrer de quaisquer elementos nocivos.</p>


                <p>A
                    ECHOPE não verifica ou controla o uso que o Usuário faz das páginas. Dessa
                    forma, a ECHOPE se exime de qualquer responsabilidade pelos danos e prejuízos
                    de toda natureza que possam decorrer da utilização das Páginas ou que possam
                    ser devidos à falta de veracidade, vigência, exaustividade e/ou autenticidade
                    do conteúdo lá disponibilizado, bem como da informação que os Usuários
                    proporcionam a outros Usuários sobre si mesmos e, em particular, ainda que não
                    de forma exclusiva, pelos danos e prejuízos de toda a natureza que possam ser
                    devidas à suplantação da personalidade efetuada por um Usuário em qualquer
                    classe de comunicação realizada por meio das Páginas.</p>


                <h2>4. PROPRIEDADE INTELECTUAL</h2>


                <p>Salvo
                    disposição em contrário, todos os direitos de propriedade intelectual do Site
                    são de propriedade da ECHOPE.</p>


                <h2>5. OBRIGAÇÃO DE UTILIZAR AS PÁGINAS CORRETAMENTE</h2>


                <p>Ao
                    utilizar as Páginas da ECHOPE, o Usuário se compromete a respeitar a
                    legislação brasileira vigente, bem como todo o quanto disposto nos presentes
                    Termos, não devendo produzir, disponibilizar, divulgar ou transmitir qualquer
                    conteúdo que:</p>


                <p>a)
                    Seja contrário a qualquer norma da legislação brasileira, bem como à moral e
                    aos bons costumes geralmente aceitos;</p>


                <p>b)
                    Incentive a discriminação, o ódio ou a violência contra pessoas ou grupos em
                    razão de orientação sexual, gênero, raça, religião, deficiência, nacionalidade
                    ou por qualquer outro motivo;</p>


                <p>c)
                    Incorporem, ponham à disposição ou permitam acessar produtos, elementos,
                    mensagens e/ou serviços ilegais, violentos, pornográficos, degradantes ou, em
                    geral, contrários à lei, à moral e aos bons costumes geralmente aceitos ou à
                    ordem pública;</p>


                <p>d)
                    Sejam falsos, ambíguos, inexatos, exagerados ou extemporâneos, de forma que
                    possam induzir a erro sobre seu objeto ou sobre as intenções ou propósitos do
                    comunicante;</p>


                <p>e)
                    Sejam protegidos por quaisquer direitos de propriedade intelectual ou
                    industrial pertencentes a terceiros, sem que o Usuário tenha obtido</p>

                <p>previamente
                    dos seus titulares a autorização necessária para levar a cabo o uso que efetuar
                    ou pretender efetuar;</p>


                <p>f)
                    Incorporem vírus ou outros elementos físicos ou eletrônicos que possam causar
                    dano ou impedir o normal funcionamento da rede, do sistema ou de equipamentos
                    informáticos (“hardware” e “software”) de terceiros, ou que possam causar dano
                    aos documentos eletrônicos e arquivos armazenados nestes equipamentos
                    informáticos; ou,</p>


                <p>g)
                    Provoquem, por suas características (tais como forma, extensão etc.)
                    dificuldades no normal funcionamento do Serviço.</p>


                <p>Em
                    qualquer circunstância, o Usuário é o único responsável pelo uso que fizer das
                    Páginas da ECHOPE, bem como por qualquer conteúdo por ele inserido.</p>


                <h2>6. PRIVACIDADE</h2>


                <p>A
                    utilização de alguns dos serviços oferecidos no Site implica na necessidade do
                    fornecimento de dados de caráter pessoal, os quais serão processados por meios
                    automatizados segundo o disposto na Política de Privacidade
                    [https://www.echope.com.br/politica-de-privacidade].</p>


                <p>A
                    ECHOPE adota níveis de segurança de proteção de dados pessoais legalmente
                    requeridos. Não obstante, o usuário deve estar consciente de que as medidas de
                    segurança na rede não são infalíveis.</p>


                <h2>7. RETIRADA E SUSPENSÃO DOS SERVIÇOS</h2>


                <p>A
                    ECHOPE se reserva o direito de recusar ou retirar o acesso ao Site e/ou aos
                    serviços, a qualquer momento e sem necessidade de prévio aviso, por iniciativa
                    própria ou por exigência de um terceiro, àqueles usuários que descumprirem
                    estes Termos de Uso.</p>


                <h2>8. DURAÇÃO E FINALIZAÇÃO</h2>


                <p>A
                    prestação dos serviços oferecidos no Site tem uma duração indefinida.</p>


                <h2>9. LEGISLAÇÃO E FORO</h2>


                <p>Estes
                    Termos de Uso serão regidos e interpretados de acordo com a legislação
                    brasileira, independentemente dos conflitos dessas leis com leis de outros
                    estados ou países, sendo competente o Foro da Comarca de Araraquara-SP, com
                    exclusão de qualquer outro, para dirimir qualquer dúvida decorrente destes
                    Termos de Uso. O usuário consente, expressamente, com a competência desse juízo
                    com exclusão de qualquer outro, por mais privilegiado que seja.</p>
            </Grid>
        </StyledContainer>
    )
}

export default UsageTermsScreen;