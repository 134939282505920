import { createTheme, IconButton, Theme, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { format } from 'date-fns';
import { getSelectedVoucher, sendApplyVoucherDiscount } from '@echope/echope-store-core/dist/store/voucher';
import { VoucherPrepared } from '@echope/echope-store-core/dist/use-cases/voucher';
import { isNullOrUndefined } from '@echope/echope-store-core/dist/util/assertions';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useVoucher } from '../../../hooks/useVoucher';
import { getPreferenceSelectedStore } from '../../../store/preferences';
import VoucherGoal from './VoucherGoal';

interface VoucherItemProps {
    voucherPrepared: VoucherPrepared;
    handleClose: () => void;
}

const VoucherItem: React.FC<VoucherItemProps> = ({ voucherPrepared, handleClose }) => {
    const { voucher, remove, mayApply } = voucherPrepared;
    const styles = useStyles();
    const theme: Theme = createTheme();

    const { voucherValidate, voucherDescription } = useVoucher(voucher, mayApply);

    const dispatch = useDispatch();
    const voucherSelected = useSelector(getSelectedVoucher)
    const store = useSelector(getPreferenceSelectedStore)

    const [isSelected, setIsSelected] = React.useState(false);
    const [dateExpire, setDateExpire] = React.useState('');

    const actionText = isSelected ? "Desativar" : "Ativar";
    const backgroundColor = isSelected ? theme.palette.error.main : theme.palette.success.main;

    const handleActiveVoucher = () => {
        if (!voucherValidate.mayApply)
            return;

        if (isSelected) {
            remove(dispatch);
            handleClose();
            return;
        }

        if (!isNullOrUndefined(voucher)) {
            dispatch(sendApplyVoucherDiscount(voucher, store?.store));
            handleClose();
        }
    }

    useEffect(() => {
        if (voucher.expiration) {
            setDateExpire(format(voucher.expiration, 'dd/MM/yyyy hh:mm:ss'))
        } else {
            setDateExpire('Não informado')
        }
    }, [voucher])

    useEffect(() => {
        if (voucherSelected !== null) {
            if (voucherSelected.id) {
                setIsSelected(voucherSelected.id === voucher.id)
            }
        } else {
            setIsSelected(false)
        }
    }, [voucher.id, voucherSelected])

    return (
        <div className={styles.voucherItem}>

            <Typography variant="h6" component="h3" color="primary">
                {voucher.description}
            </Typography>

            <div className={styles.voucherContent}>
                <div>
                    <Typography>Código: {voucher.code}</Typography>

                    <Typography>Válido até: {dateExpire} </Typography>

                    <Typography className={styles.voucherDescription}>
                        {voucherDescription}
                    </Typography>

                    {!voucherValidate.valid &&
                        (<Typography className={styles.voucherDescription} color="error">
                            {voucherValidate.message}
                        </Typography>)
                    }
                </div>

                {voucherValidate.valid &&
                    (<div>
                        <IconButton onClick={() => handleActiveVoucher()}>
                            <VoucherGoal
                                fillColor={theme.palette.grey[600]}
                                actionColor={backgroundColor}
                                actionText={actionText}
                                backgroundColor="#e9e9e9"
                                current={voucherValidate.current || 0}
                                goal={voucherValidate.goal || 0}
                                size={75}
                                strokeWidth={8}
                            />
                        </IconButton>
                    </div>)
                }

            </div>
        </div >
    )
}


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        voucherItem: {
            display: 'flex',
            flexFlow: 'column nowrap',
            margin: '15px 0',
            padding: '10px',
            borderRadius: 10,
            gap: 5,
            background: theme.palette.grey[100]
        },
        voucherContent: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            gap: 5,
        },
        voucherDescription: {
            marginTop: 5,
            fontWeight: 'bold',
        },
        [theme.breakpoints.down("sm")]: {
            voucherItem: {
                '& p': {
                    fontSize: 12
                },
                '& h3': {
                    fontSize: 14,
                },
                '& .MuiIconButton-root': {
                    padding: 0,
                }
            },
            voucherContent: {
                gap: 0,
            },
        }
    })
);


export default VoucherItem;