import {Store} from "@echope/echope-store-core/dist/models";
import {Operation} from "@echope/echope-store-core/dist/util";
import {LatLng} from "leaflet";
import {calculateDistance, findNearestStore} from "./nearest";
import {Action} from "redux";
import {operationFrom} from "../config/state/OperationStateLoad";
import {getStore} from "../config/state/StoreLoad";
import {isNullOrUndefined} from "../../util/Asserts";
import {isNonNullOrUndefined} from "@echope/echope-store-core/dist/util/assertions";

export class StoreWithDistance {
    readonly store: Store | undefined;
    readonly distance: number;

    constructor(store: Store | undefined, distance: number) {
        this.store = store;
        this.distance = distance;
    }

    get hasDistance(): boolean {
        return this.distance !== -1;
    }

    get formattedDistance(): string {
        return `${this.distance.toFixed(2)}Km`;
    }

    public updateWithDistance(distance: number): StoreWithDistance {
        return new StoreWithDistance(this.store, distance);
    }

    public static createDefault(): StoreWithDistance{
        return new StoreWithDistance(undefined, -1);
    }
}

export class PreferenceState {
    readonly stores: StoreWithDistance[];
    readonly selectedStore: StoreWithDistance | undefined;
    readonly operation: Operation;
    readonly creation: Date;
    readonly timeToLive: number;

    constructor(
        stores: StoreWithDistance[] | undefined,
        selectedStore: StoreWithDistance | undefined,
        operation: Operation,
        creation: Date,
        timeToLive: number
    ) {
        this.stores = stores || [];
        this.selectedStore = selectedStore;
        this.operation = operation;
        this.creation = creation;
        this.timeToLive = timeToLive;
    }

    public updateWithSelectStore(store: StoreWithDistance): PreferenceState {
        return new PreferenceState(this.stores, store, this.operation, this.creation, this.timeToLive);
    }

    public updateWithStores(stores: Store[]): PreferenceState {
        const storesWithDistance = stores.map(store => new StoreWithDistance(store, -1));
        return new PreferenceState(storesWithDistance, this.selectedStore, this.operation, this.creation, this.timeToLive);
    }

    public updateWithOperation(operation: Operation): PreferenceState {
        return new PreferenceState(this.stores, this.selectedStore, operation, this.creation, this.timeToLive);
    }

    public updateWithLocation(location: LatLng): PreferenceState {
        if (this.stores.length === 0){
            return this;
        }

        const stores = this.reloadDistances(location);
        const nearest = findNearestStore(stores)(location);

        return new PreferenceState(stores, nearest, this.operation, this.creation, this.timeToLive);
    }

    public clear(): PreferenceState {
        return PreferenceState.empty();
    }

    public static empty(): PreferenceState {
        return new PreferenceState([],undefined, Operation.started(), new Date(), 1800);
    }

    public static valueOf(state: any): PreferenceState {
        const getStoreWithDistance = (item: any): StoreWithDistance | undefined => {
            if(isNullOrUndefined(item)){
                return undefined;
            }

            const { store, distance } = item;
            return new StoreWithDistance(getStore(store), distance as number);
        };

        const { stores, selectedStore, operation, creation, timeToLive } = state;

        return new PreferenceState(
            stores.map((s: any) => getStoreWithDistance(s)).filter((s: any) => isNonNullOrUndefined(s)),
            getStoreWithDistance(selectedStore),
            operationFrom(operation),
            new Date(creation),
            timeToLive as number,
        );
    }

    private reloadDistances(location: LatLng): StoreWithDistance[] {
        const action = calculateDistance(location);
        return this.stores.map(s => s.updateWithDistance(action(s.store!)));
    }
}

export const UPDATE_PREFERENCES_STORES_MESSAGE = "UPDATE_PREFERENCES_STORES_MESSAGE";
export const UPDATE_PREFERENCES_SELECTED_STORE_MESSAGE = "UPDATE_PREFERENCES_SELECTED_STORE_MESSAGE";
export const UPDATE_PREFERENCES_USER_LOCATION_MESSAGE = "UPDATE_PREFERENCES_USER_LOCATION_MESSAGE";
export const UPDATE_PREFERENCES_OPERATION_MESSAGE = "UPDATE_PREFERENCES_OPERATION_MESSAGE";
export const CLEAN_PREFERENCES_MESSAGE = "CLEAN_PREFERENCES_MESSAGE";

export interface UpdatePreferenceStoresAction extends Action<typeof UPDATE_PREFERENCES_STORES_MESSAGE>{
    stores: Store[];
}

export interface UpdatePreferenceSelectedStoreAction extends Action<typeof UPDATE_PREFERENCES_SELECTED_STORE_MESSAGE>{
    store: StoreWithDistance;
}

export interface UpdatePreferenceUserLocationAction extends Action<typeof UPDATE_PREFERENCES_USER_LOCATION_MESSAGE>{
    location: LatLng
}

export interface UpdatePreferenceOperationAction extends Action<typeof UPDATE_PREFERENCES_OPERATION_MESSAGE>{
    operation: Operation;
}

export interface CleanPreferencesAction extends Action<typeof CLEAN_PREFERENCES_MESSAGE>{
}