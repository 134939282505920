import { getCustomerUserSelector } from "@echope/echope-store-core/dist/store/customerUser";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FormValues } from "../screen/user/RegistrationScreen";
import userImpl, { UserResponse, AddressResponse } from "../services/User";

const useUserRegistrationData = (isLogged: boolean) => {
    const user = useSelector(getCustomerUserSelector);
    const [userData, setUserData] = useState({ data: {} } as UserResponse);
    const [userAddress, setUserAddress] = useState({} as AddressResponse);
    const [formValuesData, setFormValuesData] = useState({addressesForm: {}} as FormValues);

    useEffect(() => {
        const fetchUser = async () => {
            if (!user?.email) return;

            try {
                const response = await userImpl().getUserData(user.email);

                setUserData(response);

                if (response.data.addresses.length > 0) {
                    setUserAddress(response.data.addresses[0]);
                }
            } catch (error) {
                console.log("erro ao buscar usuário", error);
            }
        };

        if (isLogged && user) {
            fetchUser();
        }
    }, [isLogged, user]);

    useEffect(() => {
        if (isLogged) {
            setFormValuesData({
                name: userData.data.name || "",
                cpfCnpj: userData.data.cpfCnpj || "",
                rgInscricao: userData.data.rgInscricao || "",
                birthDate: "",
                birthDateForm: !!userData.data.birthDate
                    ? new Date(userData.data.birthDate)
                    : null,
                celphone: userData.data.celphone || "",
                phone: userData.data.phone || "",
                accessKey: userData.data.accessKey || "",
                accessPass: userData.data.accessPass || "",
                accessKeyRepeat: "",
                accessPassRepeat: "",
                addressesForm: {
                    id: 0,
                    postalCode: userAddress?.postalCode || "",
                    street: userAddress?.street || "",
                    streetNumber: userAddress?.streetNumber || "",
                    complement: userAddress?.complement || "",
                    neighborhood: userAddress?.neighborhood || "",
                    locality: userAddress?.locality || "",
                    state: userAddress?.state || "",
                },
                addresses: [],
            });
        }
    }, [isLogged, userAddress, userData]);

    return formValuesData;
};

export default useUserRegistrationData;
