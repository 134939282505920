import { Operation } from "@echope/echope-store-core/dist/util";
import React, { FC } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { valueFromProps } from "../../util/ValueFromProps";
import { Typography } from "@material-ui/core";
import Loading from "../../assets/animations/loading.gif";

export interface LoadingSectionViewProps {
   operation?: Operation
   message?: string,
   loadingSize?: number
}

const LoadingSectionView: FC<LoadingSectionViewProps> = (props) => {
   const {message, operation} = props;

   if (message === undefined && operation === undefined) {
      throw new Error("we should have message or operation property")
   }

   const text = message || operation?.message;
   const size = props.loadingSize || 120;
   const styles = useStyles({size});

   return (
      <div className={styles.container}>
         <div className={styles.content}>
            <img className={styles.loading} alt="loading" src={Loading}/>
            <Typography variant={"caption"}>{text}</Typography>
         </div>
      </div>
   );
}

const useStyles = makeStyles((theme: Theme) =>
   createStyles({
      container: {
         marginTop: theme.spacing(10),
         display: "flex",
         flex: 1,
         alignItems: 'center',
         alignContent: 'center',
         justifyContent: 'space-around'
      },
      content: {
         display: "flex",
         flexDirection: "column",
         alignItems: 'center',
         alignContent: 'center'
      },
      loading: {
         width: (props) => valueFromProps(props, "size", 120),
         height: (props) => valueFromProps(props, "size", 120),
      }
   })
);

export default LoadingSectionView;