import {
    Card,
    CardContent,
    CardHeader, IconButton,
    Modal,
    Theme,
    Typography
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { createStyles, makeStyles } from "@material-ui/styles";
import { VoucherPrepared } from "@echope/echope-store-core/dist/use-cases/voucher";
import React, { Dispatch, FC, SetStateAction } from "react";
import VoucherItem from "./components/VoucherItem";

export interface CouponDiscountProps {
    modalCouponDiscount: boolean;
    setModalCouponDiscount: Dispatch<SetStateAction<boolean>>;
    vouchers: VoucherPrepared[]
}

const CouponDiscount: FC<CouponDiscountProps> = ({
    modalCouponDiscount,
    setModalCouponDiscount,
    vouchers,
}) => {
    const classes = useStyles();

    // const [openModalCouponDiscount, setOpenModalCouponDiscount] = React.useState(false)

    const handleClose = () => setModalCouponDiscount(false);

    return (
        <div>
            <Modal
                open={modalCouponDiscount}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Card className={classes.cardWrapper}>
                    <CardHeader
                        action={
                            <IconButton aria-label="close" onClick={handleClose}>
                                <Close />
                            </IconButton>
                        }
                    />

                    <CardContent component="fieldset">
                        <Typography component="legend" color="primary" variant="h6">
                            Meus vouchers
                        </Typography>
                        <div className={classes.cardContent}>
                            {vouchers.map(voucher => (
                                <VoucherItem key={voucher.voucher.id} voucherPrepared={voucher} handleClose={handleClose} />
                            ))}
                        </div>
                    </CardContent>
                </Card>
            </Modal>
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cardWrapper: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "50%",
            boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.5)",
            padding: '0 16px 16px',
            '& .MuiCardHeader-root': {
                color: theme.palette.primary.main,
                padding: '10px 0 0',
            },
            '& .MuiCardContent-root': {
                padding: 10,
            }
        },
        cardContent: {
            maxHeight: 400,
            overflowX: 'auto',
            padding: '0 10px 0 0 ',
        },
        cardActions: {
            display: "flex",
            justifyContent: "space-between",
            "& button": {
                fontSize: "1.125rem",
                textTransform: "uppercase",
            },
        },
        cardIcon: {
            width: 150,
            height: 150,
        },
        toast: {
            zIndex: 1400,
            position: "absolute",
            top: 60,
            right: 25,
            display: "flex",
            alignItems: "center",
            gap: 20,
            padding: 10,
            width: 250,
            height: 100,
            color: "#fafafa",
            backgroundColor: "#bd362f",
            transition: "ease 1s",
        },
        textPrincipal: {
            textTransform: "uppercase",
            fontSize: "1.5rem",
            margin: "30px 0 20px",
        },
        textInfo: {
            fontSize: "1.025rem",
            marginBottom: 60,
        },
        [theme.breakpoints.down("sm")]: {
            cardWrapper: {
                width: "100%",
                height: "auto",
                padding: 0,
            },
            cardContent: {
                padding: 0,
            },
            cardIcon: {
                width: 100,
                height: 100,
            },
            textInfo: {
                marginBottom: 30,
            },
            cardActions: {
                "& button": {
                    fontSize: "1em",
                },
            },
        },
    })
);

export default CouponDiscount;
