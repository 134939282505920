import styled from "styled-components";
import {FC, ReactNode} from "react";
import {Typography} from "@material-ui/core";

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px 10px;
`;

const SlideContainer = styled.div`
  align-items: center;
  display: flex;
  align-self: center;
  width: 100%;
  height: 100%;
`;

export interface SlideProps {
    title?: string;
    description?: string;
    image?: string;
    active?: boolean;
    view?: ReactNode
}

const Slide: FC<SlideProps> = ({title, description, view, image, active}) => {

    const showText = title != null || description != null;
    const showImage = image != null;
    const showView = view != null;

    return (
        <SlideContainer>
            {
                showImage &&
                <img src={image} alt="" style={{ width: '100%', height: 'auto' }} />
            }

            {
                showText &&
                <TextWrapper>
                    {
                        title != null &&
                        <Typography variant={"body2"}>{title}</Typography>
                    }

                    {
                        description != null &&
                        <Typography variant={"caption"}>{description}</Typography>
                    }
                </TextWrapper>
            }

            {showView && view}
        </SlideContainer>
    )
}

export default Slide;