import { Theme, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import { Order } from "@echope/echope-store-core/dist/models";
import { CartState } from "@echope/echope-store-core/dist/store/cart";
import { getCustomerOperationSelector } from "@echope/echope-store-core/dist/store/customer";
import { getCustomerUserSelector, getHistoryOrderSelector, sendFindHistoryOrders } from "@echope/echope-store-core/dist/store/customerUser";
import React, { FC } from 'react';
import { useDispatch, useSelector } from "react-redux";
import LazyView from "../../components/lazy/LazyView";
import LoadingSectionView from "../../components/lazy/LoadingSectionView";
import DetailsPurchase from "../../components/modal/DetailsPurchase";
import { WebApplicationState } from "../../store/types";
import ItemLastBuy from "./components/ItemLastBuy";

const MONTH_NAMES = ['Jan', 'Fev', 'Mar', 'Abr', 'Maio', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']

const HistoryBuy: React.FC = () => {
    const styles = useStyles();
    const dispatch = useDispatch();
    const historyOrders = useSelector(getHistoryOrderSelector)
    const [modalDetailsPurchase, setModalDetailsPurchase] = React.useState(false)
    const [orderToModal, setOrderToModal] = React.useState({} as Order)
    const [agroupedOrders, setAgroupedOrders] = React.useState({})

    const customerOperation = useSelector(getCustomerOperationSelector);
    const user = useSelector(getCustomerUserSelector)
    const cartState = useSelector<WebApplicationState, CartState>((state) => {
        return state.cart;
    });

    const handleClickLastBuy = (value: Order) => {
        setModalDetailsPurchase(true);
        setOrderToModal(value)
    }

    const formatText = (value: string) => {
        const arrValues = value.split('_')
        const mes = MONTH_NAMES[parseInt(arrValues[1])]
        return `${mes} ${arrValues[0]}`
    }

    React.useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });

        const findHistory = () => {
            if (user?.id) {
                dispatch(sendFindHistoryOrders(user?.id))
            }
        }

        findHistory();
    }, [dispatch, user]);

    React.useEffect(() => {
        function groupYears() {
            if (!historyOrders) return;

            const agrupados = historyOrders.reduce((prev, order) => {
                const previous = prev ? prev : {} as any;

                const date = new Date(order.creationDate);
                const ano = date.getFullYear().toString();
                const mes = date.getMonth().toString();

                const attrAtual = `${ano}_${mes}`;

                const pedidosAntes = previous[attrAtual] ? (previous[attrAtual] ?? []) : [];

                return {
                    ...prev,
                    [attrAtual]: [...pedidosAntes, order],
                };
            }, {})

            setAgroupedOrders(agrupados);
        }

        groupYears();
    }, [historyOrders])

    const GroupedOrders: FC = () => {
        return (
            <>
                {!!Object.keys(agroupedOrders).length
                    ? Object.keys(agroupedOrders).map(yearMonth => {
                        const mesAno = formatText(yearMonth)

                        return (
                            <div key={yearMonth}>
                                <Typography className={styles.titleSection}>
                                    {mesAno}
                                </Typography>

                                {/* @ts-ignore */}
                                {agroupedOrders[yearMonth].map((order: Order) => (
                                    <ItemLastBuy mes={mesAno} key={order.id} data={order} onClick={() => handleClickLastBuy(order)} />
                                ))}
                            </div>
                        )
                    })
                    : (<p></p>)
                }
            </>
        )
    }

    return (
        <section className={styles.container}>
            <Typography component="h1" variant="h4" color="primary">Lista de pedidos</Typography>

            <LazyView operation={customerOperation}
                failureSection={() => <Typography>Parece que ainda não temos compras!! Visite nossos produtos para preencher este espaço vazio.</Typography>}
                loadingSection={() => <LoadingSectionView operation={customerOperation} />}
            >
                <GroupedOrders />
            </LazyView>

            <DetailsPurchase
                modalDetailsPurchase={modalDetailsPurchase}
                setModalDetailsPurchase={setModalDetailsPurchase}
                order={orderToModal}
                cartState={cartState}
            />
        </section>
    )
}


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            maxWidth: 1020,
            margin: "20px auto",
        },
        titleSection: {
            textAlign: 'center',
            background: theme.palette.grey[100],
            padding: '10px 0',
            fontWeight: 'bold',
        },
    })
);


export default HistoryBuy;