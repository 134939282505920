import React, {FC, useState} from "react";
import {Button, createStyles, makeStyles, Typography} from "@material-ui/core";
import {valueFromProps} from "../../../util/ValueFromProps";
import {useWindowSize} from "../../../hooks/WindowSize";
import imageSlogan from "../../../assets/images/echope-slogan.jpg";
import MaskedTextField from "../../../components/field/MaskedTextField";
import {MaskPostalCode} from "../../../util/Masks";
import {Field} from "@echope/echope-store-core/dist/store/fields";
import {RequiredStringValidator} from "@echope/echope-store-core/dist/store/fields/validators";
import SectionHeader from "../../../components/header";
import {getLocationByPostalCode} from "../../../components/location";
import {Dispatch} from "redux";
import {
    getLocationFormattedAddress,
    getLocationOperation,
    getUserLocation,
    sendUpdateLocationOperation,
    sendUpdateUserLocationByAddress
} from "../../../store/location";
import {Operation} from "@echope/echope-store-core/dist/util";
import {useDispatch, useSelector} from "react-redux";
import {sendUpdatePreferencesUserLocation} from "../../../store/preferences";
import { useHistory } from "react-router";

const useStyles = makeStyles(theme => createStyles({
    container: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        marginLeft: 5,
        marginRight: 5,
        alignItems: "center",
        flex: 2,
        backgroundColor: "#efefef",
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
        }
    },
    content: {
        display: "flex",
        flexDirection: "column",
        padding: 12,
        paddingBottom: 2,
        justifyContent: "center",
        flexBasis: "auto",
        flexShrink: 0,
        [theme.breakpoints.down("sm")]: {
            padding: 3,
        }
    },
    form: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        flexBasis: "auto",
        flexShrink: 0,
    },
    foundBox: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        flexBasis: "auto",
        flexShrink: 0,
    },
    button: {
        [theme.breakpoints.down("sm")]: {
            flex: 1,
            '&:first-child': {
                marginRight: 10
            }
        }
    },
    sloganImage: {
        height: props => valueFromProps(props, "vw", 1440) * 0.33 * 0.5625,
        width: "auto",
        [theme.breakpoints.down("sm")]: {
            display: "none"
        }
    }
}));

const searchForLocation = (dispatch: Dispatch) => (postalCode: string) => {
    getLocationByPostalCode(postalCode).then(
        response => {
            dispatch(sendUpdateUserLocationByAddress(response));
            dispatch(sendUpdateLocationOperation(Operation.success()));
        },
        error => dispatch(sendUpdateLocationOperation(Operation.failed(error)))
    );
}

const SearchBox: FC = () => {
    const dispatch = useDispatch();
    const windowSize = useWindowSize();
    const styles = useStyles({vw: windowSize.width});
    const [postalCodeField, setPostalCodeField] = useState<Field<string>>(new Field(
        "",
        "",
        new RequiredStringValidator("teste",)
    ));

    const operation = useSelector(getLocationOperation);

    const onPostalCodeChanged = (value: string) => setPostalCodeField(postalCodeField.updateValue(value));

    return (
        <div className={styles.content}>
            <SectionHeader title="Informe seu CEP para encontramos a loja mais próxima:"
                           decorated={false}
                           variant="h6"
                           smFontSize={14}/>

            <div className={styles.form}>
                <MaskedTextField id="postalCodeView"
                                 field={postalCodeField}
                                 validate={true}
                                 containerStyle={{marginTop: 5, marginBottom: 5}}
                                 label={"CEP"}
                                 onFocus={(event) => {
                                     const target = event.target;
                                     setTimeout(() => target.select(), 0);
                                 }}
                                 onValueChanged={(value, maskedValue) => onPostalCodeChanged(value)}
                                 retrieveMaskedValue={false}
                                 maskComponent={MaskPostalCode as any}/>

                <Button variant="contained"
                        color="primary"
                        onClick={() => searchForLocation(dispatch)(postalCodeField.value)}>
                    Buscar
                </Button>

                {
                    operation.isFailed() &&
                    <Typography variant="body1">{operation.message}</Typography>
                }
            </div>
        </div>
    );
}

const FoundBox: FC = () => {
    const windowSize = useWindowSize();
    const styles = useStyles({vw: windowSize.width});

    const dispatch = useDispatch();
    const formattedAddress = useSelector(getLocationFormattedAddress);
    const userLocation = useSelector(getUserLocation);
    const history = useHistory();

    const findNearestStore = () => dispatch(sendUpdatePreferencesUserLocation(userLocation));

    return (
        <div className={styles.content}>
            <SectionHeader title="Encontramos esse endereço:"
                           decorated={false}
                           variant="h6"
                           smFontSize={14}/>

            <Typography variant="body2"
                        style={{ marginTop: 10, marginBottom: 10}}>
                {formattedAddress}
            </Typography>

            <div className={styles.foundBox}>
                <Button variant="contained"
                        color="primary"
                        className={styles.button}
                        onClick={() => findNearestStore()}>
                    Esta correto!
                </Button>

                <Button variant="contained"
                        color="secondary"
                        className={styles.button}
                        onClick={() => history.push('/stores')}>
                    Não, mostre a lista de lojas
                </Button>
            </div>
        </div>
    );
}

const PostalCodeLocation: FC = () => {
    const windowSize = useWindowSize();
    const styles = useStyles({vw: windowSize.width});

    const location = useSelector(getUserLocation);

    return (
        <div className={styles.container}>
            <img src={imageSlogan} className={styles.sloganImage} alt="echope é mais que chope"/>

            {
                location != null ? <FoundBox /> : <SearchBox />
            }
        </div>
    );
}

export default PostalCodeLocation;