import {FC, useEffect, useRef} from "react";
import CompanyLocation from "./CompanyLocation";
import {useMap} from "react-leaflet";
import {LatLngTuple, Point} from "leaflet";
import {useSelector} from "react-redux";
import {getPreferenceStores, StoreWithDistance} from "../../../store/preferences";

export interface CompaniesLocationProps {
    onSelectStore: (store: StoreWithDistance) => void;
}

interface Padding {
    top: number;
    left: number;
    right: number;
    bottom: number;
}

const getPadding = (tuples: LatLngTuple[]): any => {
    return tuples.map<Padding>(t => ({
        left: t[0],
        right: t[0],
        top: t[1],
        bottom: t[1]
    })).reduce((previousValue, currentValue) => ({
        top: Math.min(previousValue.top, currentValue.top),
        bottom: Math.min(previousValue.bottom, currentValue.bottom),
        left: Math.min(previousValue.left, currentValue.left),
        right: Math.min(previousValue.right, currentValue.right)
    }), {
        left: tuples[0][0],
        right: tuples[0][0],
        top: tuples[0][1],
        bottom: tuples[0][1]
    });
}

const CompaniesLocation: FC<CompaniesLocationProps> = ({onSelectStore}) => {
    const started = useRef(false);
    const map = useMap();

    const stores = useSelector(getPreferenceStores);

    useEffect(() => {
        if (stores == null || stores.length === 0) {
            return;
        }

        if (started.current) {
            return;
        }

        const bounds: LatLngTuple[] = stores.map(s => {
            const {latitude, longitude} = s.store!.coords;
            return [latitude, longitude];
        });

        const padding = getPadding(bounds);

        map.fitBounds(bounds, {
            paddingTopLeft: new Point(padding.left, padding.top),
            paddingBottomRight: new Point(padding.right, padding.bottom)
        })

        started.current = true;
    })

    return (
        <>
            {
                stores.map(store => {
                    const {id} = store.store!;

                    return (
                        <CompanyLocation key={id}
                                         store={store}
                                         onSelect={(store) => onSelectStore(store)}/>
                    );
                })
            }
        </>
    )
}

export default CompaniesLocation;