import { Box, Button, Theme, Tooltip, Typography } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { createStyles, makeStyles } from "@material-ui/styles";
import sub from 'date-fns/sub';
import { isLoggedInSelector } from "@echope/echope-store-core/dist/store/auth";
import { useFormik } from "formik";
import React, { FC, useState } from "react";
import { useSelector } from "react-redux";
import { getAddressByPostalCode } from "../../components/location";
import useUserRegistrationData from "../../hooks/UseUserRegistrationData";
import User, { AddressRequest, UserRequest } from "../../services/User";
import { isValidEmail } from "../../util/Asserts";
import { cleanFormatCpf, isValidCpf } from "../../util/Document";
import { formatServerDate, unMask } from "../../util/formatter";
import { MaskCelphone, MaskDocument, MaskDocumentRg, MaskPhone, MaskPostalCode } from "../../util/Masks";
import InputDate from "./components/InputDate";
import InputForm from "./components/InputForm";

interface AddressForm extends AddressRequest {

}

export interface FormValues extends UserRequest {
    birthDateForm: Date | null;
    birthDateError?: string;
    accessKeyRepeat: string;
    accessPassRepeat: string;
    addressesForm: AddressForm;
}

const RegistrationScreen: FC = () => {
    const styles = useStyles();
    const isLogged = useSelector(isLoggedInSelector)
    const userData = useUserRegistrationData(isLogged);

    const [isLoadAddress, setIsLoadAddress] = useState(false);
    const [isLoadSave, setIsLoadSave] = useState(false);
    const [saved, setSaved] = useState({ isOk: false, isError: false, message: '' });

    const ageRuleDate = sub(new Date(), { years: 18 });

    const handleSubmit = async (data: FormValues) => {
        if (isLogged) return;
        try {
            setIsLoadSave(true)
            const response = await User().saveUser({
                name: data.name,
                cpfCnpj: data.cpfCnpj,
                rgInscricao: data.rgInscricao,
                celphone: data.celphone,
                phone: data.phone,
                accessKey: data.accessKey,
                accessPass: data.accessPass,
                birthDate: data.birthDateForm ? formatServerDate(data.birthDateForm) : '',
                addresses: [{ ...data.addressesForm }],
            })

            setSaved({ isOk: true, isError: false, message: response.data });
        } catch (error) {
            //@ts-ignore
            setSaved({ isOk: false, isError: true, message: error.message });
        } finally {
            setIsLoadSave(false)
        }
    };

    const formik = useFormik<FormValues>({
        initialValues: {
            name: userData.name || '',
            cpfCnpj: userData.cpfCnpj || '',
            rgInscricao: userData.rgInscricao || '',
            birthDate: userData.birthDate || '',
            birthDateForm: userData.birthDateForm || null,
            celphone: userData.celphone || '',
            phone: userData.phone || '',
            accessKey: userData.accessKey || '',
            accessPass: userData.accessPass || '',
            accessKeyRepeat: '',
            accessPassRepeat: '',
            addressesForm: {
                id: 0,
                postalCode: userData.addressesForm.postalCode || '',
                street: userData.addressesForm.street || '',
                streetNumber: userData.addressesForm.streetNumber || '',
                complement: userData.addressesForm.complement || '',
                neighborhood: userData.addressesForm.neighborhood || '',
                locality: userData.addressesForm.locality || '',
                state: userData.addressesForm.state || '',
            },
            addresses: []
        },
        enableReinitialize: true,
        onSubmit: handleSubmit,
        validateOnBlur: true,
        validateOnChange: false,

        validate: (values) => {
            const errors = {} as FormValues;

            if (!values.name)
                errors.name = 'Nome é obrigatório'
            else if (RegExp(/\d/g).test(values.name))
                errors.name = 'Não é permitido número no nome';

            if (!values.cpfCnpj)
                errors.cpfCnpj = 'CPF é obrigatório'
            else if (values.cpfCnpj.includes('_'))
                errors.cpfCnpj = 'CPF está incompleto'
            else if (!isValidCpf(cleanFormatCpf(values.cpfCnpj)))
                errors.cpfCnpj = 'CPF inválido';

            if (values.rgInscricao && values.rgInscricao.includes('_'))
                errors.rgInscricao = 'RG está incompleto'

            if (values.celphone && values.celphone.includes('_'))
                errors.celphone = 'Número de celular está incompleto'

            if (values.phone && values.phone.includes('_'))
                errors.phone = 'Número de telefone está incompleto'

            if (!values.birthDateForm)
                errors.birthDateError = 'Data de nascimento é obrigatório'
            else if (ageRuleDate < values.birthDateForm)
                errors.birthDateError = `Não é permitido cadastrar usuário com idade abaixo de 18 anos`;

            if (!(values.addressesForm.postalCode || values.addressesForm.street || values.addressesForm.streetNumber ||
                values.addressesForm.neighborhood || values.addressesForm.state || values.addressesForm.locality)) {
                errors.addressesForm = {} as AddressForm
            }
            if (!values.addressesForm.postalCode) errors.addressesForm.postalCode = 'CEP é obrigatório';
            if (!values.addressesForm.street) errors.addressesForm.street = 'Rua é obrigatório';
            if (!values.addressesForm.streetNumber) errors.addressesForm.streetNumber = 'Número é obrigatório';
            if (!values.addressesForm.neighborhood) errors.addressesForm.neighborhood = 'Bairro é obrigatório';
            if (!values.addressesForm.state) errors.addressesForm.state = 'Estado é obrigatório';
            if (!values.addressesForm.locality) errors.addressesForm.locality = 'Cidade é obrigatório';

            if (!values.accessKey)
                errors.accessKey = 'E-mail é obrigatório'
            else if (!isValidEmail(values.accessKey))
                errors.accessKey = 'E-mail inválido'
            else if (!values.accessKeyRepeat)
                errors.accessKeyRepeat = 'Confirmação de E-mail é obrigatório'
            else if (values.accessKey !== values.accessKeyRepeat)
                errors.accessKeyRepeat = 'E-mail está diferente'

            if (!values.accessPass)
                errors.accessPass = 'Senha é obrigatório'
            else if (!values.accessPassRepeat)
                errors.accessPassRepeat = 'Confirmação da senha é obrigatória'
            else if (values.accessPass !== values.accessPassRepeat)
                errors.accessPassRepeat = 'Senha está diferente'

            if (Object.keys(errors).length !== 0) {
                setSaved({ isOk: false, isError: true, message: "Por favor, revise os campos novamente." });
            }

            return errors;
        }
    })

    const handleFetchCEP = async () => {
        const postalCode = formik.values.addressesForm.postalCode;

        if (postalCode.length < 9) {
            formik.setFieldError('addressesForm.postalCode', 'Necessário digitar o CEP para pesquisar');
            return;
        }

        try {
            setIsLoadAddress(true)
            const addressLoad = await getAddressByPostalCode(unMask(postalCode));
            formik.setFieldValue('addressesForm.street', addressLoad.street)
            formik.setFieldValue('addressesForm.neighborhood', addressLoad.neighborhood)
            formik.setFieldValue('addressesForm.locality', addressLoad.city)
            formik.setFieldValue('addressesForm.state', addressLoad.state)

        } catch (error) {
            formik.setFieldError('addressesForm.postalCode', 'Ocorreu um erro ao buscar o cep');
        } finally {
            setIsLoadAddress(false)
        }
    }

    return (
        <section className={styles.root}>
            <Typography component="h1" variant="h4" color="primary">
                {isLogged ? 'Consulta de Dados cadastrais' : 'Cadastro'}
            </Typography>

            {!isLogged && (
                <Typography variant="body2" color="primary">
                    Campos com * são obrigatórios.
                </Typography>
            )}

            <form onSubmit={formik.handleSubmit} className={styles.form}>
                <Box className={styles.formSection} component="fieldset">
                    <Typography component="legend" variant="h6" color="primary">
                        Dados pessoais
                    </Typography>

                    <InputForm
                        autoFocus
                        name="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        label="Nome Completo *"
                        placeholder="Digite o seu nome completo"
                        error={!!formik.errors.name}
                        helperText={formik.errors.name}
                        InputProps={{
                            readOnly: isLogged
                        }}
                    />

                    <div className={styles.formRow}>
                        <InputForm
                            name="cpfCnpj"
                            value={formik.values.cpfCnpj}
                            onChange={formik.handleChange}
                            label="CPF *"
                            placeholder="Digite o seu CPF"
                            InputProps={{
                                readOnly: isLogged,
                                inputComponent: MaskDocument as any,
                            }}
                            error={!!formik.errors.cpfCnpj}
                            helperText={formik.errors.cpfCnpj}
                        />
                        <InputForm
                            name="rgInscricao"
                            value={formik.values.rgInscricao}
                            onChange={formik.handleChange}
                            label="RG"
                            placeholder="Digite o seu RG"
                            InputProps={{
                                readOnly: isLogged,
                                inputComponent: MaskDocumentRg as any
                            }}
                            error={!!formik.errors.rgInscricao}
                            helperText={formik.errors.rgInscricao}
                        />
                    </div>

                    <div className={styles.formRow}>
                        <InputDate
                            label="Data de nascimento *"
                            value={formik.values.birthDateForm}
                            name="birthDateForm"
                            onChange={value => formik.setFieldValue("birthDateForm", value)}
                            maxDate={ageRuleDate}
                            error={!!formik.errors.birthDateError}
                            helperText={formik.errors.birthDateError}
                            InputProps={{
                                readOnly: isLogged
                            }}
                        />

                        <InputForm
                            name="celphone"
                            value={formik.values.celphone}
                            onChange={formik.handleChange}
                            label="Celular"
                            placeholder="Digite o seu celular de contato"
                            InputProps={{
                                readOnly: isLogged,
                                inputComponent: MaskCelphone as any
                            }}
                            error={!!formik.errors.celphone}
                            helperText={formik.errors.celphone}
                        />

                        <InputForm
                            name="phone"
                            value={formik.values.phone}
                            onChange={formik.handleChange}
                            label="Telefone"
                            placeholder="Digite o seu telefone de contato"
                            InputProps={{
                                readOnly: isLogged,
                                inputComponent: MaskPhone as any
                            }}
                            error={!!formik.errors.phone}
                            helperText={formik.errors.phone}
                        />
                    </div>
                </Box>

                <Box component="fieldset" className={styles.formSection}>
                    <Typography component="legend" variant="h6" color="primary">
                        Endereço de cobrança
                    </Typography>

                    <div className={styles.formRow}>
                        <InputForm
                            name="addressesForm.postalCode"
                            value={formik.values.addressesForm.postalCode}
                            onChange={formik.handleChange}
                            label="CEP *"
                            placeholder="Digite o seu CEP"
                            InputProps={{
                                readOnly: isLogged,
                                inputComponent: MaskPostalCode as any
                            }}
                            error={!!formik.errors.addressesForm?.postalCode}
                            helperText={formik.errors.addressesForm?.postalCode}
                        />
                        {!isLogged && (
                            <Tooltip title="Buscar pelo CEP">
                                <Button onClick={handleFetchCEP} variant="contained" color="primary">
                                    <Search fontSize="medium" />
                                </Button>
                            </Tooltip>
                        )}
                    </div>

                    <div className={styles.formRow}>
                        <InputForm
                            name="addressesForm.street"
                            value={formik.values.addressesForm.street}
                            onChange={formik.handleChange}
                            label="Rua *"
                            placeholder="Digite o nome da Rua"
                            disabled={isLoadAddress}
                            isLoading={isLoadAddress}
                            error={!!formik.errors.addressesForm?.street}
                            helperText={formik.errors.addressesForm?.street}
                            InputProps={{
                                readOnly: isLogged
                            }}
                        />
                        <InputForm
                            name="addressesForm.streetNumber"
                            value={formik.values.addressesForm.streetNumber}
                            onChange={formik.handleChange}
                            label="Número *"
                            placeholder="Digite o número do seu endereço"
                            error={!!formik.errors.addressesForm?.streetNumber}
                            helperText={formik.errors.addressesForm?.streetNumber}
                            InputProps={{
                                readOnly: isLogged
                            }}
                        />
                    </div>

                    <InputForm
                        name="addressesForm.complement"
                        value={formik.values.addressesForm.complement}
                        onChange={formik.handleChange}
                        label="Complemento"
                        placeholder="Digite o complemento do endereço"
                        InputProps={{
                            readOnly: isLogged
                        }}
                    />

                    <InputForm
                        name="addressesForm.neighborhood"
                        value={formik.values.addressesForm.neighborhood}
                        onChange={formik.handleChange}
                        label="Bairro *"
                        placeholder="Digite o nome do Bairro"
                        disabled={isLoadAddress}
                        isLoading={isLoadAddress}
                        error={!!formik.errors.addressesForm?.neighborhood}
                        helperText={formik.errors.addressesForm?.neighborhood}
                        InputProps={{
                            readOnly: isLogged
                        }}
                    />

                    <div className={styles.formRow}>
                        <InputForm
                            name="addressesForm.state"
                            value={formik.values.addressesForm.state}
                            onChange={formik.handleChange}
                            label="Estado *"
                            placeholder="Digite o seu Estado"
                            disabled={isLoadAddress}
                            isLoading={isLoadAddress}
                            error={!!formik.errors.addressesForm?.state}
                            helperText={formik.errors.addressesForm?.state}
                            InputProps={{
                                readOnly: isLogged
                            }}
                        />
                        <InputForm
                            name="addressesForm.locality"
                            value={formik.values.addressesForm.locality}
                            onChange={formik.handleChange}
                            label="Cidade *"
                            placeholder="Digite a sua Cidade"
                            disabled={isLoadAddress}
                            isLoading={isLoadAddress}
                            error={!!formik.errors.addressesForm?.locality}
                            helperText={formik.errors.addressesForm?.locality}
                            InputProps={{
                                readOnly: isLogged
                            }}
                        />
                    </div>
                </Box>

                <Box component="fieldset" className={styles.formSection}>
                    <Typography component="legend" variant="h6" color="primary">
                        Credenciais para Login
                    </Typography>

                    <div className={styles.formRow}>
                        <InputForm
                            name="accessKey"
                            value={formik.values.accessKey}
                            onChange={formik.handleChange}
                            label="E-mail *"
                            placeholder="Digite o seu e-mail"
                            error={!!formik.errors.accessKey}
                            helperText={formik.errors.accessKey}
                            InputProps={{
                                readOnly: isLogged
                            }}
                        />
                        {!isLogged && (
                            <InputForm
                                name="accessKeyRepeat"
                                value={formik.values.accessKeyRepeat}
                                onChange={formik.handleChange}
                                label="Confirme seu E-mail *"
                                placeholder="Repita o seu e-mail"
                                onPaste={(e) => e.preventDefault()}
                                error={!!formik.errors.accessKeyRepeat}
                                helperText={formik.errors.accessKeyRepeat}
                            />
                        )}
                    </div>

                    {!isLogged && (
                        <div className={styles.formRow}>
                            <InputForm
                                name="accessPass"
                                value={formik.values.accessPass}
                                onChange={formik.handleChange}
                                type="password"
                                label="Senha *"
                                inputProps={{
                                    minLength: 6,
                                    maxLength: 36,
                                }}
                                placeholder="Digite uma senha de 6 a 36 caracteres"
                                error={!!formik.errors.accessPass}
                                helperText={formik.errors.accessPass}
                            />
                            <InputForm
                                name="accessPassRepeat"
                                value={formik.values.accessPassRepeat}
                                onChange={formik.handleChange}
                                type="password"
                                label="Confirme sua Senha *"
                                inputProps={{
                                    minLength: 6,
                                    maxLength: 36,
                                }}
                                placeholder="Repita sua senha"
                                onPaste={(e) => e.preventDefault()}
                                error={!!formik.errors.accessPassRepeat}
                                helperText={formik.errors.accessPassRepeat}
                            />
                        </div>
                    )}
                </Box>

                {!!saved.message && (
                    <Typography variant="body1" color={saved.isError ? 'error' : 'primary'} align="center" style={{ marginBottom: 10 }}>
                        {saved.message}
                    </Typography>
                )}

                {!isLogged && !saved.isOk && (
                    <Button disabled={isLoadSave} variant="contained" type="submit" color="primary" fullWidth>
                        Confirmar
                    </Button>
                )}
            </form>
        </section>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            maxWidth: 900,
            margin: "20px auto",
        },
        form: {
            margin: "20px 0",
        },
        formSection: {
            marginBottom: 20,
            display: "flex",
            flexFlow: "column nowrap",
            gap: 20,
            padding: 10,
            border: `1px solid ${theme.palette.grey[300]}`,
            "& legend": {
                padding: "0 5px",
            },
        },
        formRow: {
            display: "flex",
            gap: 20,
        },
        label: {
            background: theme.palette.background.default,
            padding: "0 5px",
        },
    })
);

export default RegistrationScreen;
