import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { sendCleanCart } from "@echope/echope-store-core/dist/store/cart";
import { sendCleanStore } from "@echope/echope-store-core/dist/store/store";
import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SectionHeader from "../../components/header";
import FailedSectionView from "../../components/lazy/FailedSectionView";
import LazyView from "../../components/lazy/LazyView";
import LoadingSectionView from "../../components/lazy/LoadingSectionView";
import { useStoreWithDistance } from "../../hooks/UseStoresWithDistance";
import { getPreferenceOperation, sendCleanPreferences } from "../../store/preferences";
import StoreView from "./views/StoreView";


const useStyles = makeStyles((theme: Theme) => createStyles({
    container: {
        marginLeft: "2%",
        width: "96%",
        display: "flex",
        flexDirection: "column",
        marginBottom: 30,
        [theme.breakpoints.down("sm")]: {
            marginLeft: "1%",
            width: "98%",
        }
    },
    storesContainer: {
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "row",
        justifyContent: "flex-start",
        [theme.breakpoints.down("sm")]: {
            justifyContent: "center",
        }
    }
}));

const StoreListScreen: FC = () => {
    const stores = useStoreWithDistance();
    const operation = useSelector(getPreferenceOperation);
    const styles = useStyles();

    const dispatch = useDispatch();

    const storesView = stores.map(store => <StoreView key={store.store?.id} item={store} />);

    return (
        <LazyView operation={operation}
            failureSection={() => <FailedSectionView operation={operation} />}
            loadingSection={() => <LoadingSectionView operation={operation} />}>
            <div className={styles.container}>
                <SectionHeader variant="h4" title="Nossas Lojas" />

                <div className={styles.storesContainer}>
                    {storesView}
                </div>
            </div>
        </LazyView>
    );
}

export default StoreListScreen;