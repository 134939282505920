import { newRemoteService } from "@echope/echope-store-core/dist/infrastructure/api";
import { BaseRepository } from "@echope/echope-store-core/dist/infrastructure/repository/base-repository";
import { AddressResponse } from "./User";

export interface ShippingPrice {
    id: number;
    name: string;
    description: null;
    fullPrice: number;
    price: number;
    units: number;
    // datePromotion: null;
    // type: null;
    // liters: 0;
    // imgWideUrl: null;
    // imgSquareUrl: null;
    // brandId: 0;
    // brand: null;
    // group: null;
    // isChopp: false;
    // hasColdOption: false;
    // quantityMinDeliveryTaxZero: 0;
}

export interface ShipingResponse<T> {
    timestamp: number;
    data: T;
}

class ShippingRepositoryImpl extends BaseRepository {
    getValidAddress2Delivery(storeId: number, cep: string) {
        return newRemoteService().get<ShipingResponse<AddressResponse>>({
            authenticated: false,
            endpoint: this.composeURL(`Stores/${storeId}/shipping/${cep}`),
        });
    }

    getDeliveryPrice(storeId: number) {
        return newRemoteService().get<ShipingResponse<ShippingPrice>>({
            authenticated: false,
            endpoint: this.composeURL(`Stores/${storeId}/shipping`),
        });
    }
}

const shippingImpl = () => new ShippingRepositoryImpl();

export default shippingImpl;
