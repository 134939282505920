import { CatalogItem, Group } from "@echope/echope-store-core/dist/models";
import {
    getCatalogItemsSelector,
    getGroupsSelector,
} from "@echope/echope-store-core/dist/store/catalog";
import { getSelectedBrand } from "@echope/echope-store-core/dist/store/view/catalog";
import { isNonNullOrUndefined } from "@echope/echope-store-core/dist/util/assertions";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const useFilteredGroups = () => {
    const brand = useSelector(getSelectedBrand);
    const [filtered, setFiltered] = useState(false);
    const [groups, setGroups] = useState<Group[]>([]);
    const items = useSelector(getCatalogItemsSelector) as CatalogItem[];
    const allGroups = useSelector(getGroupsSelector) as Group[];

    const removeDuplicates = (groups: Group[]) =>
        groups.reduce((grps, currGrp) => {
            if (grps.length === 0) return [currGrp];

            if (grps.some((g) => g.id === currGrp.id)) return grps;
            else return [...grps, currGrp];
        }, [] as Group[]);

    useEffect(() => {
        const filteredBrands = items
            .filter((it) => (it.brand?.id || -1) === (brand?.id || -2))
            .filter((it) => isNonNullOrUndefined(it.group))
            .map((it) => it.group) as Group[];

        setFiltered(isNonNullOrUndefined(brand));
        setGroups(
            isNonNullOrUndefined(brand)
                ? removeDuplicates(filteredBrands)
                : allGroups
        );
    }, [allGroups, brand, items]);

    return [groups, filtered] as const;
};
