import {LatLng} from "leaflet";
import {Operation} from "@echope/echope-store-core/dist/util";
import { Action } from "redux";

export class LocationState {
    readonly userLocation: LatLng | undefined;
    readonly userGrantedLocation: boolean;
    readonly operation: Operation;
    readonly formattedAddress: string;

    private constructor(
        userLocation: LatLng | undefined,
        userGrantedLocation: boolean,
        operation: Operation,
        formattedAddress: string
    ) {
        this.userLocation = userLocation;
        this.userGrantedLocation = userGrantedLocation;
        this.operation = operation;
        this.formattedAddress = formattedAddress;
    }

    public updateUserLocation(location: LatLng): LocationState {
        return new LocationState(location, this.userGrantedLocation, this.operation, this.formattedAddress);
    }

    public updateUserGrantedLocation(granted: boolean): LocationState {
        return new LocationState(this.userLocation, granted, this.operation, this.formattedAddress);
    }

    public updateOperation(operation: Operation): LocationState {
        return new LocationState(this.userLocation, this.userGrantedLocation, operation, this.formattedAddress);
    }

    public updateFormattedAddress(address: string): LocationState {
        return new LocationState(this.userLocation, this.userGrantedLocation, this.operation, address);
    }

    public clear(): LocationState {
        return LocationState.empty();
    }

    public static empty(): LocationState {
        return new LocationState(undefined, false, Operation.started(), "");
    }

    public static valueOf(state: any): LocationState {
        const { userLocation, userGrantedLocation, operation, formattedAddress} = state;

        return new LocationState(
            userLocation as LatLng,
            userGrantedLocation as boolean,
            new Operation(operation.type, operation.message, operation.error),
            formattedAddress as string
        );
    }
}

export const UPDATE_USER_LOCATION_MESSAGE = 'UPDATE_USER_LOCATION_MESSAGE';
export const UPDATE_USER_LOCATION_BY_POSTAL_CODE_MESSAGE = 'UPDATE_USER_LOCATION_BY_POSTAL_CODE_MESSAGE';
export const UPDATE_USER_GRANTED_LOCATION_MESSAGE = 'UPDATE_USER_GRANTED_LOCATION_MESSAGE';
export const UPDATE_LOCATION_OPERATION_MESSAGE = 'UPDATE_LOCATION_OPERATION_MESSAGE';
export const CLEAR_LOCATION_MESSAGE = 'CLEAR_LOCATION_MESSAGE';

export interface UpdateUserLocationAction extends Action<typeof UPDATE_USER_LOCATION_MESSAGE>{
    location: LatLng;
}

export interface UpdateUserGrantedLocationAction extends Action<typeof UPDATE_USER_GRANTED_LOCATION_MESSAGE>{
    granted: boolean;
}

export interface UpdateLocationOperationAction extends Action<typeof UPDATE_LOCATION_OPERATION_MESSAGE>{
    operation: Operation;
}

export interface UpdateUserLocationByPostalCodeAction extends Action<typeof UPDATE_USER_LOCATION_BY_POSTAL_CODE_MESSAGE>{
    location: LatLng;
    address: string;
}

export interface ClearLocationAction extends Action<typeof CLEAR_LOCATION_MESSAGE>{

}