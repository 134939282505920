import React from "react";

interface VoucherGoalProps {
    size: number;
    backgroundColor: string;
    fillColor: string;
    strokeWidth: number;
    actionColor: string;
    actionText: string;
    current: number;
    goal: number;
}

const VoucherGoal: React.FC<VoucherGoalProps> = (props) => {
    const {
        size,
        backgroundColor,
        fillColor,
        strokeWidth,
        actionColor,
        actionText,
    } = props;
    const reachedGoal = value(props) >= 1;
    const currentColor = reachedGoal ? actionColor : fillColor;

    return (
        <svg width={size} height={size}>
            <path
                d={fullCircleArc(props)}
                fill="none"
                stroke={backgroundColor}
                strokeWidth={strokeWidth}
            />

            <path
                d={circularArc(props)}
                fill="none"
                stroke={currentColor}
                strokeWidth={strokeWidth}
            />

            {reachedGoal ? (
                <text
                    fill={currentColor}
                    stroke="none"
                    fontWeight="bold"
                    fontSize={12}
                    textAnchor="middle"
                    x="50%"
                    y="50%"
                    dy=".3em"
                >
                    {actionText}
                </text>
            ) : (
                <text
                    fill={currentColor}
                    stroke="none"
                    fontWeight="bold"
                    fontSize={13}
                    textAnchor="middle"
                    x="50%"
                    y="50%"
                    dy=".3em"
                >
                    {`${Math.trunc(value(props) * 100)}%`}
                </text>
            )}
        </svg>
    );
};

const center = ({ size }: VoucherGoalProps) => size / 2;
const sideOffset = ({ strokeWidth }: VoucherGoalProps) => strokeWidth / 2 + 1;
const radius = (props: VoucherGoalProps) => center(props) - sideOffset(props);

const fullCircleArc = (props: VoucherGoalProps) => {
    const { size } = props;
    const c = center(props);
    const s = sideOffset(props);
    const r = radius(props);

    return describePathData(
        describePathStart(c, s),
        describePathCircularArc(r, 1, c, size - s),
        describePathCircularArc(r, 1, c, s)
    );
};

const circularArc = (props: VoucherGoalProps) => {
    return value(props) >= 1 ? fullCircleArc(props) : partialCircleArc(props);
};

const partialCircleArc = (props: VoucherGoalProps) => {
    const v = value(props);
    const r = radius(props);
    const c = center(props);
    const s = sideOffset(props);
    const largeArcFlag = v >= 0.5 ? 1 : 0;

    const angle = v * 2 * Math.PI;
    const endX = Math.sin(angle) * r + c;
    const endY = -Math.cos(angle) * r + c;

    return describePathData(
        describePathStart(c, s),
        describePathCircularArc(r, largeArcFlag, endX, endY)
    );
};

const value = (props: VoucherGoalProps) => {
    const { current, goal } = props;

    if (goal === 0.0) {
        return current > 0 ? 1 : 0;
    }

    return current / goal;
};

//@ts-ignore
const describePathData = (...parts) => parts.join(" ");
const describePathStart = (x: number, y: number) => `M${x},${y}`;
const describePathCircularArc = (
    radius: number,
    largeArcFlag: number,
    endX: number,
    endY: number
) => {
    const xAxisRotation = 0;
    const sweepFlag = 1;
    return `A${radius},${radius} ${xAxisRotation} ${largeArcFlag} ${sweepFlag} ${endX},${endY}`;
};

export default VoucherGoal;
