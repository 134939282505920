import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {isNullOrUndefined} from "../util/Asserts";
import {getStores, sendLoadStores, sendUpdateStoreOperation} from "@echope/echope-store-core/dist/store/store";
import {Operation} from "@echope/echope-store-core/dist/util";

export const useStores = () => {
    const stores = useSelector(getStores) || [];
    const dispatch = useDispatch();

    useEffect(() => {
        if (isNullOrUndefined(stores) || stores.length === 0){
            // dispatch(sendUpdateStoreOperation(Operation.processing("Aguarde enquanto carregamos as lojas")))
            dispatch(sendLoadStores());
        }
    }, [stores]);

    return stores;
}