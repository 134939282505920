import {Group} from "@echope/echope-store-core/dist/models";
import {FC} from "react";
import {createStyles, IconButton, ListItem, ListItemText} from "@material-ui/core";
import {makeStyles, Theme} from "@material-ui/core/styles";
import {useDispatch, useSelector} from "react-redux";
import {getSelectedGroup, selectBrand, selectGroup} from "@echope/echope-store-core/dist/store/view/catalog";
import {valueFromProps} from "../../../util/ValueFromProps";
import {isNullOrUndefined} from "../../../util/Asserts";
import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles((theme: Theme) => createStyles({
    listItem: {
        flexGrow: 0,
        border: `solid 1px #d0d0d0`,
        borderRadius: 6,
        backgroundColor: (props) => valueFromProps(props, "selected", false) ?
            theme.palette.primary.main :
            theme.palette.background.paper,
        color: (props) => valueFromProps(props, "selected", false) ?
            theme.palette.background.paper :
            theme.palette.text.primary,
        padding: 0,
        fontSize: 12,
        cursor: "pointer",
        '& > *': {
            cursor: "pointer",
        },
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.background.paper,
        },
        width: "12%",
        margin: "0.25%",
        [theme.breakpoints.down("md")]: {
            width: "19.5%",
            margin: "0.25%",
        },
        [theme.breakpoints.down("sm")]: {
            width: "32%",
            margin: "0.5%",
        },
        [theme.breakpoints.down("xs")]: {
            width: "50%",
            margin: 0
        }
    },
    listItemIcon: {
        width: "21%",
        borderRadius: "50%",
        height: "auto",
        margin: 3,
        marginRight: 10
    }
}));

export interface GroupViewProps {
    group: Group
}

const name = (value: string): string =>
    value.split(" ")
        .map(str => str[0].toUpperCase() + str.substr(1).toLowerCase())
        .join(" ");

const GroupView: FC<GroupViewProps> = ({ group }) => {
    const selectedGroup = useSelector(getSelectedGroup);
    const selected = group.id === (selectedGroup?.id || -1);

    const styles = useStyles({ selected });

    const dispatch = useDispatch();

    const action = () => {
        if (isNullOrUndefined(selectedGroup) || !selected){
            dispatch(selectGroup(group));
            return;
        }

        dispatch(selectBrand(null));
        dispatch(selectGroup(null));
    }

    return (
        <ListItem className={styles.listItem} onClick={action} disableGutters>
            <img className={styles.listItemIcon} alt={name(group.name)} src={group.image} />
            <ListItemText primary={name(group.name)} disableTypography />

            {
                selected &&
                <IconButton>
                    <CancelIcon color="secondary" />
                </IconButton>
            }
        </ListItem>
    );
}

export default GroupView;