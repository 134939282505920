import { FC } from "react"
import Carousel from "../../components/carousel/Carousel"
import Slide from "../../components/carousel/Slide"
import { useBanners } from "../../hooks/UseBanners"

const InstitutionBanner: FC = () => {
    const [banners, operation] = useBanners();

    return (
        <Carousel delay={5000}>
            {banners?.map((banner, index) => (
                <Slide key={index} image={banner.image} />
            ))}
        </Carousel>
    )
}

export default InstitutionBanner
