import {
    AppBar,
    createStyles,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemText,
    makeStyles,
    SwipeableDrawer,
    Tab,
    Tabs,
    Theme,
    Toolbar
} from "@material-ui/core";
import { fade } from "@material-ui/core/styles/colorManipulator";
import MenuIcon from "@material-ui/icons/Menu";
import {
    FC,
    useEffect,
    useState
} from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../../assets/images/echope-home.png";
import { getVisitedStoreHome } from "../../services/SiteLocalStorage";
import { institutionalPaths, menuItems } from "./Menu";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        appBar: {
            boxShadow: "none",
            borderBottom: `1px solid ${theme.palette.primary.main}`,
            backgroundColor: fade("#000", 0.8),
        },
        toolbar: {
            minHeight: 80,
            justifyContent: "space-between",
        },
        inline: {
            display: "inline",
        },
        iconContainer: {
            display: "none",
            [theme.breakpoints.down("sm")]: {
                display: "block",
            },
        },
        iconButton: {
            float: "right",
        },
        tabContainer: {
            marginLeft: 32,
            [theme.breakpoints.down("sm")]: {
                display: "none",
            },
        },
        tabItem: {
            paddingTop: 20,
            paddingBottom: 20,
            minWidth: "auto",
            textTransform: "none",
        },
        flex: {
            display: "flex",
            justifyContent: "space-between",
            paddingLeft: 40,
            paddingRight: 40,
            paddingBottom: "0 !important",
            paddingTop: "0 !important",
            [theme.breakpoints.down("sm")]: {
                display: "flex",
                alignItems: "center",
            },
        },
        link: {
            textDecoration: "none",
            color: "inherit",
        },
    })
);

const MainAppBar: FC = () => {
    const styles = useStyles();

    const [currentValue, setCurrentValue] = useState<number | null>(0);
    const [menuDrawerOpen, setMenuDrawerOpen] = useState(false);
    const location = useLocation();
    const hasVisitedStoreHome = getVisitedStoreHome()?.isVisited || false;

    const drawerOpen = () => setMenuDrawerOpen(true);
    const drawerClose = () => setMenuDrawerOpen(false);

    const menus = menuItems();

    const isInstitutionalPath = (): boolean => {
        return institutionalPaths().some(
            (path) => path === location.pathname || location.pathname === "/"
        );
    };

    useEffect(() => {
        const menuItens = menus.map((menu) => menu.path);
        menuItens.push("/")
        setCurrentValue(menuItens.indexOf(location.pathname));
    }, [location, menus]);

    return (
        <AppBar className={styles.appBar}>
            <Toolbar>
                <Grid container spacing={10} alignItems="baseline">
                    <Grid item xs={12} className={styles.flex}>
                        <div className={styles.inline}>
                            <Link to="/" className={styles.link}>
                                <img src={logo} alt="echope" />
                            </Link>
                        </div>

                        <div className={styles.iconContainer}>
                            <IconButton
                                className={styles.iconButton}
                                aria-label="Menu"
                                onClick={drawerOpen}
                            >
                                <MenuIcon color="secondary" />
                            </IconButton>
                        </div>

                        <div className={styles.tabContainer}>
                            <SwipeableDrawer
                                anchor="right"
                                onClose={drawerClose}
                                onOpen={drawerOpen}
                                open={menuDrawerOpen}
                            >
                                <AppBar title="Menu" />
                                <List>
                                    {menus
                                        .filter((menu) => menu.showOnTop)
                                        .map((item, index) => (
                                            <ListItem
                                                component={Link}
                                                to={item.path}
                                                button
                                                onClick={drawerClose}
                                                key={index}
                                            >
                                                <ListItemText
                                                    primary={item.label}
                                                />
                                            </ListItem>
                                        ))}
                                </List>
                            </SwipeableDrawer>

                            <Tabs
                                value={currentValue}
                                indicatorColor="primary"
                                textColor="primary"
                                onChange={(event, value) =>
                                    setCurrentValue(value)
                                }
                            >
                                {menus
                                    .filter((menu) => menu.showOnTop)
                                    .map((value, index) => (
                                        <Tab
                                            key={index}
                                            component={Link}
                                            to={value.path}
                                            classes={{ root: styles.tabItem }}
                                            label={value.label}
                                            style={
                                                isInstitutionalPath() &&
                                                (!hasVisitedStoreHome && !value.showOnInstitutional)
                                                    ? { display: "none" }
                                                    : {}
                                            }
                                        />
                                    ))}
                            </Tabs>
                        </div>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    );
};

export default MainAppBar;
