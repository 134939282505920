import { Card, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { FC } from "react";
import { useDispatch } from "react-redux";
import LinkButton from "../../../components/button/LinkButton";
import { sendCleanPreferences, sendUpdatePreferencesSelectedStore, StoreWithDistance } from "../../../store/preferences";
import { formatAddress, formatPhone } from "../../../util/formatter";
import { sendCleanStore } from "@echope/echope-store-core/dist/store/store";
import { sendCleanCart } from "@echope/echope-store-core/dist/store/cart";

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        width: "24%",
        marginTop: 10,
        display: "flex",
        flexDirection: "column",
        marginRight: "0.5%",
        marginLeft: "0.5%",
        alignItems: "space-between",
        '&:hover': {
            boxShadow: `0 0 6px 2px ${theme.palette.primary.main}`
        },
        [theme.breakpoints.down("md")]: {
            width: "48%",
            marginRight: "1%",
            marginLeft: "1%"
        },
        [theme.breakpoints.down("sm")]: {
            width: "98%",
            marginRight: "1%",
            marginLeft: "1%"
        }
    },
    main: {
        display: "flex",
        flexDirection: "column",
        flex: 1,
    },
    media: {
        height: 250,
        width: "100%",
        objectFit: "cover"
    },
    header: {
        display: "flex",
        padding: theme.spacing(2),
        flexDirection: "column",
    },
    content: {
        display: "flex",
        padding: theme.spacing(2),
        flexDirection: "column",
    },
    action: {
        display: "flex",
        padding: theme.spacing(2),
        flexDirection: "column",
        justifyContent: "flex-end",
        marginTop: 10,
    },
    title: {
        fontWeight: "bold"
    },
    accent: {
        fontWeight: "bold",
        marginBottom: 10
    }
}));

export interface StoreViewProps {
    item: StoreWithDistance
}

const StoreView: FC<StoreViewProps> = ({ item }) => {
    const styles = useStyles({ cardWidth: (window.innerWidth * 0.95 - 15) / 4 });

    const dispatch = useDispatch();

    const enterAction = (store: StoreWithDistance) => {
        dispatch(sendCleanPreferences());
        dispatch(sendCleanStore());
        dispatch(sendCleanCart());

        dispatch(sendUpdatePreferencesSelectedStore(store))
    };

    const { store, hasDistance, formattedDistance } = item;
    const { name, address, phone, imageWideURL, info } = store!!;
    const { openFrom, closeAt, isDelivering, isDrive } = info;

    return (
        <Card className={styles.root}>
            <div className={styles.main}>
                <div className={styles.header}>
                    <Typography className={styles.title} variant="h5">{name}</Typography>
                    <Typography variant="caption">{address.city}/{address.state} - {formatPhone(phone)}</Typography>

                    {
                        hasDistance &&
                        <Typography variant="caption">{`Loja à ${formattedDistance} de distância`}</Typography>
                    }
                </div>

                <img className={styles.media} src={imageWideURL} alt={name} />

                <div className={styles.content}>
                    {
                        isDrive
                            ? <Typography variant="body1" color={"error"}
                                className={styles.accent}>Vendas somente em loja física!</Typography>
                            : isDelivering
                                ? (<Typography variant="body1" className={styles.accent}>
                                        {`Aberto das ${openFrom} até ${closeAt}`}</Typography>)
                                : (<Typography variant="body1" color={"error"}
                                    className={styles.accent}>Loja online fechada!</Typography>)
                    }

                    <Typography variant="body1">{formatPhone(phone)}</Typography>
                    <Typography variant="body1">{formatAddress(address)}</Typography>
                </div>
            </div>

            <div className={styles.action}>
                <LinkButton path="/store/home"
                    text="Entrar"
                    color="primary"
                    variant="contained"
                    disabled={!isDelivering || isDrive}
                    onClick={() => enterAction(item)} />
            </div>
        </Card>
    )
}

export default StoreView;