import { CatalogItem } from "@echope/echope-store-core/dist/models";
import {
    getCatalogFilteredSelector,
    sendFilterCatalog
} from "@echope/echope-store-core/dist/store/catalog";
import {
    getSelectedBrand,
    getSelectedGroup
} from "@echope/echope-store-core/dist/store/view/catalog";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useFilteredCatalogItems = () => {
    const group = useSelector(getSelectedGroup);
    const brand = useSelector(getSelectedBrand);

    const [lastGroup, setLastGroup] = useState(group);
    const [lastBrand, setLastBrand] = useState(brand);

    const items = useSelector(getCatalogFilteredSelector) as CatalogItem[];
    const dispatch = useDispatch();

    useEffect(() => {
        const sameGroup = lastGroup?.id === group?.id;
        const sameBrand = lastBrand?.id === brand?.id;

        if (!sameBrand || !sameGroup) {
            dispatch(sendFilterCatalog(brand, group, undefined));

            if (!sameBrand) {
                setLastBrand(brand);
            }

            if (!sameGroup) {
                setLastGroup(group);
            }
        }
    }, [brand, dispatch, group, lastBrand?.id, lastGroup?.id]);

    return items;
};
