const isNullOrUndefined = (item: any): boolean => {
   return item === null || item === undefined;
}

const nonNullOrUndefined = (item: any): boolean => {
   return !isNullOrUndefined(item);
}

const isEmpty = (item: string | undefined): boolean => {
   if (isNullOrUndefined(item)){
      return true;
   }

   return item !== undefined && item.trim().length === 0;
}

const isValidEmail = (value: string) => {
    return /\S+@\S+\.\S+/.test(value)
}

export {
   isNullOrUndefined,
   nonNullOrUndefined,
   isEmpty,
   isValidEmail,
}