import { createStyles, makeStyles, Theme } from "@material-ui/core";
import React, { FC } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { useSelectedStore } from '../../hooks/UseSelectedStore';
import CartScreen from "../../screen/cart/CartScreen";
import CatalogScreen from "../../screen/catalog/CatalogScreen";
import HelpScreen from "../../screen/help/HelpScreen";
import HistoryBuy from "../../screen/historyBuy/HistoryBuy";
import HomeScreen from "../../screen/home/HomeScreen";
import InstitutionScreen from "../../screen/institution/InstitutionScreen";
import LoginScreen from "../../screen/login/LoginScreen";
import PrivacyScreen from "../../screen/privacy/PrivacyScreen";
import ResetPassword from "../../screen/reset/ResetPassword";
import StoreHomeScreen from "../../screen/store/StoreHomeScreen";
import StoreListScreen from "../../screen/store/StoreListScreen";
import UsageTermsScreen from "../../screen/usage/UsageTerms";
import RegistrationScreen from "../../screen/user/RegistrationScreen";
import AcceptCookies from "../modal/AcceptCookies";
import LegalAge from "../modal/LegalAge";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            flex: 1,
            flexDirection: "column",
            paddingTop: 65,
        },
        "& > div": {
            padding: theme.spacing(1),
        },
    })
);

const Page: FC = () => {
    const styles = useStyles();

    const selectedStore = useSelectedStore()

    const [modalAgeAverage, setModalAgeAverage] = React.useState(true);
    const [wasAcceptCookies, setWasAcceptCookies] = React.useState(false);

    React.useEffect(() => {
        const wasAccepted = Boolean(localStorage.getItem("acceptedCookies"));
        setWasAcceptCookies(wasAccepted);
    }, []);

    React.useEffect(() => {
        const win: any = window;

        win.gtag('config', 'UA-143777450-1', { 'page_path': window.location.href });
        win.gtag('config', 'AW-392165405');
    })

    return (
        <main className={styles.container}>
            <Switch>
                <Route exact path="/">
                    {/* <HomeScreen modalAgeAverage={modalAgeAverage} setModalAgeAverage={setModalAgeAverage}/> */}
                    <HomeScreen />
                </Route>

                <Route path="/catalog">
                    <CatalogScreen />
                </Route>

                <Route path="/about">
                    <InstitutionScreen />
                </Route>

                <Route path="/privacy">
                    <PrivacyScreen />
                </Route>

                <Route path="/usage">
                    <UsageTermsScreen />
                </Route>

                <Route path="/stores">
                    <StoreListScreen />
                </Route>

                <Route path="/store/home">
                    <StoreHomeScreen selectedStore={selectedStore} />
                </Route>

                <Route exact path="/store/cart">
                    <CartScreen />
                </Route>

                <Route path="/store/cart/history-buy">
                    <HistoryBuy />
                </Route>

                <Route path="/help">
                    <HelpScreen />
                </Route>

                <Route path="/registration">
                    <RegistrationScreen />
                </Route>

                <Route path="/login">
                    <LoginScreen />
                </Route>

                <Route path="/alteracao-senha/:email">
                    <ResetPassword />
                </Route>
            </Switch>

            <LegalAge
                modalAgeAverage={modalAgeAverage}
                setModalAgeAverage={setModalAgeAverage}
            />
            <AcceptCookies
                isAccepted={wasAcceptCookies}
                handlerAccept={setWasAcceptCookies}
            />
        </main>
    );
};

export default withRouter(Page);
