import { StoreAddress } from "@echope/echope-store-core/dist/models";
import format from 'date-fns/format';

const currencyFormatter = new Intl.NumberFormat("pt-BR", {
    style: 'currency',
    currency: 'BRL'
});

const currencyFormatterNoStyle = new Intl.NumberFormat("pt-BR", { minimumFractionDigits: 2 });

const dateFormatter = new Intl.DateTimeFormat("pt-BR", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit"
});

const dateTimeFormatter = new Intl.DateTimeFormat("pt-BR", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: false
});

const formatDate = (value: string) => dateFormatter.format(new Date(value));

const formatServerDate = (value: Date) => format(value, 'yyyy-MM-dd');

const formatDateTime = (value: string) => dateTimeFormatter.format(new Date(value));

const formatPhone = (value: string) => value.replace(/(\d{2})(\d{4,5})(\d{4})/, '($1) $2-$3');

const formatDocument = (value: string) => value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');

const formatRG = (value: string) => value.replace(/(\d{2})(\d{3})(\d{3})(\d{1,2})/, '$1.$2.$3-$4');

const formatCEP = (value: string) => value.replace(/(\d{5})(\d{3})/, '$1-$2');

const formatAddress = (address: StoreAddress) => {
    const { street, neighborhood, city, state } = address;

    return `${street}, ${neighborhood} - ${city} ${state}`
}

const unMask = (value: string) => value.replace(/[^\d]/g, "");

export {
    currencyFormatter,
    currencyFormatterNoStyle,
    dateFormatter,
    formatDate,
    formatDateTime,
    formatPhone,
    formatDocument,
    formatRG,
    formatCEP,
    formatAddress,
    unMask,
    formatServerDate
}