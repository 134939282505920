import styled from "styled-components";
import {FC, ReactNode, useEffect, useRef, useState} from "react";

const CarouselWrapper = styled.div`
  display: flex;
  overflow: hidden;
  flex: 1;

  @media(max-width: 800px){
    flex-basis: 100%;
  }
  
  @media(max-width: 600px){
    flex-basis: 100%;
  }
`;

export interface ICarouselSlide {
    active?: boolean;
    slidesByTime?: number;
}

const CarouselSlide = styled.div<ICarouselSlide>`
  flex: 0 0 auto;
  opacity: ${props => (props.active ? 1 : 0)};
  width: ${props => `${Math.round(100 / (props.slidesByTime || 1))}%`};
  transition: all 0.5s ease;
  margin-right: 0.5%;
`;

export interface ICarouselProps {
    currentSlide: number;
}

const CarouselSlides = styled.div<ICarouselProps>`
  display: flex;
  transition: all 0.5s ease;
  width: 100%;
`;


export interface CarouselProps {
    children: JSX.Element[];
    delay?: number;
    slidesByTime?: number;
}

const slidesToShow = (slidesToShow: number) => (slidesCount: number) => (currentSlide: number): number[] => {
    const result = [];
    let position = currentSlide;

    while(result.length < slidesToShow){
        result.push(position);

        position++;

        if (position >= slidesCount){
            position = 0;
        }
    }

    return result;
}

const Carousel: FC<CarouselProps> = ({children, slidesByTime, delay}) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const intervalRef = useRef<number | null>(null);
    const indexRef = useRef(currentIndex);

    const activeSlides = slidesToShow(slidesByTime || 1)(children.length)(currentIndex);

    const allSlides = children.map((slide, index) => (
        <CarouselSlide key={`slide-${index}`} slidesByTime={slidesByTime} active={true}>
            {slide}
        </CarouselSlide>
    ));

    const slides: ReactNode[] = [];
    activeSlides.forEach(value => slides.push(allSlides[value]));

    useEffect(() => {
        indexRef.current = currentIndex;
    }, [currentIndex])

    useEffect(() => {
        const tick = () => {
            const newIndex = indexRef.current + 1;
            setCurrentIndex(newIndex >= children.length ? 0 : newIndex);
        }

        intervalRef.current = window.setInterval(tick, delay || 5000);

        return () => window.clearInterval(intervalRef.current || 0)
    }, [children, delay])

    return (
        <CarouselWrapper>
            <CarouselSlides currentSlide={currentIndex}>
                {slides}
            </CarouselSlides>
        </CarouselWrapper>
    )
}

export default Carousel;