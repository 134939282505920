import {
    Button,
    Card,
    CardActions,
    CardContent, Modal,
    Theme,
    Typography
} from "@material-ui/core"
import { InfoOutlined } from "@material-ui/icons"
import { createStyles, makeStyles } from "@material-ui/styles"
import React, { Dispatch, FC, ReactElement, SetStateAction } from "react"

export interface InfoModal {
    modalInfo: boolean;
    setModalInfo: Dispatch<SetStateAction<boolean>>;
    title: string;
    txtSuccessBtn: string;
    txtCloseBtn?: string;
    icon?: ReactElement;
    closeCallback?: () => void;
    successCallback?: () => void;
    variant?: "text" | "outlined" | "contained";
    isCenter?: boolean
}

const Info: FC<InfoModal> = ({
    modalInfo,
    setModalInfo,
    title,
    children,
    icon,
    txtSuccessBtn,
    txtCloseBtn,
    closeCallback,
    successCallback,
    variant,
    isCenter,
}) => {
    const classes = useStyles()

    // const [modalInfo, setModalInfo] = React.useState(false)

    const handleClose = () => {
        setModalInfo(false)
        closeCallback && closeCallback();
    }

    const handleOk = () => {
        successCallback && successCallback();
        setModalInfo(false)
    }

    return (
        <Modal
            open={modalInfo}
            aria-labelledby="Confirmação"
            aria-describedby="Confirmação para enviar itens no carrinho"
        >
            <Card className={classes.cardWrapper}>
                <CardContent className={classes.cardContent}>
                    <div className={classes.center}>
                        {icon ? icon : <InfoOutlined color="primary" style={{ fontSize: '100px' }} />}
                        <Typography color="primary" variant="h5">{title}</Typography>
                    </div>

                    <br />

                    {children}
                </CardContent>

                <CardActions className={`${classes.cardActions} ${isCenter && classes.btnCenter}`}>
                    {txtCloseBtn && (
                        <Button
                            size="large"
                            variant={variant || 'contained'}
                            color="primary"
                            onClick={handleClose}
                        >
                            {txtCloseBtn}
                        </Button>
                    )}

                    <Button
                        size="large"
                        variant={variant || 'contained'}
                        color="primary"
                        onClick={handleOk}
                    >
                        {txtSuccessBtn}
                    </Button>
                </CardActions>
            </Card>
        </Modal>
    )
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        center: {
            margin: '0 auto',
            textAlign: 'center',
        },
        cardWrapper: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.5)",
            padding: 16,
        },
        cardContent: {
            margin: '0 auto',
            maxWidth: 500,
            display: "flex",
            flexFlow: "column nowrap",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
        },
        cardActions: {
            margin: '0 auto',
            maxWidth: 500,
            display: "flex",
            justifyContent: "flex-end",
        },
        [theme.breakpoints.down("sm")]: {
            cardWrapper: {
                width: "80%",
                height: "auto",
            },
        },
        btnCenter: {
            justifyContent: 'center',
        },
    })
)

export default Info
