import { useSelector } from "react-redux";
import { getSelectedGroup } from "@echope/echope-store-core/dist/store/view/catalog";
import { useEffect, useState } from "react";
import { Brand, CatalogItem } from "@echope/echope-store-core/dist/models";
import {
    getBrandsSelector,
    getCatalogItemsSelector,
} from "@echope/echope-store-core/dist/store/catalog";
import { isNonNullOrUndefined } from "@echope/echope-store-core/dist/util/assertions";

export const useFilteredBrands = () => {
    const group = useSelector(getSelectedGroup);
    const [filtered, setFiltered] = useState(false);
    const [brands, setBrands] = useState<Brand[]>([]);
    const items = useSelector(getCatalogItemsSelector) as CatalogItem[];
    const allBrands = useSelector(getBrandsSelector) as Brand[];

    const removeDuplicates = (brands: Brand[]) =>
        brands.reduce((brds, currBrd) => {
            if (brds.length === 0) return [currBrd];

            if (brds.some((g) => g.id === currBrd.id)) return brds;
            else return [...brds, currBrd];
        }, [] as Brand[]);

    useEffect(() => {
        const filteredBrands = items
            .filter((i) => (i.group?.id || -1) === (group?.id || -2))
            .filter((i) => isNonNullOrUndefined(i.brand))
            .map((i) => i.brand) as Brand[];

        setFiltered(isNonNullOrUndefined(group));
        setBrands(
            isNonNullOrUndefined(group)
                ? removeDuplicates(filteredBrands)
                : allBrands
        );
    }, [allBrands, group, items]);

    return [brands, filtered] as const;
};
