import { createStyles, List } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { CatalogItem, Product } from "@echope/echope-store-core/dist/models";
import { isNonNullOrUndefined } from "@echope/echope-store-core/dist/util/assertions";
import React, { FC, useState } from "react";
import SectionHeader from "../../../components/header";
import { useFilteredCatalogItems } from "../../../hooks/UseFilteredCatalogItems";
import CatalogItemDetailView from "./CatalogItemDetailView";
import CatalogItemView from "./CatalogItemView";

const useStyles = makeStyles((theme) =>
    createStyles({
        list: {
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "flex-start",
        },
    })
);

const CatalogItemListView: FC = () => {
    const [open, setOpen] = useState(false);
    const [item, setItem] = useState<Product>({} as Product);

    const items = useFilteredCatalogItems();
    const styles = useStyles();

    const onItemClick = (item: CatalogItem) => {
        setOpen(isNonNullOrUndefined(item));
        setItem(item.product);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div style={{ backgroundColor: "#efefef", marginBottom: 8 }}>
            <SectionHeader
                variant="h6"
                decorated={false}
                primary={false}
                title="Produtos"
            />
            <List className={styles.list}>
                {items.map((item) => (
                    <CatalogItemView
                        key={item.product.id}
                        item={item}
                        onClick={onItemClick}
                    />
                ))}
            </List>

            <CatalogItemDetailView
                product={item}
                open={open}
                onClose={handleClose}
            />
        </div>
    );
};

export default CatalogItemListView;
