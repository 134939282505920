import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import presentSvg from "../../../assets/vectors/gift-outline.svg";

const CouponButton: FC<{ onClick: () => void }> = ({ onClick }) => {
    const styles = useStyles();

    return (
        <>
            <button type="button" className={styles.buttonCoupon} onClick={onClick}>
                <div>
                    <img
                        src={presentSvg}
                        alt="Caixa de presente com um laço"
                        style={{ width: 80, height: 80 }}
                    />
                </div>
                <div style={{ color: "white", textAlign: "center" }}>
                    <Typography variant="h5">
                        Temos desconto pra você!
                    </Typography>
                    <Typography variant="body2">
                        Clique aqui e veja seus cupons e as regras de desconto
                    </Typography>
                </div>
            </button>
        </>
    )
}


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonCoupon: {
            maxWidth: 400,
            display: "flex",
            alignItems: "center",
            gap: 10,
            padding: 20,
            background: `linear-gradient(-160deg, ${theme.palette.primary.dark} 10%, ${theme.palette.primary.light} 60%)`,
            cursor: "pointer",
            borderRadius: 10,
            margin: "20px auto",
            transition: "1s",
            "&:hover": {
                opacity: "0.7",
            },
        },

    })
);

export default CouponButton;