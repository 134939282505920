export interface Menu {
    label: string;
    path: string;
    showOnTop: boolean;
    showOnInstitutional: boolean,
}

export const menuItems = (): Menu[] => {
    return [
        {
            label: "Home",
            path: "/store/home",
            showOnTop: true,
            showOnInstitutional: false
        },
        {
            label: "Catálogo",
            path: "/catalog",
            showOnTop: true,
            showOnInstitutional: false
        },
        {
            label: "Nossas Lojas",
            path: "/stores",
            showOnTop: true,
            showOnInstitutional: true
        },
        {
            label: "Projeto echope",
            path: "/about",
            showOnTop: true,
            showOnInstitutional: true
        },
        {
            label: "Termo de Uso",
            path: "/usage",
            showOnTop: false,
            showOnInstitutional: true
        },
        {
            label: "Política de Privacidade",
            path: "/privacy",
            showOnTop: false,
            showOnInstitutional: true
        }
    ];
}

export const institutionalPaths = () => {
    return menuItems().filter(path => {
        return path.showOnInstitutional
    }).map(path => {
        return path.path;
    })
}