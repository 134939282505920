import {createMuiTheme, responsiveFontSizes, Theme} from "@material-ui/core";

// eslint-disable-next-line
import type from "@material-ui/lab/themeAugmentation"

const createTheme = (): Theme => {
    return responsiveFontSizes(createMuiTheme({
        palette: {
            primary: {
                main: '#008200',
                light: '#2db42d',
                dark: '#004300',
            },
            text: {
                primary: "#222",
                secondary: "#fff",
            },
            secondary: {
                // main: '#A24A00',
                // light: '#E28538',
                // dark: '#542600',
                main: '#444',
                // light: '#E28538',
                // dark: '#542600',
            },
            error: {
                light: '#FF5B39',
                main: '#FF2C00',
                dark: '#C52200',
                contrastText: "#FFF"
            },
            common: {
                black: "#444",
                white: "#fff"
            },
            grey: {
                100: '#E7E7E7',
            }
        },
        typography: {
            fontSize: 13
        },
        overrides: {
            MuiTab: {
                textColorPrimary: {
                    color: "#fff",
                }
            },
            MuiButton: {
                root: {
                    textTransform: "none"
                }
            },
            MuiFormLabel: {
                root: {
                    color: "#000",
                }
            },
            MuiSvgIcon: {
                colorSecondary: {
                    color: '#fff'
                }
            }
        }
    }));
}

export {
    createTheme
};