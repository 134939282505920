import DateFnsUtils from "@date-io/date-fns";
import {
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Theme,
    Typography
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import {
    DatePicker,
    MuiPickersUtilsProvider,
    TimePicker
} from "@material-ui/pickers";
import { createStyles, makeStyles } from "@material-ui/styles";
import { ptBR } from "date-fns/locale";
import { isLoggedInSelector } from "@echope/echope-store-core/dist/store/auth";
import { FormikProps } from "formik";
import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AddressForm from "../../../components/modal/AddressForm";
import Login from "../../../components/modal/Login";
import shippingImpl from "../../../services/Shipping";
import { AddressRequest } from "../../../services/User";
import { getAddressTextReq } from "../../../util/Address";
import { formatCEP } from "../../../util/formatter";
import { FormState } from "../CartScreen";
import { PaymentOption } from "@echope/echope-store-core/dist/models";

interface DeliveryProps {
    timeDelivery: number;
    storeId: number;
    formik: FormikProps<FormState>;
    initialDate: Date;
}

const Delivery: FC<DeliveryProps> = ({
    timeDelivery,
    storeId,
    formik,
    initialDate,
}) => {
    const styles = useStyles();
    const isUserLogged = useSelector(isLoggedInSelector)

    // const [errorsMessage, setErrorsMessage] = useState({ deliveryAddress: '' })
    const [defaultDeliveryTime, setDefaultDeliveryTime] = useState(true);
    const [modalLogin, setModalLogin] = React.useState(false)
    const [modalAddress, setModalAddress] = React.useState(false)

    const handleModalAddress = () => {
        if (!isUserLogged) {
            setModalLogin(true)
            return
        }

        setModalAddress(true)
    }

    const makeAddressInfo = (address: AddressRequest) => {
        if (Object.keys(address).length > 0) {
            return getAddressTextReq(address)
        }

        return 'Selecione um CEP para Entrega'
    }

    // useEffect(() => {
    //     const validaCepEntrega = async () => {
    //         try {
    //             const cepFormated = formatCEP(formik.values.addressDelivery?.postalCode)
    //             await shippingImpl().getValidAddress2Delivery(storeId, cepFormated)

    //             setErrorsMessage({ deliveryAddress: '' })
    //         } catch (error) {
    //             //@ts-ignore
    //             setErrorsMessage({ deliveryAddress: error.message })
    //         }
    //     }

    //     if (formik.values.addressDelivery?.postalCode) {
    //         validaCepEntrega();
    //     }
    // }, [formik.values.addressDelivery?.postalCode, storeId])

    return (
        <fieldset className={styles.container}>
            <Typography variant="h5" component="h3">
                Dados da entrega
            </Typography>

            {defaultDeliveryTime ? (
                <Box display="flex" alignItems="center" gridGap={20}>
                    <Typography color="error" style={{ fontWeight: "bold" }}>
                        Seu pedido será entregue em {timeDelivery} minutos
                    </Typography>

                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={() => setDefaultDeliveryTime(false)}
                    >
                        Agendar
                    </Button>
                </Box>
            ) : (
                <Box display="flex" flexDirection="column">
                    <Box display="flex" gridGap={20}>
                        <MuiPickersUtilsProvider
                            locale={ptBR}
                            utils={DateFnsUtils}
                        >
                            <DatePicker
                                label="Data"
                                value={formik.values.dateDelivery}
                                disablePast
                                name="dateDelivery"
                                onChange={value => formik.setFieldValue("dateDelivery", value)}
                                inputVariant="outlined"
                                format="dd/MM/yyyy"
                                animateYearScrolling
                                className={styles.input}
                            />
                            <TimePicker
                                label="Hora"
                                value={formik.values.dateDelivery}
                                name="dateDelivery"
                                ampm={false}
                                onChange={value => formik.setFieldValue("dateDelivery", value)}
                                inputVariant="outlined"
                                className={styles.input}
                            />
                        </MuiPickersUtilsProvider>
                    </Box>
                    <p
                        role="button"
                        onClick={() => {
                            formik.setFieldValue("dateDelivery", initialDate)
                            setDefaultDeliveryTime(true)
                        }}
                        className={styles.deliveryButton}
                    >
                        Quero entrega imediata
                    </p>
                </Box>
            )}

            <FormControl className={styles.input} variant="outlined" fullWidth>
                <InputLabel id="paymentMethod" shrink className={styles.label}>
                    Método de pagamento
                </InputLabel>
                <Select
                    labelId="paymentMethod"
                    label="Método de pagamento"
                    id="paymentMethod"
                    name="paymentMethod"
                    value={formik.values.paymentMethod}
                    onChange={formik.handleChange}
                    displayEmpty
                >
                    <MenuItem value="0" disabled>
                        Selecione a Forma de Pagamento
                    </MenuItem>
                    <MenuItem value={PaymentOption.DebitCard}>
                        Quero pagar com o cartão de débito
                    </MenuItem>
                    <MenuItem value={PaymentOption.CreditCard}>
                        Quero pagar com o cartão de crédito
                    </MenuItem>
                    <MenuItem value={PaymentOption.Cash}>Quero pagar em dinheiro</MenuItem>
                </Select>
            </FormControl>

            <fieldset className={styles.addressField}>
                <legend>Endereço da entrega</legend>
                <div className={styles.infoAddress}>
                    <Typography variant="body2">
                        {makeAddressInfo(formik.values.addressDelivery)}
                    </Typography>
                    <Button color="primary" variant="contained" onClick={handleModalAddress}>
                        <Search />
                    </Button>
                </div>

                {/* {!!errorsMessage?.deliveryAddress && (
                    <Typography variant="body2" color="error">
                        {errorsMessage?.deliveryAddress}
                    </Typography>
                )} */}
            </fieldset>

            <TextField
                name="observation"
                value={formik.values.observation}
                onChange={formik.handleChange}
                className={styles.input}
                label="Observação"
                variant="outlined"
                placeholder="Digite a observação do seu pedido (não obrigatório)"
                fullWidth
                InputLabelProps={{
                    shrink: true,
                }}
            />

            <Login
                modalLogin={modalLogin}
                setModalLogin={setModalLogin}
            />

            <AddressForm
                modalAddress={modalAddress}
                setModalAddress={setModalAddress}
                formikCart={formik}
            />
        </fieldset>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: "flex",
            flexFlow: "column nowrap",
            gap: 15,
            marginTop: 20,
            border: `1px solid ${theme.palette.grey[100]}`,
            "& h3": {
                background: theme.palette.background.default,
                marginTop: -15,
                padding: "0 10px 10px",
                width: 190,
            },
        },
        addressField: {
            border: `1px solid ${theme.palette.grey[400]}`,
            borderRadius: 5,
        },
        input: {
            "& .MuiOutlinedInput-input": {
                padding: 11,
            },
        },
        label: {
            background: theme.palette.background.default,
            padding: "0 10px",
        },
        deliveryButton: {
            padding: 10,
            margin: 0,
            fontSize: 15,
            color: theme.palette.primary.main,
            cursor: "pointer",
            transition: "color 0.1s",
            "&:hover": {
                color: theme.palette.primary.dark,
            },
        },
        infoAddress: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            background: theme.palette.grey[100],
            padding: 10,
            "& button": {
                padding: 5,
                minWidth: 40,
                minHeight: 40,
            },
        },
    })
);

export default Delivery;
