import { createStyles, makeStyles } from "@material-ui/core";
import React, { FC } from "react";
import { useDispatch } from "react-redux";
import SectionHeader from "../../components/header";
import DownloadApp from "../../components/modal/DownloadApp";
import { useStoreWithDistance } from "../../hooks/UseStoresWithDistance";
import { setVisitedStoreHome } from "../../services/SiteLocalStorage";
import HomeTopBox from "./views/HomeTopBox";
import Location from "./views/Location";

const useStyles = makeStyles(theme => createStyles({
    container: {
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column"
        }
    },
    box: {
        flex: 1
    }
}))

const HomeScreen: FC = () => {
    const styles = useStyles();
    const dispatch = useDispatch();

    useStoreWithDistance();

    React.useEffect(() => {
        setVisitedStoreHome({isVisited: false})
        // dispatch(sendCleanLocation());
        // dispatch(sendCleanPreferences());
        // dispatch(sendCleanStore());
        // dispatch(sendCleanCart());
    }, [])

    return (
        <div className={styles.container}>
            <DownloadApp />
            <div className={styles.box} style={{ marginTop: 10 }}>
                <HomeTopBox />
            </div>
            <SectionHeader title="Encontre a loja mais próxima" />
            <div className={styles.box}>
                <Location />
            </div>
        </div>
    )
}

export default HomeScreen;