import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Modal,
    Theme,
    Typography,
} from "@material-ui/core"
import UnderAgeImage from "../../assets/images/alcool-para-menores-proibido.png"
import { makeStyles, createStyles } from "@material-ui/styles"
import React, { Dispatch, FC, SetStateAction } from "react"
import Cancel from "@material-ui/icons/Cancel"

export interface AgeModal {
    modalAgeAverage: boolean, 
    setModalAgeAverage: Dispatch<SetStateAction<boolean>>,
}

const LegalAge: FC<AgeModal> = ({ modalAgeAverage, setModalAgeAverage }) => {
    const classes = useStyles()

    // const [openAverageModal, setOpenAverageModal] = React.useState(true)
    const [isUnderAge, setIsUnderAge] = React.useState(false)

    const handleClose = () => setModalAgeAverage(false)
    const handleUnderAge = () => {
        setIsUnderAge(true);

        setTimeout(() => {
            setIsUnderAge(false)
        }, 2000)
    };

    return (
        <div>
            {isUnderAge && (
                <div className={classes.toast}>
                    <Cancel fontSize="large" />
                    <Typography>
                        Este site é apenas para maiores de 18 anos.
                    </Typography>
                </div>
            )}

            <Modal
                open={modalAgeAverage}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Card className={classes.cardWrapper}>
                    <CardHeader title="Atenção !!!" />

                    <CardContent className={classes.cardContent}>
                        <img className={classes.cardIcon} src={UnderAgeImage} />
                        <Typography
                            component="h4"
                            className={classes.textPrincipal}
                        >
                            Você tem 18 anos ou mais?
                        </Typography>
                        <Typography className={classes.textInfo}>
                            Somente pessoas com 18 anos ou mais podem visitar o
                            site.
                        </Typography>
                    </CardContent>
                    <CardActions className={classes.cardActions}>
                        <Button
                            size="medium"
                            variant="contained"
                            onClick={handleClose}
                        >
                            Sim, eu tenho
                        </Button>
                        <Button
                            size="medium"
                            variant="text"
                            style={{ color: "#fafafa" }}
                            onClick={handleUnderAge}
                        >
                            Não
                        </Button>
                    </CardActions>
                </Card>
            </Modal>
        </div>
    )
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cardWrapper: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "50%",
            background: "linear-gradient(87deg,#008746 0,#00877e 100%)",
            boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.5)",
            padding: 16,
            color: "white",
        },
        cardContent: {
            display: "flex",
            flexFlow: "column nowrap",
            justifyContent: "center",
            alignItems: "center",
        },
        cardActions: {
            display: "flex",
            justifyContent: "space-between",
            "& button": {
                fontSize: "1.125rem",
                textTransform: "uppercase",
            },
        },
        cardIcon: {
            width: 150,
            height: 150,
        },
        toast: {
            zIndex: 1400,
            position: "absolute",
            top: 60,
            right: 25,
            display: "flex",
            alignItems: "center",
            gap: 20,
            padding: 10,
            width: 250,
            height: 100,
            color: "#fafafa",
            backgroundColor: "#bd362f",
            transition: 'ease 1s',
        },
        textPrincipal: {
            textTransform: "uppercase",
            fontSize: "1.5rem",
            margin: "30px 0 20px",
        },
        textInfo: {
            fontSize: "1.025rem",
            marginBottom: 60,
        },
        [theme.breakpoints.down("sm")]: {
            cardWrapper: {
                width: "80%",
                height: "auto",
            },
            cardIcon: {
                width: 100,
                height: 100,
            },
            textInfo: {
                marginBottom: 30,
            },
            cardActions: {
                "& button": {
                    fontSize: "1em",
                },
            },
        },
    })
)

export default LegalAge
