import DateFnsUtils from "@date-io/date-fns";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { KeyboardDatePicker, KeyboardDatePickerProps, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ptBR } from "date-fns/locale";
import { FC } from "react";

const InputDate: FC<KeyboardDatePickerProps> = (props) => {
    const styles = useStyles();

    return (
        <MuiPickersUtilsProvider
            locale={ptBR}
            utils={DateFnsUtils}
        >
            <KeyboardDatePicker
                inputVariant="outlined"
                animateYearScrolling
                className={styles.root}
                InputLabelProps={{ shrink: true }}
                emptyLabel=""
                invalidDateMessage="Data inválida"
                minDateMessage="Data não pode ser menor que a data mínima permitida"
                format="dd/MM/yyyy"
                {...props}
            />
        </MuiPickersUtilsProvider>
    )
}



const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flex: 1,
            "& .MuiOutlinedInput-input": {
                padding: 11,
            },
        },
    })
);


export default InputDate;