import {StoreState} from "@echope/echope-store-core/dist/store/store";
import {operationFrom} from "./OperationStateLoad";
import {Banner, Promotion, ScreenSaverItem} from "@echope/echope-store-core/dist/models";
import {getStore} from "./StoreLoad";

const storeFrom = (state: any): StoreState => {
    try {
        const { stores, promotions, screenSavers, banners, operation } = state;

        return new StoreState(
            stores.map((s: any) => getStore(s)) || [],
            promotions.map((p: any) => p as Promotion) || [],
            screenSavers.map((ss: any) => ss as ScreenSaverItem) || [],
            banners.map((b: any) => b as Banner) || [],
            operationFrom(operation)
        );
    } catch (e) {
        console.log("error loading store state from storage", e);
        return StoreState.empty();
    }
}

export {
    storeFrom
}