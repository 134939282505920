import {useSelector} from "react-redux";
import {getScreenBanners} from "@echope/echope-store-core/dist/store/store";
import {WebApplicationState} from "../store/types";
import {useEffect, useState} from "react";
import {Operation} from "@echope/echope-store-core/dist/util";
import {isNonNullOrUndefined} from "@echope/echope-store-core/dist/util/assertions";

export const useBanners = () => {
    const banners = useSelector(getScreenBanners);
    const storeOperation = useSelector(state => (state as WebApplicationState).store.operation);
    const [operation, setOperation] = useState(Operation.processing("Estamos carregando os banners"));

    useEffect(() => {
        if (operation.isSuccess() && operation.isFailed()){
            return;
        }

        if (!storeOperation.isProcessing()){
            setOperation(storeOperation);
            return;
        }

        if (isNonNullOrUndefined(banners) && banners.length > 0){
            setOperation(Operation.success());
            return;
        }
    }, [banners, storeOperation, operation]);

    return [banners, operation] as const;
}