import { Theme, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import { CartState } from "@echope/echope-store-core/dist/store/cart";
import React, { FC } from "react";
import { useSelector } from "react-redux";
import { getPreferenceSelectedStore } from "../../../store/preferences";
import { currencyFormatterNoStyle } from "../../../util/formatter";

interface SummaryProps {
    cartState: CartState;
    deliveryPrice: number;
}

interface FormatedValues {
    totalPrice: string;
    subTotal: string;
    deliveryTaxZeroPrice: string;
    hasDeliveryTaxZero: boolean;
    readDeliveryPrice: string;
    discount: string;
}

const Summary: FC<SummaryProps> = ({ cartState, deliveryPrice }) => {
    const styles = useStyles();
    const [formated, setFormated] = React.useState<FormatedValues>(
        {} as FormatedValues
    );

    const store = useSelector(getPreferenceSelectedStore)?.store;

    React.useEffect(() => {
        let deliveryTaxZero = cartState.deliveryPrice;
        let hasDeliveryTaxZero = false;
        const readDeliveryPrice = cartState.hasDoubleDelivery ? deliveryPrice * 2 : deliveryPrice

        if (store && store.zeroRate.enabled) {
            hasDeliveryTaxZero = store.zeroRate.enabled && cartState.subTotal > store.zeroRate.forValue;
        }

        setFormated({
            totalPrice: currencyFormatterNoStyle.format(cartState.total),
            subTotal: currencyFormatterNoStyle.format(cartState.subTotal),
            discount: currencyFormatterNoStyle.format(cartState.discount),
            readDeliveryPrice: currencyFormatterNoStyle.format(readDeliveryPrice),
            deliveryTaxZeroPrice: currencyFormatterNoStyle.format(deliveryTaxZero),
            hasDeliveryTaxZero
        });
    }, [cartState.deliveryPrice, cartState.discount, cartState.subTotal, cartState.total, store, deliveryPrice]);

    return (
        <fieldset className={styles.container}>
            <Typography component="legend" variant="h5">Total da compra</Typography>

            <div className={styles.content}>
                <div className={styles.firstLine}>
                    <Typography>Produtos</Typography>
                    <Typography>{cartState.items.length}</Typography>
                </div>

                <div className={styles.line}>
                    <Typography>Subtotal</Typography>
                    <div className={styles.contentMoney}>
                        <Typography>R$</Typography>
                        <Typography>{formated.subTotal}</Typography>
                    </div>
                </div>

                <div className={styles.line}>
                    <Typography>Taxa Conveniência</Typography>
                    <div className={styles.contentMoney}>
                        <Typography>R$</Typography>

                        {!!deliveryPrice && formated.hasDeliveryTaxZero &&
                            (<Typography className={styles.taxZero}>
                                <s> {formated.readDeliveryPrice} </s>
                            </Typography>)
                        }
                        <Typography >{formated.deliveryTaxZeroPrice}</Typography>
                    </div>
                </div>

                <div className={styles.line}>
                    <Typography>Desconto</Typography>
                    <div className={styles.contentMoney}>
                        <Typography>R$</Typography>
                        <Typography>{formated.discount}</Typography>
                    </div>
                </div>

                <div className={styles.line}>
                    <Typography>Total</Typography>
                    <div className={styles.contentMoney}>
                        <Typography>R$</Typography>
                        <Typography>{formated.totalPrice}</Typography>
                    </div>
                </div>
            </div>
        </fieldset>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            marginTop: 20,
            border: `1px solid ${theme.palette.grey[100]}`,
            padding: 10,
        },
        content: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            background: theme.palette.grey[100],
            padding: 10,
        },
        cartLocalization: {},
        firstLine: {
            display: "flex",
            flex: 1,
            flexDirection: "row",
            justifyContent: "space-between",
        },
        line: {
            display: "flex",
            flex: 1,
            marginTop: 10,
            flexDirection: "row",
            justifyContent: "space-between",
        },
        contentMoney: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: 100,
        },
        taxZero: {
            color: theme.palette.grey[500]
        }
    })
);

export default Summary;
