import {Converter, newRemoteService} from "@echope/echope-store-core/dist/infrastructure/api";

class Address {
    readonly postalCode: string;
    readonly street: string;
    readonly complement: string;
    readonly neighborhood: string;
    readonly city: string;
    readonly state: string;
    readonly ibgeCode: string;
    readonly gia: string;
    readonly ddd: string;
    readonly siafi: string;

    constructor(postalCode: string, street: string, complement: string, neighborhood: string, city: string, state: string, ibgeCode: string, gia: string, ddd: string, siafi: string) {
        this.postalCode = postalCode;
        this.street = street;
        this.complement = complement;
        this.neighborhood = neighborhood;
        this.city = city;
        this.state = state;
        this.ibgeCode = ibgeCode;
        this.gia = gia;
        this.ddd = ddd;
        this.siafi = siafi;
    }
}

const addressConverter: Converter<Address> = (data) => {
    return new Address(
        data.cep,
        data.logradouro,
        data.complemento,
        data.bairro,
        data.localidade,
        data.uf,
        data.ibge,
        data.gia,
        data.ddd,
        data.siafi
    );
}


const getAddressByPostalCode = (postalCode: string): Promise<Address> => newRemoteService().get<Address>({
    authenticated: false,
    endpoint: `https://viacep.com.br/ws/${postalCode}/json`,
    converter: addressConverter
});

export {
    Address,
    getAddressByPostalCode
}
