import {FC} from "react";
import StyledContainer from "../../components/core/StyledContainer";
import {createStyles, Grid, makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => createStyles({
    container: {
        justifyContent: "center",
        alignItems: "center"
    },
    box: {
        textAlign: "justify"
    }
}))

const PrivacyScreen:FC = () => {
    const styles = useStyles();

    return (
        <StyledContainer className={styles.container}>
            <Grid item md={8} sm={10} xs={10} className={styles.box}>
                <p>A ECHOPE, está
                    comprometida em resguardar sua privacidade. O objetivo deste documento é
                    esclarecer quais informações são coletadas dos usuários de nosso site www.echope.com.br
                    e respectivos serviços e de que forma esses dados são utilizados.</p>

                <p>Este documento é
                    parte integrante dos Termos e Condições de Uso do site ECHOPE, disponível no &nbsp;
                    <a href="/usage">Termos de Uso</a>. Caso haja conflito entre as
                    disposições de ambos os documentos, deverá prevalecer o quanto estabelecido no
                    documento que trata a questão de forma mais específica.</p>

                <p>Ainda, esta
                    Política poderá ser atualizada, a qualquer tempo, pela ECHOPE, mediante aviso
                    no site e/ou por e-mail, caso o Usuário tenha consentido em receber
                    comunicações diretas da ECHOPE.</p>


                <h2>Sobre a coleta de dados</h2>


                <p>Em nossos sites, as informações são coletadas das seguintes formas:</p>


                <p>Informações fornecidas por você: coletamos informações de identificação pessoal – como CPF, Nome Completo,
                    Data de
                    Nascimento, RG, Telefone, E-mail e Endereço Completo via preenchimento dos
                    formulários no site e aplicativo de celular do ECHOPE.</p>


                <p>
                    Informações
                    de navegação no site: Quando você visita nosso site, é inserido um ‘cookie’ no
                    seu navegador, por meio do software Google Analytics, para identificar quantas
                    vezes você retorna ao nosso site. São coletadas, anonimamente, informações como
                    endereço de IP, localização geográfica, fonte de referência, tipo de navegador,
                    duração da visita e páginas visitadas.</p>

                <p>
                    Histórico
                    de contato: Nós armazenamos informações a respeito de todos os contatos já
                    realizados com nossos usuários, como conteúdos baixados a partir de nossas
                    páginas, interações via e-mail, compras realizadas, etc.</p>

                <h2>Sobre o uso de suas Informações Pessoais</h2>

                <p>
                    As
                    informações coletadas pela ECHOPE
                    têm como finalidade o estabelecimento de vínculo contratual e prestação dos
                    serviços propostos por meio do site e aplicativo, de modo a dar cumprimento aos
                    serviços contratados pelos Usuários.</p>

                <p>
                    As
                    informações coletadas poderão, ainda, ser utilizadas para fins publicitários,
                    como para o envio de informações de marcas, produtos, promoções e descontos da ECHOPE, bem como a
                    divulgação de eventos e de programas de fidelidade, ou para a realização de pesquisa
                    de satisfação de compra, mediante o fornecimento do consentimento do Usuário
                    para tanto através de um “aceite” do usuário no momento do cadastro de seus dados.

                </p>


                <p>
                    No
                    entanto, o usuário pode cancelar a assinatura a qualquer momento. Caso não
                    deseje mais receber informativos publicitários da ECHOPE, a qualquer momento o Usuário pode contatar
                    a ECHOPE por meio
                    do SAC, disponível no link https://www.echope.com.br/contato/.</p>


                <p>
                    A
                    ECHOPE poderá
                    compartilhar as informações coletadas por meio das Páginas, nas seguintes
                    hipóteses:</p>

                <p>
                    i.
                    Para a proteção do crédito, inclusive quanto ao disposto na legislação
                    pertinente.</p>

                <p>
                    ii.
                    Para proteção dos interesses da ECHOPE em qualquer tipo de conflito, incluindo ações judiciais;</p>

                <p>
                    iii.
                    Mediante ordem judicial ou pelo requerimento de autoridades administrativas que
                    detenham competência legal para sua requisição.</p>

                <h2>Sobre o acesso às suas informações pessoais</h2>

                <p>
                    Apenas
                    funcionários da ECHOPE
                    poderão ver suas informações. Nenhuma informação pessoal será divulgada
                    publicamente.</p>

                <p>
                    A
                    ECHOPE também se
                    compromete a não vender, alugar ou repassar suas informações para terceiros em
                    hipótese alguma. A única exceção está em casos em que essas informações forem
                    exigidas judicialmente.</p>

                <p>
                    Embora
                    trabalhemos com boas práticas de proteção e segurança, nenhum serviço web
                    possui 100% de garantia contra invasões e não podemos nos responsabilizar caso
                    isso ocorra.</p>

                <h2>Sobre o compartilhamento de conteúdo nas redes sociais</h2>

                <p>
                    Ao
                    clicar nos botões de compartilhamento de conteúdo nas mídias sociais
                    disponíveis em nossas páginas, o usuário estará publicando os produtos por meio
                    de seu perfil na rede selecionada. A ECHOPE não têm acesso ao login e senha
                    dos usuários nessas redes, nem publicará conteúdo em nome do usuário sem que
                    ele realize esta ação.</p>

                <h2>Sobre a segurança para o cliente</h2>

                <p>
                    Todas
                    as informações pessoais fornecidas no momento do cadastro e da compra em nosso
                    site, são armazenados com criptografia.</p>

                <p>
                    A
                    ECHOPE alerta a
                    todos os clientes que nunca enviará mensagens eletrônicas solicitando
                    confirmação de dados ou com anexos que possam ser executados (extensões: .exe,
                    .com). As mensagens
                    eletrônicas que serão enviadas serão sempre em resposta para alguma ação feita
                    pelos Usuários nas nossas plataformas como alteração de dados cadastrais, envio
                    de senha (esqueci minha senha) ou fechamento de um pedido de compra.</p>

                <p>
                    Pagamentos
                    com cartão de crédito: Os números de cartão de crédito informados nos
                    formulários de pagamento, em ambiente seguro, são gerenciados pelas operadoras
                    de cartões de crédito e, em nenhum momento, são armazenados em nossas bases de
                    dados.</p>

                <p>
                    Boletos
                    de Pagamento: Podem existir alguns vírus de computador que alteram os dados dos
                    boletos antes, ou no momento de impressão. Sendo assim, preste muita atenção
                    aos dados de pagamento e o código numérico do boleto. Confira se o código do
                    banco confere com o código do boleto, e se estiver em dúvida entre em contato
                    com uma agência bancária ou entre em contato com nossa central de atendimento.</p>


                <h2>Sobre o cancelamento do recebimento de e-mail e alteração/exclusão de informações pessoais</h2>


                <p>
                    Você
                    pode optar por não receber mais qualquer tipo de e-mail da ECHOPE. No perfil de cadastro do
                    Usuário existe a opção de receber ou não notificações por e-mails, sms.

                </p>

                <p>
                    Para
                    alterar suas informações pessoais ou mesmo excluí-las do nosso banco de dados,
                    basta enviar um e-mail para <a href="mailto:faleconosco@echope.com.br">faleconosco@echope.com.br</a>

                </p>

                <h2>Sobre mudanças na Política de Privacidade</h2>

                <p>
                    Essa
                    Política de Privacidade pode passar por atualizações. Desta forma, recomendamos
                    visitar periodicamente esta página para que você tenha conhecimento sobre as
                    modificações.</p>

                <p>
                    Antes
                    de usar informações para outros fins que não os definidos nesta Política de
                    Privacidade, solicitaremos sua autorização.</p>


                <p>
                    Alertamos
                    que, se você não concorda com o conteúdo desta política, não é recomendável
                    baixar nossos materiais e não acessar o nosso site.
                </p>

                <h2>Atendimento</h2>

                <p>Qualquer dúvida em relação à nossa política de privacidade pode entrar em contato conosco.</p>

                <p>
                    Envie
                    um e-mail para <a href="mailto:faleconosco@echope.com.br">faleconosco@echope.com.br</a>.</p>

                <p>
                    &nbsp;
                </p>
            </Grid>
        </StyledContainer>
    )
}

export default PrivacyScreen;