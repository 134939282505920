import { Box, Theme, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import { Store, StoreAddress } from "@echope/echope-store-core/dist/models";
import React, { FC } from "react";
import { formatAddress, formatPhone } from "../../../util/formatter";

interface LocalizationCardProps {
    store?: Store;
}

const LocalizationCard: FC<LocalizationCardProps> = ({ store }) => {
    const styles = useStyles();

    return (
        <Box className={styles.container}>
            <div className={styles.image}>
                <img
                    src={store?.imageSquareURL}
                    alt={store?.name}
                />
            </div>
            <Typography className={styles.localization}>
                <strong>{store?.name}</strong>
                <br />
                {formatAddress(store?.address || ({} as StoreAddress))}
                <br />
                {formatPhone(store?.phone || "")}
            </Typography>
        </Box>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: "flex",
            alignItems: "center",
            // flexWrap: "wrap",
            background: theme.palette.grey[100],
            padding: "20px 10px",
        },
        image: {
            borderRadius: "50%",
            overflow: "hidden",
            "& img": {
                width: 150,
                height: 150,
                objectFit: "cover",
            },
        },
        localization: {
            marginLeft: 50,
            fontSize: 13,
            "& strong": {
                fontSize: 18,
            },
        },
        [theme.breakpoints.down("sm")]: {
            container: {
                // flexDirection: 'column',
                // justifyContent: 'center',
            },
            image: {
                "& img": {
                    width: 100,
                    height: 100,
                },
            },
            localization: {
                fontSize: 11,
                marginLeft: 10,
                flex: 1,
                "& strong": {
                    fontSize: 13,
                },
            },
        },
    })
);

export default LocalizationCard;
