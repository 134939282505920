import {WebApplicationState} from "../types";

const saveState = (state: WebApplicationState) => {
    try {
        // console.log("saving state...");
        localStorage.setItem("echope:state", JSON.stringify(state));
    } catch (e) {
        console.log("error saving state " + e);
    }
}

export { saveState }