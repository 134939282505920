import {
    Button,
    Card,
    CardActions,
    CardContent,
    SvgIconTypeMap,
    TextField,
    Typography
} from "@material-ui/core";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { FormikErrors } from "formik";
import { FC } from "react";
import { Link } from "react-router-dom";

export interface DataProfileCardView {
    title: String;
    description: String;
    buttonText?: String;
    buttonAction?: () => void;
    disabled?: Boolean;
    avatar: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
    profileFormik?: {
        handleChange: any,
        values: {
            email: string,
            password: string,
        },
        onSubmit: any,
        error: string,
        errorFormik: FormikErrors<{email: string, password: string}>,
    };
}

export interface ProfileCardViewProps {
    data: DataProfileCardView;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            boxSizing: "content-box",
            display: "flex",
            width: "23%",
            minWidth: 310,
            maxWidth: 350,
            height: "350px",
            margin: "0.5%",
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.text.secondary,
            flexDirection: "column",
            transition: "0.5s",
            "&:hover": {
                boxShadow: "0 0 5px 5px rgba(168, 168, 168, 0.9)",
            },
            [theme.breakpoints.down("sm")]: {
                width: "98%",
                alignSelf: "center",
            },
        },
        container: {
            display: "flex",
            flex: 1,
        },
        header: {
            display: "flex",
            justifyContent: "center",
            flexFlow: "column nowrap",
            gap: 10,
            marginBottom: 10,
        },
        title: {},
        actionsContainer: {
            // margin: theme.spacing(3, 1),
            margin: "auto 0 20px 5px",
        },
        disabled: {
            backgroundColor: theme.palette.grey[300],
            color: theme.palette.text.primary,
            border: `3px solid ${theme.palette.primary.main}`,
        },
        button: {
            backgroundColor: theme.palette.error.main,
            color: theme.palette.text.secondary,
            "&:hover": {
                backgroundColor: theme.palette.error.dark,
            },
            paddingRight: 20,
            paddingLeft: 20,
            [theme.breakpoints.down("sm")]: {
                width: "50%",
            },
        },
        formContainer: {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            marginBottom: 0,
        },
        form: {
            display: 'flex',
            flexFlow: 'column nowrap',
            gap: 20,
            margin: '20px auto 0',
        },
        input: {
            "& .MuiOutlinedInput-input": {
                padding: 11,
            },
        },
        messageError: {

        },
        link: {
            textDecoration: 'none',
            color: theme.palette.primary.dark,
            fontWeight: 'bold',
        }
    })
);

const CardContentProfile: FC<ProfileCardViewProps> = ({ data }) => {
    const styles = useStyles();

    return (
        <>
            <CardContent>
                <div className={styles.header}>
                    <data.avatar fontSize="large" />
                    <Typography variant="h6" className={styles.title}>
                        {data.title}
                    </Typography>
                </div>

                <Typography variant="body1"> {data.description} </Typography>
            </CardContent>

            {data.buttonText && (
                <CardActions className={styles.actionsContainer}>
                    <Button
                        onClick={data.buttonAction}
                        variant="contained"
                        className={styles.button}
                    >
                        {data.buttonText}
                    </Button>
                </CardActions>
            )}
        </>
    )
}

const CardContentFormProfile: FC<ProfileCardViewProps> = ({ data }) => {
    const styles = useStyles();

    return (
        <>
            <CardContent style={{ paddingBottom: 0 }}>
                <div className={styles.header}>
                    <data.avatar fontSize="large" />
                    <Typography variant="h6" className={styles.title}>
                        {data.title}
                    </Typography>
                </div>

                <Typography variant="body1"> {data.description} </Typography>

                <div className={styles.form}>
                    <TextField
                        name="email"
                        value={data?.profileFormik?.values.email}
                        onChange={data?.profileFormik?.handleChange}
                        className={styles.input}
                        label="E-mail"
                        variant="outlined"
                        placeholder="Digite seu e-mail"
                        error={!!data?.profileFormik?.errorFormik.email}
                        helperText={data?.profileFormik?.errorFormik.email}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        name="password"
                        value={data?.profileFormik?.values.password}
                        onChange={data?.profileFormik?.handleChange}
                        type="password"
                        className={styles.input}
                        label="Senha"
                        variant="outlined"
                        placeholder="Digite sua senha"
                        error={!!data?.profileFormik?.errorFormik.password}
                        helperText={data?.profileFormik?.errorFormik.password}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />

                    <Link to="/registration" className={styles.link}>
                        Ainda não é cadastrado?
                    </Link>

                </div>

                <Typography color="error" align="center" style={{ marginTop: 5, fontWeight: 'bold' }}>
                    {!!data.profileFormik?.error ? data.profileFormik?.error : ''}
                </Typography>
            </CardContent>

            {data.buttonText && (
                <CardActions className={styles.actionsContainer}>
                    <Button
                        type="submit"
                        variant="contained"
                        className={styles.button}
                    >
                        {data.buttonText}
                    </Button>
                </CardActions>
            )}
        </>
    )
}

const ProfileCardView: FC<ProfileCardViewProps> = (props) => {
    const { data } = props;
    const styles = useStyles();

    return (
        <Card className={`${styles.root} ${data.disabled && styles.disabled}`}>
            {data.disabled && data.profileFormik ? (
                <form onSubmit={data.profileFormik.onSubmit} className={styles.formContainer}>
                    <CardContentFormProfile data={data} />
                </form>
            ) : (
                <CardContentProfile data={data} />
            )}
        </Card>
    );
};

export default ProfileCardView;
