import React, {FC, useEffect, useRef} from "react";
import {Marker, Tooltip, useMapEvents} from "react-leaflet";
import {useDispatch, useSelector} from "react-redux";
import {getUserLocation, sendUpdateUserGrantedLocation, sendUpdateUserLocation} from "../../../store/location";
import {sendUpdatePreferencesUserLocation} from "../../../store/preferences";

export interface UserLocationPermissionProps {
    onUserDeniedLocation?: () => void;
}

const UserLocation: FC<UserLocationPermissionProps> = ({ onUserDeniedLocation }) => {
    const started = useRef(false);
    const dispatch = useDispatch();
    const map = useMapEvents({
        locationerror: (e) => {
            console.log("location error: ", e);

            if (e.code === 1){ //Geolocation error: User denied Geolocation.
                dispatch(sendUpdateUserGrantedLocation(false));
                onUserDeniedLocation?.();
            }
        },
        locationfound: (e) => {
            dispatch(sendUpdateUserLocation(e.latlng));
            dispatch(sendUpdateUserGrantedLocation(true))
            dispatch(sendUpdatePreferencesUserLocation(e.latlng));
        }
    });

    const position = useSelector(getUserLocation)

    useEffect(() => {
        if (started.current){
            return;
        }

        started.current = true;

        //TODO use this when home use store to get data
        // if (isNonNullOrUndefined(position)){
        //     return;
        // }

        map.locate();
    })

    return position == null ? null : (
        <Marker position={position}>
            <Tooltip>Você está aqui</Tooltip>
        </Marker>
    )
}

export default UserLocation;