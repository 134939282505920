import React, {FC} from "react";
import MaskedInput from "react-text-mask";

export interface MaskProps {
    inputRef: (ref: HTMLInputElement | void) => void;
    isShowMask?: boolean;
}

const MaskDocument: FC<MaskProps> = (props) => {
    const {inputRef, isShowMask, ...other} = props;
    return (
        <MaskedInput {...other}
                     ref={(ref: any) => inputRef(ref ? ref.inputElement : null)}
                     showMask={isShowMask}
                     mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}

        />
    );
}

const MaskDocumentRg: FC<MaskProps> = (props) => {
    const {inputRef, isShowMask, ...other} = props;
    return (
        <MaskedInput {...other}
                     ref={(ref: any) => inputRef(ref ? ref.inputElement : null)}
                     showMask={isShowMask}
                     mask={[/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /[\dXx]/]}

        />
    );
}

const MaskPostalCode: FC<MaskProps> = (props) => {
    const {inputRef, isShowMask, ...other} = props;
    return (
        <MaskedInput {...other}
                     ref={(ref: any) => inputRef(ref ? ref.inputElement : null)}
                     showMask={isShowMask}
                     mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
        />
    );
}

const MaskPhone: FC<MaskProps> = ({inputRef, isShowMask, ...other}) => {
    return (
        <MaskedInput {...other}
                     ref={(ref: any) => inputRef(ref ? ref.inputElement : null)}
                     showMask={isShowMask}
                     mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
        />
    );
}

const MaskCelphone: FC<MaskProps> = ({inputRef, isShowMask, ...other}) => {
    return (
        <MaskedInput {...other}
                     ref={(ref: any) => inputRef(ref ? ref.inputElement : null)}
                     showMask={isShowMask}
                     mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
        />
    );
}

const MaskDate: FC<MaskProps> = ({inputRef, isShowMask, ...other}) => {
    return (
        <MaskedInput {...other}
                     ref={(ref: any) => inputRef(ref ? ref.inputElement : null)}
                     showMask={isShowMask}
                     mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}

        />
    );
}

export {
    MaskDocument,
    MaskDocumentRg,
    MaskPhone,
    MaskCelphone,
    MaskDate,
    MaskPostalCode
}