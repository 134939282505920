import {
    Button,
    Card,
    CardActions,
    CardContent, Modal, Theme,
    Tooltip,
    Typography
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { createStyles, makeStyles } from "@material-ui/styles";
import { FormikProps, useFormik } from "formik";
import React, { Dispatch, FC, SetStateAction } from "react";
import { FormState } from "../../screen/cart/CartScreen";
import { AddressRequest } from "../../services/User";
import { unMask } from "../../util/formatter";
import { MaskPostalCode } from "../../util/Masks";
import InputForm from "../field/InputForm";
import { getAddressByPostalCode } from "../location";
import RecoverPassword from "./RecoverPassword";

export interface LoginModal {
    modalAddress: boolean;
    setModalAddress: Dispatch<SetStateAction<boolean>>;
    formikCart: FormikProps<FormState>;
}

const AddressForm: FC<LoginModal> = ({
    modalAddress,
    setModalAddress,
    formikCart
}) => {
    // this state is used at father component
    // const [modalAddress, setModalAddress] = React.useState(false)

    const styles = useStyles();
    const [modalRecoverPass, setModalRecoverPass] = React.useState(false);
    const [isLoadAddress, setIsLoadAddress] = React.useState(false);

    const formik = useFormik({
        initialValues: {
            postalCode: formikCart.values.addressDelivery?.postalCode || '',
            street: formikCart.values.addressDelivery?.street || '',
            streetNumber: formikCart.values.addressDelivery?.streetNumber || '',
            complement: formikCart.values.addressDelivery?.complement || '',
            neighborhood: formikCart.values.addressDelivery?.neighborhood || '',
            locality: formikCart.values.addressDelivery?.locality || '',
            state: formikCart.values.addressDelivery?.state || '',
        },
        onSubmit: (values) => {
            console.log(values)
            formikCart.setFieldValue('addressDelivery', values)
            setModalAddress(false)
        },
        validate: (values) => {
            const errors = {} as AddressRequest;

            if (!values.postalCode) errors.postalCode = 'CEP é obrigatório';
            else if (values.postalCode.includes('_')) errors.postalCode = 'CEP está incompleto'

            if (!values.street) errors.street = 'Rua é obrigatório';
            if (!values.streetNumber) errors.streetNumber = 'Número é obrigatório';
            if (!values.neighborhood) errors.neighborhood = 'Bairro é obrigatório';
            if (!values.state) errors.state = 'Estado é obrigatório';
            if (!values.locality) errors.locality = 'Cidade é obrigatório';

            return errors;
        },
        enableReinitialize: true,
    });

    const handleFetchCEP = async () => {
        const postalCode = formik.values.postalCode;

        if (postalCode.length < 9) {
            formik.setFieldError('postalCode', 'Necessário digitar o CEP para pesquisar');
            return;
        }

        try {
            setIsLoadAddress(true)
            const addressLoad = await getAddressByPostalCode(unMask(postalCode));
            formik.setFieldValue('street', addressLoad.street)
            formik.setFieldValue('neighborhood', addressLoad.neighborhood)
            formik.setFieldValue('locality', addressLoad.city)
            formik.setFieldValue('state', addressLoad.state)

        } catch (error) {
            formik.setFieldError('postalCode', 'Ocorreu um erro ao buscar o cep');
        } finally {
            setIsLoadAddress(false)
        }
    }

    const handleClose = () => {
        setModalAddress(false)
    };

    return (
        <div>
            <Modal
                open={modalAddress}
                aria-labelledby="modal-login"
                aria-describedby="modal-login"
            >
                <form onSubmit={formik.handleSubmit}>
                    <Card className={styles.cardWrapper}>
                        <CardContent component="fieldset" className={styles.cardContent}>
                            <Typography component="legend" color="primary" variant="h6">
                                Endereço de entrega
                            </Typography>

                            <div className={styles.formRow}>
                                <InputForm
                                    name="postalCode"
                                    value={formik.values.postalCode}
                                    onChange={formik.handleChange}
                                    label="CEP *"
                                    placeholder="Digite o seu CEP"
                                    InputProps={{
                                        inputComponent: MaskPostalCode as any
                                    }}
                                    error={!!formik.errors.postalCode}
                                    helperText={formik.errors.postalCode}
                                />

                                <Tooltip title="Buscar pelo CEP">
                                    <Button onClick={handleFetchCEP} variant="contained" color="primary">
                                        <Search fontSize="medium" />
                                    </Button>
                                </Tooltip>
                            </div>

                            <div className={styles.formRow}>
                                <InputForm
                                    name="street"
                                    value={formik.values.street}
                                    onChange={formik.handleChange}
                                    label="Rua *"
                                    placeholder="Digite o nome da Rua"
                                    disabled={isLoadAddress}
                                    isLoading={isLoadAddress}
                                    error={!!formik.errors.street}
                                    helperText={formik.errors.street}
                                />
                                <InputForm
                                    name="streetNumber"
                                    value={formik.values.streetNumber}
                                    onChange={formik.handleChange}
                                    label="Número *"
                                    placeholder="Digite o número do seu endereço"
                                    error={!!formik.errors.streetNumber}
                                    helperText={formik.errors.streetNumber}
                                />
                            </div>

                            <InputForm
                                name="complement"
                                value={formik.values.complement}
                                onChange={formik.handleChange}
                                label="Complemento"
                                placeholder="Digite o complemento do endereço"
                            />

                            <InputForm
                                name="neighborhood"
                                value={formik.values.neighborhood}
                                onChange={formik.handleChange}
                                label="Bairro *"
                                placeholder="Digite o nome do Bairro"
                                disabled={isLoadAddress}
                                isLoading={isLoadAddress}
                                error={!!formik.errors.neighborhood}
                                helperText={formik.errors.neighborhood}
                            />

                            <div className={styles.formRow}>
                                <InputForm
                                    name="state"
                                    value={formik.values.state}
                                    onChange={formik.handleChange}
                                    label="Estado *"
                                    placeholder="Digite o seu Estado"
                                    disabled={isLoadAddress}
                                    isLoading={isLoadAddress}
                                    error={!!formik.errors.state}
                                    helperText={formik.errors.state}
                                />
                                <InputForm
                                    name="locality"
                                    value={formik.values.locality}
                                    onChange={formik.handleChange}
                                    label="Cidade *"
                                    placeholder="Digite a sua Cidade"
                                    disabled={isLoadAddress}
                                    isLoading={isLoadAddress}
                                    error={!!formik.errors.locality}
                                    helperText={formik.errors.locality}
                                />
                            </div>
                        </CardContent>

                        <CardActions className={styles.cardActions}>
                            <Button
                                variant="contained"
                                onClick={handleClose}
                            >
                                Cancelar
                            </Button>

                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                            >
                                Confirmar
                            </Button>
                        </CardActions>
                    </Card>
                </form>
            </Modal>

            <RecoverPassword
                modalRecoverPass={modalRecoverPass}
                setModalRecoverPass={setModalRecoverPass}
            />
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cardWrapper: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.5)",
            padding: 16,
            "& .MuiCardHeader-root": {
                textAlign: "center",
                color: theme.palette.primary.main,
            },
        },
        cardContent: {
            maxWidth: 600,
            margin: "0 auto",
            display: "flex",
            flexFlow: "column nowrap",
            gap: 20,
        },
        cardActions: {
            padding: '10px 0',
            maxWidth: 600,
            margin: "0 auto",
            display: "flex",
            justifyContent: "flex-end",
            "& button": {
                fontSize: "1rem",
                textTransform: "capitalize",
            },
        },
        form: {
            margin: "20px 0",
        },
        formRow: {
            display: "flex",
            gap: 20,
        },
        [theme.breakpoints.down("sm")]: {
            cardContent: {
                // maxHeight: 350,
                overflowX: 'auto',
            },
            cardWrapper: {
                width: "80%",
                // height: "auto",
            },
            cardActions: {
                "& button": {
                    fontSize: "1em",
                },
            },
        },
    })
);

export default AddressForm;
