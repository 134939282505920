import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    IconButton,
    Modal,
    Theme,
    Typography
} from "@material-ui/core"
import { Close } from "@material-ui/icons"
import { createStyles, makeStyles } from "@material-ui/styles"
import { format } from 'date-fns'
import { CartItem, Order, OrderItem } from "@echope/echope-store-core/dist/models"
import { CartState, sendCleanCart, sendUpdateCartItems } from "@echope/echope-store-core/dist/store/cart"
import { getCatalogItemsSelector } from "@echope/echope-store-core/dist/store/catalog"
import React, { Dispatch, FC, SetStateAction } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router"
import LocalizationCard from "../../screen/cart/components/LocalizationCard"
import { currencyFormatter } from '../../util/formatter'
import ConfirmSendToCart from "./ConfirmSendToCard"

export interface DetailsPurchaseProps {
    modalDetailsPurchase: boolean,
    setModalDetailsPurchase: Dispatch<SetStateAction<boolean>>,
    order: Order,
    cartState: CartState
}

type ItemProps = {
    item: OrderItem
}

type ItemTotalsProps = {
    label: string,
    value: string,
}

const ItemList: FC<ItemProps> = ({ item }) => {
    const styles = useStyles()

    return (
        <Box display="flex" justifyContent="space-between" className={styles.item}>
            <Typography>{item.name}</Typography>

            <Typography>
                {item.quantity}<span>x </span>{currencyFormatter.format(item.price)}
            </Typography>
        </Box>
    )
}

const ItemTotals: FC<ItemTotalsProps> = ({label, value}) => {
    const styles = useStyles()

    return (
        <Box display="flex" justifyContent="space-between" className={styles.item}>
            <Typography color="primary" variant="h5" >{label}</Typography>

            <Typography variant="h5">
                {value}
            </Typography>
        </Box>
    )
}

const DetailsPurchase: FC<DetailsPurchaseProps> = ({
    modalDetailsPurchase,
    setModalDetailsPurchase,
    order,
    cartState
}) => {
    const styles = useStyles()
    const dispatch = useDispatch();
    const catalogItens = useSelector(getCatalogItemsSelector)
    const history = useHistory();

    // const [modalDetailsPurchase, setModalDetailsPurchase] = React.useState(false)
    const [modalConfirmSendToCart, setModalConfirmSendToCart] = React.useState(false)

    const handleClose = () => setModalDetailsPurchase(false)

    const handleSendToCart = () => {
        dispatch(sendCleanCart())

        const product2Cart = order.items.map(orderItem => {
            const itens2Cart = catalogItens.find(catItem => catItem.product.id === orderItem.id)

            if (itens2Cart)
                // TODO: realizar verifcação da quantidade
                return CartItem.create(itens2Cart.product, orderItem.quantity)

            return itens2Cart;
        }).filter(product => !!product)

        dispatch(sendUpdateCartItems(product2Cart))

        history.push('/store/cart')
    }

    return (
        <>
            <Modal
                open={modalDetailsPurchase}
                aria-labelledby="detalhes-compra-passada"
                aria-describedby="detalhes-das-compras-passadas"
            >
                <Card className={styles.cardWrapper}>
                    <CardHeader
                        title="Detalhes do pedido"
                        color="primary"
                        action={
                            <IconButton aria-label="close" onClick={handleClose}>
                                <Close />
                            </IconButton>
                        }
                    />

                    <CardContent className={styles.cardContent}>
                        <div style={{ width: '100%' }}>
                            <LocalizationCard store={order.store} />
                        </div>

                        <Box component="fieldset" className={styles.fieldBox}>
                            <Typography component="legend" variant="h6">Detalhes</Typography>

                            <Typography variant="body2">
                                <Typography component="span" color="primary" variant="body2">Número:</Typography>{" "}
                                {order.id}
                            </Typography>

                            <Typography variant="body2">
                                <Typography component="span" color="primary" variant="body2">Status:</Typography>{" "}
                                {order.status}
                            </Typography>

                            <Typography variant="body2">
                                <Typography component="span" color="primary" variant="body2">Data do Pedido:</Typography>{" "}
                                {!!order.creationDate && format(new Date(order.creationDate), 'dd/MM/yyyy hh:mm:ss')}
                            </Typography>

                            <Typography variant="body2">
                                <Typography component="span" color="primary" variant="body2">Data do Agendamento:</Typography>{" "}
                                {!!order.scheduleDate && format(new Date(order.scheduleDate), 'dd/MM/yyyy hh:mm:ss')}
                            </Typography>
                        </Box>

                        <Box component="fieldset" className={styles.fieldBox}>
                            <Typography component="legend" variant="h6">Lista de Produtos</Typography>

                            {!!order.items?.length && order.items.map((item, index) => (
                                <ItemList key={`${item.name}_${index}`} item={item} />)
                            )}
                        </Box>

                        <Box component="fieldset" className={styles.fieldBox}>
                            <Typography component="legend" variant="h6">Opção da Entrega</Typography>

                            <Typography variant="body2">
                                {order.observation}
                            </Typography>
                        </Box>

                        {/* adicionar o summary de compras */}
                        {/* <Summary cartState={cartState} /> */}

                        <Box component="fieldset" className={styles.fieldBox}>
                            {/* <Typography component="legend" variant="h6">Lista de Produtos</Typography> */}
                            <ItemTotals label="Subtotal" value={currencyFormatter.format(order.total)} />
                            <ItemTotals label="Desconto" value={currencyFormatter.format(order.discount || 0)} />
                            <ItemTotals label="Total" value={currencyFormatter.format(order.netValue)} />
                        </Box>

                    </CardContent>
                    <CardActions className={styles.cardActions}>
                        <Button fullWidth variant="contained" color="primary" onClick={() => setModalConfirmSendToCart(true)}>
                            Enviar pedido para o carrinho
                        </Button>
                    </CardActions>
                </Card>

            </Modal>

            <ConfirmSendToCart
                modalConfirmSendToCart={modalConfirmSendToCart}
                setModalConfirmSendToCart={setModalConfirmSendToCart}
                handleConfirm={handleSendToCart}
            />
        </>
    )
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cardWrapper: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "50%",
            boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.5)",
            padding: 16,
            '& .MuiCardHeader-root': {
                color: theme.palette.primary.main,
            },
        },
        cardContent: {
            maxHeight: 400,
            overflowX: 'auto',
            width: '100%',
        },
        fieldBox: {
            width: '100%',
            margin: '10px 0',
            border: `1px solid ${theme.palette.grey[400]}`,
            '& p': {
                marginTop: 2
            }
        },
        cardActions: {
            display: "flex",
            justifyContent: "space-between",
            "& button": {
                fontSize: "1.125rem",
            },
        },
        item: {
            margin: '5px 0',
            borderBottom: `1px solid ${theme.palette.grey[300]}`,
            '& p:first-child': {
                maxWidth: 500,
            },
        },
        [theme.breakpoints.between('sm', 'md')]: {
            cardWrapper: {
                width: "80%",
                height: "auto",
                padding: 0,
            },
        },
        [theme.breakpoints.down("xs")]: {
            cardWrapper: {
                width: "100%",
                height: "auto",
                padding: 0,
            },
            cardContent: {
                padding: 10,
            },
            item: {
                '& p:first-child': {
                    maxWidth: 200,
                },
                '& p:nth-child(2)': {
                    fontSize: 10,
                }
            },
            cardActions: {
                "& button": {
                    fontSize: "1em",
                },
            },
        },
    })
)

export default DetailsPurchase
