import { Store } from "@echope/echope-store-core/dist/models";
import React, { FC } from "react";
import { Button, createStyles, makeStyles, Typography } from "@material-ui/core";
import { formatPhone } from "../../../util/formatter";
import { useWindowSize } from "../../../hooks/WindowSize";
import SectionHeader from "../../../components/header";
import { valueFromProps } from "../../../util/ValueFromProps";
import { Link } from "react-router-dom";
import { isNullOrUndefined } from "../../../util/Asserts";

export interface SelectedCompanyProps {
    store?: Store;
    distance?: string;
}

const useStyles = makeStyles(theme => createStyles({
    container: {
        display: "flex",
        flexDirection: "row",
        marginLeft: 5,
        marginRight: 5,
        flex: 2,
        backgroundColor: "#efefef",
        [theme.breakpoints.down("xs")]: {
            flexDirection: "column",
        }
    },
    content: {
        display: "flex",
        flexDirection: "column",
        padding: 12,
        paddingBottom: 2,
        flex: 1,
        justifyContent: "space-between",
        flexBasis: "auto",
        flexShrink: 0,
        [theme.breakpoints.down("sm")]: {
            padding: 3,
        }
    },
    description: {
        display: "flex",
        flexDirection: "column"
    },
    storeImage: {
        height: props => valueFromProps(props, "vw", 1440) * 0.33 * 0.5625,
        width: props => valueFromProps(props, "vw", 1440) * 0.33 * 0.5625 * 1920 / 1080,
        [theme.breakpoints.down("sm")]: {
            height: (props: any) => `${(valueFromProps(props, "vw") * 0.30 * 0.5625).toFixed(2)}px`,
            width: (props: any) => `${(valueFromProps(props, "vw") * 0.30).toFixed(2)}px`,
        }
    }
}));

const SelectedCompany: FC<SelectedCompanyProps> = ({ store, distance }) => {
    const windowSize = useWindowSize();
    const styles = useStyles({ vw: windowSize.width });

    if (isNullOrUndefined(store)) {
        return null;
    }

    const { address, phone } = store!;

    const data = [];

    if (address != null) {
        data.push(`${address.street}, ${address.neighborhood} - ${address.city} ${address.state}`);
    }

    if (phone != null) {
        data.push(formatPhone(phone))
    }

    const { isDelivering, openFrom, closeAt, maxDaysForSchedule, isDrive } = store!.info;

    return (
        <div className={styles.container}>
            <img src={store!.imageSquareURL} alt={store!.name} className={styles.storeImage} />
            <div className={styles.content}>
                <SectionHeader title="Você está na loja:" decorated={false} variant="h6" smFontSize={14} />

                <div className={styles.description}>

                    <Typography variant="h5" style={{ fontWeight: "bold" }}>{store!.name}</Typography>
                    <Typography variant="caption" style={{ whiteSpace: "pre-line" }}>{data.join("\n")}</Typography>

                    {
                        distance != null &&
                        <Typography variant="caption">{`Distância: ${distance}`}</Typography>
                    }

                    {
                        isDrive
                            ? <Typography variant="body2" color={"error"}
                                style={{ fontWeight: "bold" }}>Vendas somente em loja física!</Typography>
                            : isDelivering
                                ? (<Typography variant="body2" style={{ fontWeight: "bold" }}>
                                    {`Aberto das ${openFrom} até ${closeAt}`}</Typography>)
                                : (<Typography variant="body2" color={"error"}
                                    style={{ fontWeight: "bold" }}>Loja online fechada!</Typography>)
                    }

                    <Typography variant="caption"
                        style={{ fontWeight: "bold" }}>
                        {`Prazo máximo para agendamento ${maxDaysForSchedule} dia(s)`}
                    </Typography>
                </div>

                <Button variant="contained"
                    color="primary"
                    component={Link}
                    disabled={!isDelivering || isDrive}
                    to="/store/home">
                    Entre na loja
                </Button>
            </div>
        </div>
    );
}

export default SelectedCompany;