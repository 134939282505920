import {AuthenticationState} from "@echope/echope-store-core/dist/store/auth";
import {initialState} from "@echope/echope-store-core/dist/store/view/auth";
import {CartState} from "@echope/echope-store-core/dist/store/cart";
import {CatalogState} from "@echope/echope-store-core/dist/store/catalog";
import {CatalogViewState} from "@echope/echope-store-core/dist/store/view/catalog";
import {CustomerState} from "@echope/echope-store-core/dist/store/customer";
import {LocationState} from "../location";
import {OrderState} from "@echope/echope-store-core/dist/store/order";
import {PreferenceState} from "../preferences";
import {StoreState} from "@echope/echope-store-core/dist/store/store";
import {VoucherState} from "@echope/echope-store-core/dist/store/voucher";
import {CustomerViewState} from "@echope/echope-store-core/dist/store/view/customer";
import {Field} from "@echope/echope-store-core/dist/store/fields";
import {
    AgeValidator,
    CpfValidator,
    EmailValidator,
    RequiredStringValidator
} from "@echope/echope-store-core/dist/store/fields/validators";
import {WebApplicationState} from "../types";
import {catalogFrom} from "./state/CatalogStateLoad";
import {storeFrom} from "./state/StoreStateLoad";
import { CustomerUserState } from "@echope/echope-store-core/dist/store/customerUser";

const valueOf = (state: any): WebApplicationState => {
    return {
        authentication: "authentication" in state ? authFrom(state.authentication) : AuthenticationState.empty(),
        authenticationView: initialState,
        cart: "cart" in state ? cartFrom(state.cart) : CartState.empty(),
        catalog: "catalog" in state ? catalogFrom(state.catalog) : CatalogState.empty(),
        catalogView: "catalogView" in state ? catalogViewFrom(state.catalogView) : CatalogViewState.empty(),
        customerUser: "customerUser" in state ? customerUserFrom(state.customerUser) : CustomerUserState.empty(),
        customer: "customer" in state ? customerFrom(state.customer) : CustomerState.empty(),
        customerView: "customerView" in state ? customerViewFrom(state.customerView) : emptyCustomerView(),
        location: "location" in state ? LocationState.valueOf(state.location) : LocationState.empty(),
        order: "order" in state ? orderFrom(state.order) : OrderState.empty(),
        preference: "preference" in state ? PreferenceState.valueOf(state.preference) : PreferenceState.empty(),
        store: "store" in state ? storeFrom(state.store) : StoreState.empty(),
        vouchers: "voucher" in state ? voucherfrom(state.voucher) : VoucherState.empty(),
    };
}

const authFrom = (state: any): AuthenticationState => {
    return AuthenticationState.empty();
}

const cartFrom = (state: any): CartState => {
    return CartState.empty();
}

const catalogViewFrom = (state: any): CatalogViewState => {
    return CatalogViewState.empty();
}

const customerUserFrom = (state: any): CustomerUserState => {
    return CustomerUserState.empty();
}

const customerFrom = (state: any): CustomerState => {
    return CustomerState.empty();
}

const customerViewFrom = (state: any): CustomerViewState => {
    return emptyCustomerView();
}

const orderFrom = (state: any): OrderState => {
    return OrderState.empty();
}

const voucherfrom = (state: any): VoucherState => {
    return VoucherState.empty();
}

const emptyCustomerView = () => ({
    birthDate: new Field<string>('', '', new AgeValidator('validation.customer.birthDate', false)),
    name: new Field<string>('', '', new RequiredStringValidator('validation.customer.name')),
    mobile: new Field<string>('', '', new RequiredStringValidator('validation.customer.mobile')),
    document: new Field<string>('', '', new CpfValidator('validation.customer.document')),
    email: new Field<string>('', '', new EmailValidator('validation.customer.email', false)),
});

export {
    valueOf
}