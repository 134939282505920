import { CssBaseline } from "@material-ui/core";
import { StylesProvider, ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { Environment, EnvironmentType } from "@echope/echope-store-core/dist/infrastructure/environment";
import { ProductType } from "@echope/echope-store-core/dist/infrastructure/product";
import React, { FC, useEffect } from "react";
import ReactPixel from 'react-facebook-pixel';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import FooterBar from "./components/core/FooterBar";
import MainAppBar from "./components/core/MainAppBar";
import Page from "./components/core/Page";
import { configureWebStore } from "./store";
import { createTheme } from "./theme/Theme";

if (process.env.REACT_APP_ENV === 'production') {
    Environment.init(EnvironmentType.Production, ProductType.AppSales);
}else{
    Environment.init(EnvironmentType.Development, ProductType.AppSales);
}

const store = configureWebStore();

const App: FC = () => {
    const theme = createTheme();

    useEffect(() => {
        ReactPixel.init("319046729791971");
        ReactPixel.pageView();

        const script = document.createElement('script');
        script.async = true;
        script.src = 'https://www.googletagmanager.com/gtag/js?id=UA-143777450-1';
        document.head.prepend(script);
    }, [])

    return (
        <ReduxProvider store={store}>
            <MuiThemeProvider theme={theme}>
                <ThemeProvider theme={theme}>
                    <StylesProvider injectFirst>
                        <CssBaseline />

                        <Router>
                            <MainAppBar />
                            <Page />
                            <FooterBar />
                        </Router>
                    </StylesProvider>
                </ThemeProvider>
            </MuiThemeProvider>
        </ReduxProvider>
    )
}

export default App;