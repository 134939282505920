import React, { ChangeEvent, FocusEvent, FC, useState } from "react";
import { FormControl, Input, InputLabel, Typography } from "@material-ui/core";
import { Field } from "@echope/echope-store-core/dist/store/fields";
import { InputBaseComponentProps } from "@material-ui/core/InputBase/InputBase";
import { unMask } from "../../util/formatter";
import { CSSProperties } from "react";

export interface MaskedTextFieldProps {
    id: string
    field: Field<string>
    retrieveMaskedValue?: boolean
    validate: boolean
    label: string
    containerStyle?: CSSProperties
    onChange?: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
    onFocus?: (event: FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => void
    onValueChanged?: (value: string, maskedValue: string) => void
    maskComponent: React.ElementType<InputBaseComponentProps>
}

const MaskedTextField:FC<MaskedTextFieldProps> = (props) => {
    const { id, label, onValueChanged, onChange, validate, field, maskComponent, containerStyle, retrieveMaskedValue, onFocus } = props;

    const [maskedValue, setMaskedValue] = useState("");
    const [shouldValidate, setShouldValidate] = useState(false);

    const shouldUnMaskValue = !retrieveMaskedValue || true;
    const isError = shouldValidate && !field.isValid();

    const internalOnChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const value = event.target.value;

        setMaskedValue(value)
        onValueChanged?.(shouldUnMaskValue ? unMask(value) : value, value);
    }

    const value = shouldUnMaskValue ? maskedValue : field.value;

    return (
        <FormControl style={containerStyle}>
            <InputLabel htmlFor={id} error={isError}>{label}</InputLabel>
            <Input
                value={value}
                onChange={onChange || internalOnChange}
                onFocus={(event) => {
                    setShouldValidate(validate);
                    onFocus?.(event);
                }}
                error={isError}
                id={id}
                inputComponent={maskComponent}
            />
            {
                isError &&
                <Typography color="error" variant="caption">
                    {/* {field.validationMessage()} */}
                    CEP é obrigatório
                </Typography>
            }
        </FormControl>
    )
}

export default MaskedTextField;