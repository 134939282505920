import {
    Button,
    Card,
    CardActions,
    CardContent, Modal,
    Theme,
    Typography
} from "@material-ui/core"
import { InfoOutlined } from "@material-ui/icons"
import { createStyles, makeStyles } from "@material-ui/styles"
import React, { Dispatch, FC, SetStateAction } from "react"

export interface ConfirmSendToCartModal {
    modalConfirmSendToCart: boolean,
    setModalConfirmSendToCart: Dispatch<SetStateAction<boolean>>,
    handleConfirm: () => void,
}

const ConfirmSendToCart: FC<ConfirmSendToCartModal> = ({ modalConfirmSendToCart, setModalConfirmSendToCart, handleConfirm }) => {
    const classes = useStyles()

    // const [modalConfirmSendToCart, setModalConfirmSendToCart] = React.useState(false)

    const handleClose = () => setModalConfirmSendToCart(false)

    return (
        <Modal
            open={modalConfirmSendToCart}
            aria-labelledby="Confirmação"
            aria-describedby="Confirmação para enviar itens no carrinho"
        >
            <Card className={classes.cardWrapper}>
                <CardContent className={classes.cardContent}>
                    <div className={classes.center}>
                        <InfoOutlined color="primary" style={{ fontSize: '100px' }} />
                        <Typography color="primary" variant="h5">Repetir Pedido</Typography>
                    </div>

                    <br />

                    <Typography>
                        Ao enviar o seu pedido para o carrinho, somente os <b>produtos que temos em estoque</b> na data da compra serão apresentados. <br />
                        Além disso, os produtos estarão com os <b>preços atualizados</b> de acordo com a data da compra. <br /> <br />
                        <b>Deseja continuar?</b>
                    </Typography>
                </CardContent>

                <CardActions className={classes.cardActions}>
                    <Button
                        size="large"
                        variant="contained"
                        onClick={handleClose}
                    >
                        Cancelar
                    </Button>
                    <Button
                        size="large"
                        variant="contained"
                        color="primary"
                        onClick={handleConfirm}
                    >
                        Ok
                    </Button>
                </CardActions>
            </Card>
        </Modal>
    )
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        center: {
            margin: '0 auto',
            textAlign: 'center',
        },
        cardWrapper: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.5)",
            padding: 16,
        },
        cardContent: {
            margin: '0 auto',
            maxWidth: 500,
            display: "flex",
            flexFlow: "column nowrap",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
        },
        cardActions: {
            margin: '0 auto',
            maxWidth: 500,
            display: "flex",
            justifyContent: "flex-end",
        },
        [theme.breakpoints.down("sm")]: {
            cardWrapper: {
                width: "80%",
                height: "auto",
            },
        },
    })
)

export default ConfirmSendToCart
