import {isNullOrUndefined} from "../../../util/Asserts";
import {createStyles, List} from "@material-ui/core";
import {makeStyles, Theme} from "@material-ui/core/styles";
import {useFilteredBrands} from "../../../hooks/UseFilteredBrands";
import BrandView from "./BrandView";
import {isNonNullOrUndefined} from "@echope/echope-store-core/dist/util/assertions";
import SectionHeader from "../../../components/header";

const useStyles = makeStyles((theme: Theme) => createStyles({
    list: {
        display: "flex",
        flexWrap: "wrap",
        flex: 1,
        justifyContent: "flex-start",
        overflow: "hidden",
        maxHeight: 192,
        [theme.breakpoints.down("md")]: {
            maxHeight: 160
        },
        [theme.breakpoints.down("sm")]: {
            maxHeight: 180
        },
        [theme.breakpoints.down("xs")]: {
            maxHeight: 158
        }
    }
}))

const BrandListView = () => {
    const [brands, filtered] = useFilteredBrands();
    const styles = useStyles();

    if (isNullOrUndefined(brands) || brands.length === 0) {
        return null;
    }

    const brandsToShow = filtered ?
        brands:
        brands.filter(b => filtered || isNonNullOrUndefined(b.image));

    return (
        <div style={{ backgroundColor: "#efefef", padding: 8, marginBottom: 8}}>
            <SectionHeader variant="h6" decorated={false} primary={false} title="Marcas" />
            <List className={styles.list}>
                {
                    brandsToShow.map(b => <BrandView key={b.id} brand={b}/>)
                }
            </List>
        </div>
    )
}

export default BrandListView;