import { Box, Button, Theme, Typography } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { ColdOption } from "@echope/echope-store-core/dist/models";
import React, { FC } from "react";

interface TemperatureToggleProps {
    productCold?: ColdOption;
    handleTempToggle: (option: ColdOption) => void
}

const TemperatureToggle: FC<TemperatureToggleProps> = ({productCold, handleTempToggle}) => {
    const styles = useStyles();

    return (
        <div className={styles.container}>
            <Typography variant="h5">Temperatura dos Produtos</Typography>
            <Box display="flex" justifyContent="center" gridGap={20} className={styles.itemColder}>
                <Button
                    fullWidth
                    variant="contained"
                    color={
                        productCold === ColdOption.Normal
                            ? "primary"
                            : "default"
                    }
                    onClick={() => handleTempToggle(ColdOption.Normal)}
                >
                    Desejo receber os produtos ao natural
                </Button>
                <Button
                    fullWidth
                    variant="contained"
                    color={
                        productCold === ColdOption.WithIce
                            ? "primary"
                            : "default"
                    }
                    onClick={() => handleTempToggle(ColdOption.WithIce)}
                >
                    Desejo receber os produtos gelados
                </Button>
            </Box>
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            margin: '20px 0 40px 0',
            textAlign: 'center'
        },
        itemColder: {
            marginTop: 20,
            "& button": {
                width: 200,
            },
        },
    })
);

export default TemperatureToggle;
