import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Modal, TextField,
    Theme,
    Typography
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import { Credential } from "@echope/echope-store-core/dist/models";
import { getAuthOperation, isLoggedInSelector, sendSignIn, sendUpdateAuthOperation } from "@echope/echope-store-core/dist/store/auth";
import { Operation } from "@echope/echope-store-core/dist/util";
import { useFormik } from "formik";
import React, { Dispatch, FC, SetStateAction, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { isValidEmail } from "../../util/Asserts";
import RecoverPassword from "./RecoverPassword";

export interface LoginModal {
    modalLogin: boolean;
    setModalLogin: Dispatch<SetStateAction<boolean>>;
}

const Login: FC<LoginModal> = ({
    modalLogin,
    setModalLogin,
}) => {
    const styles = useStyles();
    const dispatch = useDispatch();
    const isLogged = useSelector(isLoggedInSelector)
    const authOperation = useSelector(getAuthOperation)
    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        onSubmit: (values) => {
            const credential = new Credential(values.email, values.password)

            dispatch(sendSignIn(credential))
        },
        validate: (values) => {
            const errors = {} as {email: string, password: string};

            if (!values.email)
                errors.email = 'E-mail é obrigatório'
            else if (!isValidEmail(values.email))
                errors.email = 'E-mail inválido'

            if (!values.password) errors.password = 'Favor digitar a senha';

            return errors;
        }
    });
    const [modalRecoverPass, setModalRecoverPass] = React.useState(false);

    // this state is used at father component
    // const [modalLogin, setModalLogin] = React.useState(false)

    const handleClose = () => {
        setModalLogin(false)
    };

    useEffect(() => {
        if (isLogged) {
            setModalLogin(false);
        }
    }, [isLogged, setModalLogin])

    useEffect(() => {
        dispatch(sendUpdateAuthOperation(Operation.started()))
    }, [dispatch, modalLogin])

    return (
        <div>
            <Modal
                open={modalLogin}
                aria-labelledby="modal-login"
                aria-describedby="modal-login"
            >
                <form onSubmit={formik.handleSubmit}>
                    <Card className={styles.cardWrapper}>
                        <CardHeader title="Faça o seu login" />

                        <CardContent component="fieldset" className={styles.cardContent}>
                            <Typography component="legend" color="primary" variant="h6">
                                Autenticação
                            </Typography>

                            <TextField
                                name="email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                className={styles.input}
                                label="E-mail"
                                variant="outlined"
                                placeholder="Digite seu e-mail"
                                error={!!formik.errors.email}
                                helperText={formik.errors.email}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                            />
                            <TextField
                                name="password"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                type="password"
                                className={styles.input}
                                label="Senha"
                                variant="outlined"
                                placeholder="Digite sua senha"
                                error={!!formik.errors.password}
                                helperText={formik.errors.password}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                            />

                            <Typography color="error" align="center" style={{ fontWeight: 'bold' }}>
                                {!!authOperation.message ? authOperation.message : ''}
                            </Typography>

                            <Box display="flex" justifyContent="space-between">
                                <Link to="/registration" className={styles.link}>
                                    Ainda não é cadastrado?
                                </Link>
                                <Typography
                                    role="button"
                                    className={styles.link}
                                    onClick={() => setModalRecoverPass(true)}
                                >
                                    Esqueceu a senha?
                                </Typography>
                            </Box>
                        </CardContent>

                        <CardActions className={styles.cardActions}>
                            <Button
                                variant="contained"
                                onClick={handleClose}
                            >
                                Cancelar
                            </Button>

                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                            >
                                Entrar
                            </Button>
                        </CardActions>
                    </Card>
                </form>
            </Modal>

            <RecoverPassword
                modalRecoverPass={modalRecoverPass}
                setModalRecoverPass={setModalRecoverPass}
            />
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cardWrapper: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.5)",
            padding: 16,
            "& .MuiCardHeader-root": {
                textAlign: "center",
                color: theme.palette.primary.main,
            },
        },
        cardContent: {
            maxWidth: 600,
            margin: "0 auto",
            display: "flex",
            flexFlow: "column nowrap",
            gap: 20,
        },
        form: {
            display: "flex",
            flexFlow: "column nowrap",
            justifyContent: "center",
            alignItems: "center",
            gap: 20,
        },
        cardActions: {
            padding: '10px 0',
            maxWidth: 600,
            margin: "0 auto",
            display: "flex",
            justifyContent: "flex-end",
            "& button": {
                fontSize: "1rem",
                textTransform: "capitalize",
            },
        },
        link: {
            width: "fit-content",
            textDecoration: "none",
            fontSize: 13,
            color: theme.palette.primary.main,
            cursor: "pointer",
            fontWeight: "bold",
        },
        [theme.breakpoints.down("sm")]: {
            cardWrapper: {
                width: "80%",
                height: "auto",
            },
            cardActions: {
                "& button": {
                    fontSize: "1em",
                },
            },
        },
    })
);

export default Login;
