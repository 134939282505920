// Blacklist common values.
const BLACKLIST: Array<string> = [
    "00000000000",
    "11111111111",
    "22222222222",
    "33333333333",
    "44444444444",
    "55555555555",
    "66666666666",
    "77777777777",
    "88888888888",
    "99999999999",
    "12345678909",
];

const STRICT_STRIP_REGEX = /[.-]/g;
const LOOSE_STRIP_REGEX = /[^\d]/g;

const verifierDigit = (digits: string): number => {
    const numbers: Array<number> = digits.split("").map((number) => {
        return parseInt(number, 10);
    });

    const modulus: number = numbers.length + 1;
    const multiplied: Array<number> = numbers.map(
        (number, index) => number * (modulus - index)
    );
    const mod: number =
        multiplied.reduce((buffer, number) => buffer + number) % 11;

    return mod < 2 ? 0 : 11 - mod;
};

const strip = (number: string, strict?: boolean): string => {
    number = number.toString();
    const regex: RegExp = strict ? STRICT_STRIP_REGEX : LOOSE_STRIP_REGEX;
    return (number || "").replace(regex, "");
};

/**
 *
 * @param numberCpf numero de cpf deve ser SEM formatação
 * @returns String do cpf sem formatação e caso a quantidade de caracteres do cpf seja diferente de 11, irá preencher 0 (zero) à esquerda.
 */
const zeroToLeft = (numberCpf: string | number): string => {
    if (typeof numberCpf === "undefined") return "";

    const cpf = numberCpf.toString();

    let cpfFormated = cpf;

    if (cpf.length !== 11) {
        cpfFormated = cpf.padStart(11, "0");
    }

    return cpfFormated;
};

const format = (number: string): string => {
    number = number.toString();
    return strip(number).replace(
        /^(\d{3})(\d{3})(\d{3})(\d{2})$/,
        "$1.$2.$3-$4"
    );
};

const cleanFormatCpf = (number: string): string => {
    number = number.toString();
    return number.replace(/[\_\.\-]/g, "");
};

const isValidCpf = (number: string, strict?: boolean): boolean => {
    const stripped: string = strip(number, strict);

    // CPF must be defined
    if (!stripped) {
        return false;
    }

    // CPF must have 11 chars
    if (stripped.length !== 11) {
        return false;
    }

    // CPF can't be blacklisted
    if (BLACKLIST.includes(stripped)) {
        return false;
    }

    let numbers: string = stripped.substr(0, 9);
    numbers += verifierDigit(numbers);
    numbers += verifierDigit(numbers);

    return numbers.substr(-2) === stripped.substr(-2);
};

export { verifierDigit, strip, format, cleanFormatCpf, isValidCpf, zeroToLeft };
