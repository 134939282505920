import { Box, Button, Card, CardContent, createStyles, makeStyles, TextField, Typography } from "@material-ui/core";
import { newAuthRepository } from "@echope/echope-store-core/dist/infrastructure/repository";
import { useFormik } from "formik";
import React, { FC, useEffect } from "react";
import { useHistory, useParams } from 'react-router-dom';
import SectionHeader from "../../components/header";
import { useQuery } from "../../hooks/UseQueryParams";

const useStyles = makeStyles(theme => createStyles({
    container: {
        marginLeft: "2%",
        width: "96%",
        display: "flex",
        flexDirection: "column",
        marginBottom: 30,
        [theme.breakpoints.down("sm")]: {
            marginLeft: "1%",
            width: "98%",
        }
    },
    box: {
        flex: 1
    },
    cardWrapper: {
        width: 500,
        margin: '50px auto',
        boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.2)",
        padding: 16,
        "& .MuiCardHeader-root": {
            textAlign: "center",
            color: theme.palette.primary.main,
        },
    },
    cardContent: {
        maxWidth: 600,
        margin: "10 auto",
    },
    form: {
        display: "flex",
        flexFlow: "column nowrap",
        justifyContent: "center",
        alignItems: "center",
        gap: 20,
    },
    cardActions: {
        padding: '0',
        maxWidth: 600,
        margin: "0 auto",
        display: "flex",
        justifyContent: "flex-end",
        "& button": {
            fontSize: "1rem",
        },
    },
    textHelper: {
        textAlign: 'center',
        fontSize: '0.98rem',
    },
    [theme.breakpoints.down("sm")]: {
        cardWrapper: {
            width: "80%",
            height: "auto",
        },
        cardActions: {
            "& button": {
                fontSize: "1em",
            },
        },
        textHelper: {
            fontSize: '0.8rem',
        }
    },
}))

const ResetPassword: FC = () => {
    const styles = useStyles();
    const history = useHistory();

    const params = useParams<{ email: string }>();
    const query = useQuery();

    const [userId, setUser] = React.useState(0);
    const [successMsg, setSuccessMsg] = React.useState({ message: "", isSuccess: false })

    const formik = useFormik({
        initialValues: {
            password: "",
            passwordConfirm: "",
        },
        onSubmit: async (values) => {
            try {
                await newAuthRepository().saveNewPassword({
                    accessPass: values.passwordConfirm,
                    email: params.email,
                    token: query.get('token') || '',
                    userId,
                });

                setSuccessMsg({ message: 'Senha alterada com sucesso', isSuccess: true })

                setTimeout(() => {
                    history.push('/');
                }, 2 * 1000)
            } catch (error) {
                if (error instanceof Error)
                    setSuccessMsg({ message: error.message, isSuccess: false })
            }
        },
        validateOnBlur: true,
        validate: (values) => {
            const errors = {} as { password: string, passwordConfirm: string };

            if (!values.password)
                errors.password = "Senha é obrigatório"
            else if (values.password.length < 6)
                errors.password = 'Senha deve ter no mínimo 6 digitos';
            else if (!values.passwordConfirm)
                errors.passwordConfirm = "Confirmação da senha é obrigatório"
            else if (values.password !== values.passwordConfirm)
                errors.passwordConfirm = "Confirmação inválida: Senhas diferentes"

            return errors;
        },
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const resUser = await newAuthRepository().validateToken(params.email || '', query.get('token') || '');
                setUser(resUser.userId);
            } catch (error) {

                setSuccessMsg({ message: "Token ou email inválidos, não será possível alterar a senha.", isSuccess: false })
            }
        }

        if (params.email && query.get('token'))
            fetchData();
    }, [params, query])

    return (
        <section className={styles.container}>
            <SectionHeader title="Alteração de senha" />
            <form onSubmit={formik.handleSubmit}>
                <Card className={styles.cardWrapper}>
                    <CardContent component="fieldset" className={styles.cardContent}>
                        <Typography component="legend" variant="h5" color="primary">Cadastre sua nova senha</Typography>
                        <Box className={styles.form}>
                            <TextField
                                id="password"
                                name="password"
                                label="Nova Senha"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                placeholder="Digite sua nova senha"
                                type="password"
                                className={styles.input}
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={!!formik.errors.password}
                                helperText={!!formik.errors.password ? formik.errors.password : ""}
                                fullWidth
                            />
                            <TextField
                                id="passwordConfirm"
                                name="passwordConfirm"
                                label="Confirme sua nova senha"
                                value={formik.values.passwordConfirm}
                                onChange={formik.handleChange}
                                type="password"
                                placeholder="Repita a sua nova senha"
                                className={styles.input}
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={!!formik.errors.passwordConfirm}
                                helperText={!!formik.errors.passwordConfirm ? formik.errors.passwordConfirm : ""}
                                onPaste={(e) => { e.preventDefault() }}
                                fullWidth
                            />

                            {!!successMsg.message && (
                                <Typography className={styles.textHelper}
                                    color={successMsg.isSuccess ? 'primary' : 'error'}
                                >
                                    <b> {successMsg.message} </b>
                                </Typography>
                            )}

                        </Box>
                    </CardContent>

                    <div className={styles.cardActions}>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                        >
                            Confirmar
                        </Button>
                    </div>
                </Card>
            </form>
        </section>
    )
}

export default ResetPassword;