import {useDispatch, useSelector} from "react-redux";
import {
    getPreferenceStores, sendUpdatePreferencesOperation,
    sendUpdatePreferencesStore,
    sendUpdatePreferencesUserLocation,
    StoreWithDistance
} from "../store/preferences";
import {getUserLocation} from "../store/location";
import {useEffect} from "react";
import {isNonNullOrUndefined} from "@echope/echope-store-core/dist/util/assertions";
import {useStores} from "./UseStores";
import {Operation} from "@echope/echope-store-core/dist/util";

export const useStoreWithDistance = (): StoreWithDistance[] => {
    const dispatch = useDispatch();

    const stores = useStores();
    const storesWithDistance = useSelector(getPreferenceStores);
    const userLocation = useSelector(getUserLocation);

    useEffect(() => {
        if (isNonNullOrUndefined(storesWithDistance) && storesWithDistance.length == stores.length){
            return;
        }

        // dispatch(sendUpdatePreferencesOperation(Operation.processing("Aguarde enquanto carregamos os dados")));
        dispatch(sendUpdatePreferencesStore(stores));

        if (isNonNullOrUndefined(userLocation)){
            dispatch(sendUpdatePreferencesUserLocation(userLocation));
        }
    }, [stores, userLocation]);

    return storesWithDistance;
}