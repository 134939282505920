import { CircularProgress, createStyles, makeStyles, TextField, TextFieldProps, Theme } from "@material-ui/core";
import React, { FC } from "react";

type CustomInputProps = TextFieldProps & {
    isLoading?: boolean;
}

const InputForm: FC<CustomInputProps> = (props) => {
    const styles = useStyles();

    return (
        <TextField
            className={styles.root}
            variant="outlined"
            fullWidth
            {...props}
            InputLabelProps={{ shrink: true, ...props.InputLabelProps }}
            InputProps={{
                endAdornment: props.isLoading ? <CircularProgress size={25}/> : null,
                ...props.InputProps
            }}
        />
    );
};


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flex: 1,
            "& .MuiOutlinedInput-input": {
                padding: 11,
            },
        },
    })
);


export default InputForm;
