import {CatalogItem} from "@echope/echope-store-core/dist/models";
import {FC} from "react";
import {makeStyles, Theme} from "@material-ui/core/styles";
import {Button, createStyles, Typography} from "@material-ui/core";
import {currencyFormatter} from "../../../util/formatter";
import PromotionImage from "../../../assets/images/promotion.png";

export interface CatalogItemSlideProps {
    item: CatalogItem,
    onClick: () => void;
}

const CatalogItemSlide: FC<CatalogItemSlideProps> = ({item, onClick}) => {
    const {product} = item;

    const styles = useStyle({ promotional: product.price == product.fullPrice });

    const action = () => {

    }

    return (
        <div className={styles.root}>
            <div className={styles.container} onClick={action}>
                <div className={styles.imageContainer}>
                    <img className={styles.image} src={product.imageSquareURL} alt={product.name} />
                    {
                        product.price != product.fullPrice &&
                        <img className={styles.imagePromotion} alt="Promoção" src={PromotionImage} />
                    }
                </div>

                <div className={styles.content}>
                    <div className={styles.info}>
                        <Typography color="primary" align="center" variant="body1">{product.name}</Typography>
                    </div>

                    <div className={styles.actions}>
                        <Button variant="contained"
                                className={styles.button}
                                onClick={onClick}>Eu quero!</Button>

                        <Typography variant="h6"
                                    className={styles.price}>{currencyFormatter.format(product.price)}</Typography>
                    </div>
                </div>
            </div>
        </div>
    )
}

const useStyle = makeStyles((theme: Theme) => createStyles({
    root: {
        display: "flex",
        width: "100%",
        height: 230,
        backgroundColor: theme.palette.background.paper,
        justifyContent: "center",
        alignItems: "center"
    },
    container: {
        display: "flex",
        height: "100%",
        width: "100%",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        border: "solid 1px #d0d0d0",
        borderRadius: 3
    },
    content: {
        width: "100%",
        display: "flex",
        flex: 1,
        flexDirection: "column",
        justifyContent: "space-between",
        padding: theme.spacing(1),
    },
    info: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
    },
    actions: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
    },
    button: {
        backgroundColor: theme.palette.error.light,
        color: theme.palette.text.secondary,
        '&:hover': {
            backgroundColor: theme.palette.error.main,
        },
        paddingRight: 20,
        paddingLeft: 20,
        [theme.breakpoints.down("sm")]: {
            width: "50%"
        },
    },
    imageContainer: {
        position: 'relative',
    },
    image: {
        width: "100%",
        height: 150,
        objectFit: "contain",
    },
    imagePromotion: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "30%",
    },
    price: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.text.secondary,
        padding: 3,
        paddingLeft: 6,
        paddingRight: 6,
        display: "flex",
        borderRadius: 3,
        alignSelf: "flex-end",
        fontWeight: "bold",
        textAlign: "right",
    },
    [theme.breakpoints.up("lg")]: {
        root: {
            height: "100%",
        }
    }
}));

export default CatalogItemSlide;