import {Marker, Popup, Tooltip} from "react-leaflet";
import React, {FC, useRef} from "react";
import {Button, createStyles, makeStyles, Typography} from "@material-ui/core";
import {icon, Icon} from "leaflet";
import echopePin from "../../../assets/images/epin.png"
import {formatPhone} from "../../../util/formatter";
import {StoreWithDistance} from "../../../store/preferences";

export interface CompanyLocationProps {
    store: StoreWithDistance,
    onSelect: (store: StoreWithDistance) => void
}

const useStyles = makeStyles(theme => createStyles({
    popupContainer: {
        display: "flex",
        flexDirection: "column",
    },
    popupAction: {
        display: "flex",
        flexDirection: "column",
        marginTop: 10
    }
}))

const CompanyLocation:FC<CompanyLocationProps> = ({ store, onSelect }) => {
    const currentStore = store.store!;
    const { latitude, longitude } = currentStore.coords;
    const styles = useStyles();
    const popupRef = useRef<any>();

    const selectAction = (store: StoreWithDistance) => {
        onSelect(store);
        popupRef.current._map.closePopup();
    }

    const companyIcon:Icon = icon({
       iconUrl: echopePin,
       iconAnchor: [30, 39],
    });

    const { address, phone } = currentStore;

    const data = [];
    const isDelivering = store.store?.info.isDelivering;

    if (address != null){
        data.push(`${address.street}, ${address.neighborhood} - ${address.city} ${address.state}`);
    }

    if (phone != null){
        data.push(formatPhone(phone))
    }

    return (
        <Marker position={[latitude, longitude]}
                icon={companyIcon}>
            <Popup ref={popupRef}>
                <div className={styles.popupContainer}>
                    <Typography variant={"h6"}>{currentStore.name}</Typography>
                    <Typography variant={"caption"} style={{ whiteSpace: "pre-line"}} >{data.join("\n")}</Typography>
                    {
                        !isDelivering &&
                        <Typography variant={"caption"} color="error">Loja online fechada</Typography>
                    }
                    <div className={styles.popupAction}>
                        <Button variant="contained"
                                color="primary"
                                disabled={!isDelivering}
                                onClick={() => selectAction(store)}>
                            Selecionar
                        </Button>
                    </div>
                </div>
            </Popup>
            <Tooltip>{currentStore.name}</Tooltip>
        </Marker>
    )
}

export default CompanyLocation;