import {
    Coords,
    Store,
    StoreAddress,
    StoreInfo,
} from "@echope/echope-store-core/dist/models";
import { StoreZeroRate } from "@echope/echope-store-core/dist/models/store-zero-rate";

const getStore = (item: any): Store => {
    const {
        _id,
        _name,
        _description,
        _phone,
        _acceptingOrders,
        _address,
        _coords,
        _imageWideURL,
        _imageSquareURL,
        _info,
        _zeroRate,
    } = item;

    const { _street, _city, _state, _neighborhood } = _address;
    const { _latitude, _longitude } = _coords;
    const {
        _isDelivering,
        _isDrive,
        _openFrom,
        _closeAt,
        _email,
        _maxDaysForSchedule,
        _quantityMaxProductSale,
        _valueMaxSale,
    } = _info;
    const { _enabled, _forValue } = _zeroRate;

    return new Store(
        _id,
        _name,
        _description,
        _phone,
        _acceptingOrders,
        new StoreAddress(_street, _city, _state, _neighborhood),
        new Coords(_latitude, _longitude),
        _imageWideURL,
        _imageSquareURL,
        new StoreInfo(
            _isDelivering,
            _isDrive,
            _openFrom,
            _closeAt,
            _email,
            _maxDaysForSchedule,
            _quantityMaxProductSale,
            _valueMaxSale
        ),
        new StoreZeroRate(_enabled, _forValue)
    );
};

export { getStore };
