import {
    Button,
    Card,
    CardActions,
    CardContent, Modal,
    TextField,
    Theme,
    Typography
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import { newAuthRepository } from '@echope/echope-store-core/dist/infrastructure/repository';
import { useFormik } from "formik";
import React, { Dispatch, FC, SetStateAction } from "react";
import { isValidEmail } from "../../util/Asserts";

export interface RecoverPassModal {
    modalRecoverPass: boolean;
    setModalRecoverPass: Dispatch<SetStateAction<boolean>>;
}

const RecoverPassword: FC<RecoverPassModal> = ({
    modalRecoverPass,
    setModalRecoverPass,
}) => {
    const styles = useStyles();
    const [successMsg, setSuccessMsg] = React.useState({ message: "", isSuccess: false })
    const formik = useFormik({
        initialValues: {
            email: "",
            emailConfirm: "",
        },
        onSubmit: async (values) => {
            try {
                const response = await newAuthRepository().resetPassword(values.emailConfirm);

                setSuccessMsg({ message: response.data, isSuccess: true })
            } catch (error) {
                setSuccessMsg({ message: 'Usuário inválido', isSuccess: false })
            }
        },
        validateOnBlur: true,
        validate: (values) => {
            const errors = {} as { email: string, emailConfirm: string };

            if (!values.email)
                errors.email = "E-mail é obrigatório"
            else if (!isValidEmail(values.email))
                errors.email = "E-mail inválido"
            else if (!values.emailConfirm)
                errors.emailConfirm = "Confirmação do e-mail é obrigatório"
            else if (values.email !== values.emailConfirm)
                errors.emailConfirm = "Confirmação inválida: E-mails diferentes"

            return errors;
        },
    });

    // this state is used at father component
    // const [modalRecoverPass, setModalRecoverPass] = React.useState(false)

    const handleClose = () => {
        cleanState()
        setModalRecoverPass(false)
    };

    const cleanState = () => {
        setSuccessMsg({ message: '', isSuccess: false })
        formik.resetForm()
    }

    return (
        <div>
            <Modal
                open={modalRecoverPass}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <form onSubmit={formik.handleSubmit}>
                    <Card className={styles.cardWrapper}>
                        <CardContent component="fieldset" className={styles.cardContent}>
                            <Typography component="legend" variant="h5" color="primary">Recuperar Senha</Typography>
                            <div className={styles.form}>
                                <TextField
                                    id="email"
                                    name="email"
                                    label="E-mail"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    placeholder="Digite seu e-mail"
                                    type="email"
                                    className={styles.input}
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    error={!!formik.errors.email}
                                    helperText={!!formik.errors.email ? formik.errors.email : ""}
                                    fullWidth
                                />
                                <TextField
                                    id="emailConfirm"
                                    name="emailConfirm"
                                    label="Confirme seu E-mail"
                                    value={formik.values.emailConfirm}
                                    onChange={formik.handleChange}
                                    type="email"
                                    placeholder="Repita o seu e-mail"
                                    className={styles.input}
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    error={!!formik.errors.emailConfirm}
                                    helperText={!!formik.errors.emailConfirm ? formik.errors.emailConfirm : ""}
                                    onPaste={(e) => { e.preventDefault() }}
                                    fullWidth
                                />

                                <Typography className={styles.textHelper}>
                                    <b>
                                        {!!successMsg.message ? successMsg.message : '* Após clicar em enviar, verifique sua caixa de e-mail.'}
                                    </b>
                                </Typography>
                            </div>
                        </CardContent>

                        <CardActions className={styles.cardActions}>
                            <Button
                                variant="contained"
                                onClick={handleClose}
                            >
                                {successMsg.isSuccess ? 'Fechar' : 'Cancelar'}
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                            >
                                Enviar
                            </Button>
                        </CardActions>
                    </Card>
                </form>
            </Modal>
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cardWrapper: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.5)",
            padding: 16,
            "& .MuiCardHeader-root": {
                textAlign: "center",
                color: theme.palette.primary.main,
            },
        },
        cardContent: {
            maxWidth: 600,
            margin: "10 auto",
        },
        form: {
            display: "flex",
            flexFlow: "column nowrap",
            justifyContent: "center",
            alignItems: "center",
            gap: 20,
        },
        cardActions: {
            padding: '0',
            maxWidth: 600,
            margin: "0 auto",
            display: "flex",
            justifyContent: "flex-end",
            "& button": {
                fontSize: "1rem",
            },
        },
        textHelper: {
            color: theme.palette.primary.main,
            textAlign: 'center',
            fontSize: '0.98rem',
        },
        [theme.breakpoints.down("sm")]: {
            cardWrapper: {
                width: "80%",
                height: "auto",
            },
            cardActions: {
                "& button": {
                    fontSize: "1em",
                },
            },
            textHelper: {
                fontSize: '0.8rem',
            }
        },
    })
);

export default RecoverPassword;
