import {FC} from "react";
import {createStyles, makeStyles, Theme, Typography} from "@material-ui/core";
import {Variant} from "@material-ui/core/styles/createTypography";
import {valueFromProps} from "../../util/ValueFromProps";
import {isNullOrUndefined} from "../../util/Asserts";
import {isNonNullOrUndefined} from "@echope/echope-store-core/dist/util/assertions";

const useStyles = makeStyles((theme: Theme) => createStyles({
    title: {
        fontWeight: "bold",
        fontFamily: '"Baloo 2"',
        textShadow: props => `1px 1px 1px ${valueFromProps(props, "primary", true) ? "#0000022" : "#fff"}`,
        borderBottomStyle: "solid",
        borderBottomWidth: props => valueFromProps(props, "decorated", true) ? 2 : 0,
        borderBottomColor: theme.palette.primary.main,
        marginTop: 8,
        [theme.breakpoints.down("sm")]: {
            fontSize: (props: any) => valueFromProps(props, "smFontSize", 16),
        }
    }
}))

export interface SectionHeaderProps {
    title: string;
    variant?: Variant;
    smFontSize?: number;
    decorated?: boolean;
    primary?: boolean;
}

const SectionHeader:FC<SectionHeaderProps> = ({title, primary, variant, smFontSize, decorated}) => {
    const usePrimary = isNonNullOrUndefined(primary) ? primary : true;

    const styles = useStyles({
        smFontSize: smFontSize || 16,
        decorated: isNullOrUndefined(decorated) ? true : decorated,
        primary: usePrimary,
    });



    return (
        <Typography variant={variant || "h4"} color={usePrimary ? "primary" : "secondary"} className={styles.title} >
            {title}
        </Typography>
    )
}

export default SectionHeader;