import { Operation } from "@echope/echope-store-core/dist/util";
import { Button, createStyles, Paper, Theme, Typography } from "@material-ui/core";
import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ThumbDownIcon from '@material-ui/icons/ThumbDown';

export interface FailedSectionViewProps {
   operation?: Operation
   message?: string
   onBack?: () => void
}

const FailedSectionView: FC<FailedSectionViewProps> = (props) => {
   const {operation, message, onBack} = props;

   console.log("error => ", operation?.error);

   const text = operation?.error?.message || message;
   const showBack = onBack !== undefined;

   const styles = useStyles();

   return (
      <div className={styles.container}>
         <Paper className={styles.paper}>
            <div className={styles.main}>
               <div className={styles.content}>
                  <ThumbDownIcon className={styles.icon} color={"primary"} />
                  <Typography variant={"body1"}>{text}</Typography>
               </div>
            </div>

            {
               showBack && (
                  <div className={styles.retryBox}>
                     <Button onClick={() => onBack?.()} variant={"contained"} color={"primary"}>
                        Tentar novamente
                     </Button>
                  </div>
               )
            }
         </Paper>
      </div>
   );
}

const useStyles = makeStyles((theme: Theme) =>
   createStyles({
      container: {
         display: "flex",
         flex: 1,
         justifyContent: "space-between",
         flexDirection: "column",
         alignItems: "center"
      },
      paper: {
        padding: 8
      },
      icon: {
         width: "80px",
         height: "80px",
         marginBottom: 10,
      },
      main: {
         display: "flex",
         flex: 1,
         alignItems: 'center',
         alignContent: 'center',
         justifyContent: 'space-around'
      },
      content: {
         display: "flex",
         flexDirection: "column",
         alignItems: 'center',
         alignContent: 'center'
      },
      retryBox: {
         display: "flex",
         justifyContent: "center",
         alignSelf: "center",
         marginTop: theme.spacing(12)
      }
   })
);

export default FailedSectionView;