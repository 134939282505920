import { ActionCreator } from "redux";
import {
    CLEAR_LOCATION_MESSAGE,
    ClearLocationAction,
    UPDATE_LOCATION_OPERATION_MESSAGE,
    UPDATE_USER_GRANTED_LOCATION_MESSAGE, UPDATE_USER_LOCATION_BY_POSTAL_CODE_MESSAGE,
    UPDATE_USER_LOCATION_MESSAGE, UpdateLocationOperationAction,
    UpdateUserGrantedLocationAction,
    UpdateUserLocationAction, UpdateUserLocationByPostalCodeAction
} from "./types";
import {LatLng} from "leaflet";
import {Operation} from "@echope/echope-store-core/dist/util";
import {LocationResponse} from "../../components/location";

export const sendUpdateUserLocation: ActionCreator<UpdateUserLocationAction> = (location: LatLng) => ({
    type: UPDATE_USER_LOCATION_MESSAGE,
    location
});

export const sendUpdateUserLocationByAddress: ActionCreator<UpdateUserLocationByPostalCodeAction> =
    (response: LocationResponse) => ({
        type: UPDATE_USER_LOCATION_BY_POSTAL_CODE_MESSAGE,
        location: response.location,
        address: response.address
    });

export const sendUpdateUserGrantedLocation: ActionCreator<UpdateUserGrantedLocationAction> = (granted: boolean) => ({
   type: UPDATE_USER_GRANTED_LOCATION_MESSAGE,
   granted
});

export const sendUpdateLocationOperation: ActionCreator<UpdateLocationOperationAction> = (operation: Operation) => ({
    type: UPDATE_LOCATION_OPERATION_MESSAGE,
    operation
});

export const sendCleanLocation: ActionCreator<ClearLocationAction> = () => ({
    type: CLEAR_LOCATION_MESSAGE
});