import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Brand, Group, Product, Store } from "@echope/echope-store-core/dist/models";
import { sendReloadCart } from "@echope/echope-store-core/dist/store/cart";
import { sendLoadProducts, sendUpdateCatalogItemQuantity } from "@echope/echope-store-core/dist/store/catalog";
import { clearCatalogView, selectBrand, selectGroup } from "@echope/echope-store-core/dist/store/view/catalog";
import { FC, useEffect } from "react";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { Dispatch } from "redux";
import FailedSectionView from "../../components/lazy/FailedSectionView";
import LazyView from "../../components/lazy/LazyView";
import LoadingSectionView from "../../components/lazy/LoadingSectionView";
import { WebApplicationState } from "../../store/types";
import StoreHomeHeaderView from "../store/views/StoreHomeHeaderView";
import BrandListView from "./views/BrandListView";
import CatalogItemListView from "./views/CatalogItemListView";
import GroupListView from "./views/GroupListView";

const mapState = (state: WebApplicationState) => ({
    selectedStore: state.preference.selectedStore,
    operation: state.catalog.operation,
    allItems: state.catalog.items,
})

const mapDispatch = (dispatch: Dispatch) => ({
    selectProduct: (product: Product, quantity: number) => {
        dispatch(sendUpdateCatalogItemQuantity(product.id, quantity));
        dispatch(sendReloadCart());
    },
    selectBrand: (brand: Brand) => {
        dispatch(selectBrand(brand));
    },
    selectGroup: (group: Group) => {
        dispatch(selectGroup(group));
    },
    clearFilter: () => {
        dispatch(clearCatalogView());
    },
    retryCatalogLoad: (store: Store | undefined) => {
        if (store != null) {
            dispatch(sendLoadProducts(store));
        }
    }
})

const connector = connect(mapState, mapDispatch);

type CatalogScreenProps = ConnectedProps<typeof connector>;

const CatalogScreen: FC<CatalogScreenProps> = (props) => {

    const styles = useStyles();
    const { operation, selectedStore } = props;
    const dispatch = useDispatch();
    const { retryCatalogLoad } = props;

    const store = selectedStore?.store;

    useEffect(() => {
        dispatch(selectGroup(null));
        dispatch(selectBrand(null));
    }, [dispatch, store])

    return (
        <LazyView operation={operation}
            failureSection={
                () => <FailedSectionView operation={operation} onBack={() => retryCatalogLoad(store)} />
            }
            loadingSection={() => <LoadingSectionView operation={operation} />}>
            <div className={styles.container}>
                <StoreHomeHeaderView store={store!} />

                <GroupListView />
                <BrandListView />
                <CatalogItemListView />
            </div>
        </LazyView>
    )
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    container: {
        display: "flex",
        flexDirection: "column",
        margin: 0,
        paddingLeft: "2%",
        paddingRight: "2%",
        width: "100%",
        [theme.breakpoints.down("sm")]: {
            paddingLeft: "1%",
            paddingRight: "1%",
            // width: "98%",
        }
    }
}));

export default connector(CatalogScreen);