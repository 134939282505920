import { PreloadedState } from "redux";
import {WebApplicationState} from "../types";
import {valueOf} from "./stateRebuild";
import {isNonNullOrUndefined} from "@echope/echope-store-core/dist/util/assertions";

const loadState = (): PreloadedState<WebApplicationState> | undefined => {
    try {
        console.log("loading state...");

        const serializedState = localStorage.getItem("echope:state");
        if (serializedState == null){
            return undefined
        }

        const retrievedState = JSON.parse(serializedState);

        if (shouldPurge(retrievedState)){
            localStorage.removeItem("echope:state");
            return undefined;
        }

        const state =  valueOf(retrievedState);

        console.log("finishing state load with success: ", isNonNullOrUndefined(state))

        return state;
    } catch (e) {
        console.log("error lading state: ", e);
        return undefined;
    }
}

const shouldPurge = (state: any): boolean => {
    const { preference } = state;

    if (preference == null){
        return false;
    }

    const { creation, timeToLive } = preference;

    const created = new Date(creation).getTime();
    const now = new Date().getTime();

    return ((now - created) / 1000) > timeToLive;
}

export { loadState }