import {
    CLEAR_LOCATION_MESSAGE,
    LocationState,
    UPDATE_LOCATION_OPERATION_MESSAGE,
    UPDATE_USER_GRANTED_LOCATION_MESSAGE, UPDATE_USER_LOCATION_BY_POSTAL_CODE_MESSAGE,
    UPDATE_USER_LOCATION_MESSAGE,
    UpdateLocationOperationAction,
    UpdateUserGrantedLocationAction,
    UpdateUserLocationAction, UpdateUserLocationByPostalCodeAction
} from "./types";
import {Action, Reducer} from "redux";

const locationByPostalCodeReducer = (state: LocationState) => (action: UpdateUserLocationByPostalCodeAction) =>
    state.updateFormattedAddress(action.address).updateUserLocation(action.location);

const locationReducer: Reducer<LocationState> = (state = LocationState.empty(), action: Action) => {
    switch (action.type) {
        case UPDATE_LOCATION_OPERATION_MESSAGE:
            return state.updateOperation((action as UpdateLocationOperationAction).operation);
        case UPDATE_USER_LOCATION_MESSAGE:
            return state.updateUserLocation((action as UpdateUserLocationAction).location);
        case UPDATE_USER_GRANTED_LOCATION_MESSAGE:
            return state.updateUserGrantedLocation((action as UpdateUserGrantedLocationAction).granted);
        case UPDATE_USER_LOCATION_BY_POSTAL_CODE_MESSAGE:
            return locationByPostalCodeReducer(state)(action as UpdateUserLocationByPostalCodeAction);
        case CLEAR_LOCATION_MESSAGE:
            return state.clear();
        default:
            return state;
    }
}

export {
    locationReducer
}