import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Theme,
    Typography
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import { Kit } from "@echope/echope-store-core/dist/models";
import { FormikProps } from "formik";
import React, { FC, useEffect, useState } from "react";
import { FormState } from "../CartScreen";

interface ChoppKitCardProps {
    formik: FormikProps<FormState>
    kit: Kit
}

interface IKitData {
    isAvailable: boolean;
    name: string;
}

const ChoppKitCard: FC<ChoppKitCardProps> = ({ formik, kit }) => {
    const styles = useStyles();

    const [kitData, setKitData] = useState<IKitData>({} as IKitData)

    useEffect(() => {
        if (kit) {
            setKitData({
                isAvailable: kit.units > 0,
                name: kit.name,
            })
        }
    }, [kit])

    return (
        <div className={styles.container}>
            <Typography variant="h5" className={styles.kitTitle}>
                Para este pedido a loja disponibiliza também o equipamento:{" "}
                <span> {kitData.name} </span>
            </Typography>

            {kitData.isAvailable ? (
                <>
                    <div className={styles.selectStyle}>
                        <FormControl
                            className={styles.input}
                            variant="outlined"
                            fullWidth
                        >
                            <InputLabel id="choppKit" shrink>
                                Optar pelo kit
                            </InputLabel>
                            <Select
                                labelId="choppKit"
                                label="Optar pelo kit"
                                id="choppKit"
                                name="choppKit"
                                value={formik.values.choppKit}
                                onChange={formik.handleChange}
                            >
                                <MenuItem value={1}>Sim, desejo receber o kit</MenuItem>
                                <MenuItem value={2}>Não, já possuo um kit</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <Typography variant="body2">
                        Caso necessário, você pode solicitar mais de um kit entrando em
                        contato com a loja após a finalização do pedido
                    </Typography>
                </>
            ) : (
                <Typography variant="h6" className={styles.kitTitle} color="error">
                    Indisponível no momento
                </Typography>
            )}
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            background: theme.palette.grey[100],
            padding: 15,
            borderRadius: 10,
            display: "flex",
            gap: 20,
            flexFlow: "column nowrap",
        },
        kitTitle: {
            fontWeight: "bold",
            "& span": {
                color: theme.palette.primary.main,
                textTransform: "uppercase",
            },
        },
        input: {
            "& .MuiOutlinedInput-input": {
                padding: 11,
            },
        },
        selectStyle: {
            background: theme.palette.background.default,
            padding: "15px 10px",
            borderRadius: 10,
        },
    })
);

export default ChoppKitCard;
