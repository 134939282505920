import React, {Dispatch, FC, ReactElement, SetStateAction, useEffect, useState} from "react";
import {
    Operation,
    OperationFailed,
    OperationProcessing,
    OperationStarted,
    OperationSuccess,
    OperationType
} from "@echope/echope-store-core/dist/util";
import {Backdrop, createStyles, Fade, Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

export const switchState = (setter: Dispatch<SetStateAction<Operation>>) =>
    (previous: Operation) =>
        (current: Operation) => {
            if (previous.type !== current.type) {
                setter(current)
            }
        }

export type SectionRenderer = (props: any) => ReactElement;

export interface LazyViewProps {
    operation: Operation;
    failureSection: SectionRenderer;
    loadingSection: SectionRenderer;
    children: ReactElement
}

const LazyView: FC<LazyViewProps> = (props) => {
    const {operation, failureSection, loadingSection, children, ...rest} = props;

    const showing = (operationType: OperationType): boolean => operation.type === operationType;

    const [loading, setLoading] = useState(false);

    useEffect(
        () => {
            setLoading(showing(OperationProcessing))
        },
        [operation, showing]
    );

    const styles = useStyles();

    return (
        <>
            {
                showing(OperationProcessing) &&
                <Backdrop className={styles.backdrop} open={loading}>
                    {loadingSection({...rest})}
                </Backdrop>
            }

            {
                showing(OperationSuccess) &&
                <Fade in={showing(OperationSuccess)}>
                    {children}
                </Fade>
            }
            {
                showing(OperationStarted) &&
                <Fade in={showing(OperationStarted)}>
                    {children}
                </Fade>
            }
            {
                showing(OperationFailed) &&
                <Fade in={showing(OperationFailed)}>
                    {failureSection({...rest})}
                </Fade>
            }
        </>
    )
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: "flex",
            flex: 1
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: theme.palette.text.primary,
            flex: 1,
            position: "relative",
            backgroundColor: "rgba(0,0,0,0.1)"
        }
    })
)

export default LazyView;
