import {isNullOrUndefined} from "../../../util/Asserts";
import {createStyles, List} from "@material-ui/core";
import GroupView from "./GroupView";
import {makeStyles} from "@material-ui/core/styles";
import {useFilteredGroups} from "../../../hooks/UseFilteredGroups";
import SectionHeader from "../../../components/header";

const useStyles = makeStyles(theme => createStyles({
    list: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "flex-start",
        [theme.breakpoints.down("sm")]: {
            justifyContent: "center",
        },
        [theme.breakpoints.down("xs")]: {
            justifyContent: "flex-start",
        },
    }
}))

const GroupListView = () => {
    const [groups] = useFilteredGroups();
    const styles = useStyles();

    if (isNullOrUndefined(groups) || groups.length === 0){
        return null;
    }

    return (
        <div style={{ backgroundColor: "#efefef", padding: 8, marginBottom: 8}}>
            <SectionHeader variant="h6" decorated={false} primary={false} title="Categorias" />
            <List className={styles.list}>
                { groups.map(g => <GroupView key={g.id} group={g} />) }
            </List>
        </div>
    )
}

export default GroupListView;