import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
    HelpOutline,
    Person,
    PersonOutline,
    PlaylistAddCheck,
    PriorityHigh
} from "@material-ui/icons";
import { Credential } from "@echope/echope-store-core/dist/models";
import { getAuthOperation, isLoggedInSelector, sendSignIn } from "@echope/echope-store-core/dist/store/auth";
import { sendLoadProducts } from "@echope/echope-store-core/dist/store/catalog";
import { getCustomerSelector } from "@echope/echope-store-core/dist/store/customer";
import { getCustomerUserSelector } from "@echope/echope-store-core/dist/store/customerUser";
import { useFormik } from "formik";
import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import Login from "../../components/modal/Login";
import RecoverPassword from "../../components/modal/RecoverPassword";
import { setVisitedStoreHome } from "../../services/SiteLocalStorage";
import { StoreWithDistance } from "../../store/preferences";
import { isNullOrUndefined, isValidEmail } from "../../util/Asserts";
import BannerCardView from "./views/BannerCardView";
import CatalogCardView from "./views/CatalogCardView";
import ProfileCardView, { DataProfileCardView } from "./views/ProfileCardView";
import StoreHomeHeaderView from "./views/StoreHomeHeaderView";

interface StoreHomeScreenProps {
    selectedStore?: StoreWithDistance;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            // marginLeft: "2%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            marginBottom: 30,
            // [theme.breakpoints.down("sm")]: {
            //     marginLeft: "0%",
            //     width: "100%",
            // }
        },
        content: {
            width: "100%",
            padding: 10,
            margin: 0,
            flexWrap: "wrap",
            display: "flex",
            [theme.breakpoints.down("sm")]: {
                flexDirection: "column",
            },
        },
        cardContent: {
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            width: "100%",
            marginTop: 10,
            gap: 8,
            [theme.breakpoints.between("xs", "md")]: {
                justifyContent: "space-evenly",
            },
            [theme.breakpoints.down("xs")]: {
                flexFlow: "column nowrap",
            },
        },
    })
);

const StoreHomeScreen: FC<StoreHomeScreenProps> = ({ selectedStore }) => {
    const styles = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();

    const user = useSelector(getCustomerUserSelector)
    const authOperation = useSelector(getAuthOperation)
    const customer = useSelector(getCustomerSelector)
    const isUserLogged = useSelector(isLoggedInSelector)
    const store = selectedStore?.store;

    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        onSubmit: (values) => {
            const credential = new Credential(values.email, values.password)

            dispatch(sendSignIn(credential))
        },
        validate: (values) => {
            const errors = {} as {email: string, password: string};

            if (!values.email)
                errors.email = 'E-mail é obrigatório'
            else if (!isValidEmail(values.email))
                errors.email = 'E-mail inválido'

            if (!values.password) errors.password = 'Favor digitar a senha';

            return errors;
        }
    });

    const [modalRecoverPass, setModalRecoverPass] = React.useState(false);
    const [modalLogin, setModalLogin] = React.useState(false)

    const dataProfileCards: DataProfileCardView[] = [
        {
            avatar: Person,
            title: isUserLogged ? "Meu perfil" : "Autenticação",
            description: isUserLogged ? `Bem vindo ${user?.name}` : "",
            disabled: !Boolean(isUserLogged),
            profileFormik: {
                handleChange: formik.handleChange,
                values: formik.values,
                onSubmit: formik.handleSubmit,
                error: authOperation.message,
                errorFormik: formik.errors,
            },
            buttonText: isUserLogged ? "" : "Entrar",
        },
        {
            avatar: PlaylistAddCheck,
            title: "Meus pedidos",
            description: customer?.name
                ? "Veja seu histórico de pedidos"
                : "Necessário autenticação",
            buttonText: "Ver pedidos",
            buttonAction: () => {
                if (isUserLogged) {
                    history.push("/store/cart/history-buy");
                }

                setModalLogin(true)
            },
        },
        {
            avatar: HelpOutline,
            title: "Dúvidas com seus pedidos",
            description: "Leia caso ocorra algum problema com o seu pedido.",
            buttonText: "Ler",
            buttonAction: () => {
                history.push("/help");
            },
        },
        {
            avatar: PriorityHigh,
            title: "Recuperar Senha",
            description:
                'Clique em "Recuperar agora" caso tenha esquecido sua senha.',
            buttonText: "Recuperar agora",
            buttonAction: () => {
                setModalRecoverPass(true);
            },
        },
        {
            avatar: PersonOutline,
            title: "Dados Cadastrais",
            description:
                'Clique em "Ver" para visualizar seus dados pessoais e cadastrais.',
            buttonText: "Ver ",
            buttonAction: () => {
                if (isUserLogged) {
                    history.push("/registration");
                    return;
                }
                setModalLogin(true)
            },
        },

    ];

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
        setVisitedStoreHome({isVisited: true})
    }, []);

    useEffect(() => {
        if (store) dispatch(sendLoadProducts(store));
    }, [dispatch, store])

    if (isNullOrUndefined(store)) {
        return null;
    }

    if (isNullOrUndefined(selectedStore) || isNullOrUndefined(store)) {
        return <Redirect to="/stores" />;
    }

    return (
        <section className={styles.container}>
            <StoreHomeHeaderView store={store!} />

            <div className={styles.content}>
                <BannerCardView />
                <CatalogCardView store={selectedStore} />

                <div className={styles.cardContent}>
                    {dataProfileCards.map((data, index) => (
                        <ProfileCardView key={index} data={data} />
                    ))}
                </div>
            </div>

            <Login
                modalLogin={modalLogin}
                setModalLogin={setModalLogin}
            />

            <RecoverPassword
                modalRecoverPass={modalRecoverPass}
                setModalRecoverPass={setModalRecoverPass}
            />
        </section>
    );
};

export default StoreHomeScreen;
