import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React, { FC } from "react";
import { useBanners } from "../../../hooks/UseBanners";
import LazyView from "../../../components/lazy/LazyView";
import FailedSectionView from "../../../components/lazy/FailedSectionView";
import LoadingSectionView from "../../../components/lazy/LoadingSectionView";
import Slide from "../../../components/carousel/Slide";
import Carousel from "../../../components/carousel/Carousel";
import InstitutionBanner from "../../institution/InstitutionBanners";
import imageSlogan from "../../../assets/images/echope-slogan.jpg";

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        display: "flex",
        width: "39%",
        marginBottom: 5,
        flexDirection: "column",
        [theme.breakpoints.up("lg")]: {
            width: "31%"
        },
        [theme.breakpoints.between("sm", "lg")]: {
            width: "44%",
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    container: {
        display: "flex",
        flex: 1
    },
    failBannerContainer: {
        width: '100%',
        height: '300px',
        display: 'flex',
    },
    failBannerImg: {
        objectFit: 'contain',
    }
}));

const FailureComponent: FC = () => {
    const styles = useStyles();

    return (
        <div className={styles.failBannerContainer}>
            <img src={imageSlogan} className={styles.failBannerImg} alt="echope é mais que chope" />
        </div>
    )
}

const BannerCardView: FC = () => {
    const styles = useStyles();

    const [banners, operation] = useBanners();

    return (
        <div className={styles.root}>
            <LazyView operation={operation}
                failureSection={() => <FailureComponent />}
                loadingSection={() => <LoadingSectionView operation={operation} />}>
                {banners
                    ? (<div className={styles.container}>
                        <Carousel delay={5000}>
                            {
                                banners?.map(banner => (
                                    <Slide key={banner.id} image={banner.image} />
                                ))
                            }
                        </Carousel>
                    </div>)
                    : <FailureComponent />
                }
            </LazyView>
        </div>
    );
}

export default BannerCardView;