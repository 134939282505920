const visitedHome = "isVisitedStoreHomeSite"

interface ILocalStorageVisitedHome {
    isVisited: boolean
}

const getVisitedStoreHome = () => {
    const data = JSON.parse(localStorage.getItem(visitedHome) || '{"isVisited": false}')
    return data as ILocalStorageVisitedHome
}

const setVisitedStoreHome = (data: ILocalStorageVisitedHome) => {
    localStorage.setItem(visitedHome, JSON.stringify(data))
}

export {
    getVisitedStoreHome,
    setVisitedStoreHome,
}