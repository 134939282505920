import {Action, Reducer} from "redux";
import {
    CLEAN_PREFERENCES_MESSAGE,
    PreferenceState,
    UPDATE_PREFERENCES_OPERATION_MESSAGE,
    UPDATE_PREFERENCES_SELECTED_STORE_MESSAGE,
    UPDATE_PREFERENCES_STORES_MESSAGE,
    UPDATE_PREFERENCES_USER_LOCATION_MESSAGE,
    UpdatePreferenceOperationAction,
    UpdatePreferenceSelectedStoreAction,
    UpdatePreferenceStoresAction,
    UpdatePreferenceUserLocationAction
} from "./types";

const preferenceReducer: Reducer<PreferenceState> = (state = PreferenceState.empty(), action: Action) => {
    switch (action.type){
        case UPDATE_PREFERENCES_USER_LOCATION_MESSAGE:
            return state.updateWithLocation((action as UpdatePreferenceUserLocationAction).location);
        case UPDATE_PREFERENCES_SELECTED_STORE_MESSAGE:
            return state.updateWithSelectStore((action as UpdatePreferenceSelectedStoreAction).store);
        case UPDATE_PREFERENCES_STORES_MESSAGE:
            return state.updateWithStores((action as UpdatePreferenceStoresAction).stores);
        case UPDATE_PREFERENCES_OPERATION_MESSAGE:
            return state.updateWithOperation((action as UpdatePreferenceOperationAction).operation);
        case CLEAN_PREFERENCES_MESSAGE:
            return state.clear();
        default:
            return state;
    }
}

export {
    preferenceReducer
}