import { Theme, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import { Order } from "@echope/echope-store-core/dist/models";
import { currencyFormatter } from '../../../util/formatter'
import React from 'react';

interface ItemLastBuyProps {
    onClick: () => void;
    data: Order,
    mes: string
}

const ItemLastBuy: React.FC<ItemLastBuyProps> = ({ onClick, data, mes }) => {
    const styles = useStyles();

    const mesValue = mes.split(' ')[0];
    const dia = new Date(data.creationDate);

    return (
        <div className={styles.purchaseSection} onClick={onClick}>
            <div className={styles.purchaseSectionContent}>
                <div>
                    <Typography align="center" color="primary">
                        <Typography component="span" variant="h4">
                            {dia.getDate()}
                        </Typography>
                        <br /> {mesValue}
                    </Typography>
                </div>
                <div>
                    <Typography variant="h6" color="primary">{data.store.name}</Typography>
                    <Typography>Pedido nº {data.id}</Typography>
                    <Typography variant="body2">Status: {data.status}</Typography>
                </div>
            </div>

            <div>
                <Typography color="primary" align="right">{currencyFormatter.format(data.netValue)}</Typography>
            </div>
        </div>
    )
}


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        purchaseSection: {
            display: 'flex',
            flexFlow: 'column nowrap',
            padding: 10,
            margin: '10px 40px',
            cursor: 'pointer',
            transition: 'background 0.3s',
            '&:hover': {
                background: theme.palette.grey[200],
            }
        },
        purchaseSectionContent: {
            display: 'flex',
            alignItems: 'center',
            gap: 30,
        }
    })
);


export default ItemLastBuy;