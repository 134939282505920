import React, {FC, ForwardedRef} from "react";
import {Link} from "react-router-dom";
import {Button, ButtonProps} from "@material-ui/core";

export interface LinkButtonProps extends ButtonProps {
    path: string
    text: string
}

const LinkButton: FC<LinkButtonProps> = (props) => {

    const { path, text } = props;

    const RedirectTo = React.forwardRef(
        (props, ref: any) => <Link to={path} {...props} ref={ref} />
    );

    return (
        <Button component={RedirectTo} {...props}>
            {text}
        </Button>
    );
}

export default LinkButton;